import { SvgIcon } from '@mui/material';

export function NotificationPopupIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 60,
    height: 60,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="63.6"
      height="63.6"
      viewBox="0 0 63.6 63.6"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_105711"
        data-name="Group 105711"
        transform="translate(-123 -349)"
      >
        <rect
          id="Rectangle_55948"
          data-name="Rectangle 55948"
          width="16"
          height="16"
          transform="translate(123 349)"
          fill="none"
        />
        <path
          id="icons8-user"
          d="M12.714,4A3,3,0,1,0,15.66,7,2.98,2.98,0,0,0,12.714,4Zm0,.9A2.1,2.1,0,1,1,10.652,7,2.075,2.075,0,0,1,12.714,4.9ZM9.172,11.2A1.19,1.19,0,0,0,8,12.394v.456a2.8,2.8,0,0,0,1.563,2.419A6.534,6.534,0,0,0,12.714,16a6.534,6.534,0,0,0,3.151-.731A2.893,2.893,0,0,0,17.358,13.3h.071v-.906A1.19,1.19,0,0,0,16.256,11.2Zm0,.9h7.084a.285.285,0,0,1,.289.294V12.4h0v.45a1.828,1.828,0,0,1-1.107,1.631,5.786,5.786,0,0,1-2.724.619,5.786,5.786,0,0,1-2.724-.619A1.828,1.828,0,0,1,8.884,12.85v-.456A.284.284,0,0,1,9.172,12.1Z"
          transform="translate(118.286 347)"
          fill="#888"
          stroke="#888"
          strokeWidth="0.2"
        />
      </g>
    </SvgIcon>
  );
}

export function StepperUserIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="63.6"
      height="63.6"
      viewBox="0 0 24 24"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_5522"
        data-name="Group 5522"
        transform="translate(0.688 0.688)"
      >
        <circle
          id="Ellipse_39423"
          data-name="Ellipse 39423"
          cx="12"
          cy="12"
          r="12"
          transform="translate(-0.688 -0.688)"
          fill="#007965"
        />
        <path
          id="icons8-user"
          d="M12.469,4a2.793,2.793,0,1,0,2.793,2.793A2.8,2.8,0,0,0,12.469,4Zm0,.838a1.955,1.955,0,1,1-1.955,1.955A1.949,1.949,0,0,1,12.469,4.838ZM9.111,10.7A1.118,1.118,0,0,0,8,11.815v.425a2.6,2.6,0,0,0,1.482,2.252,6.286,6.286,0,0,0,2.987.681,6.286,6.286,0,0,0,2.987-.681,2.7,2.7,0,0,0,1.416-1.833h.067v-.844A1.118,1.118,0,0,0,15.827,10.7Zm0,.838h6.715a.268.268,0,0,1,.274.273v.006h0v.419a1.7,1.7,0,0,1-1.049,1.519,5.568,5.568,0,0,1-2.582.576,5.568,5.568,0,0,1-2.582-.576,1.7,1.7,0,0,1-1.049-1.519v-.425A.267.267,0,0,1,9.111,11.541Z"
          transform="translate(-1.157 1.726)"
          fill="#fff"
          stroke="#fff"
          stroke-width="0.25"
        />
      </g>
    </SvgIcon>
  );
}

export function DonetickIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: '14px',
    width: '14px',
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.174 10.121"
      {...rest}
    >
      <path
        data-name="Path 100638"
        d="M1.059 6.315l3.1 2.743 6.96-8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </SvgIcon>
  );
}

export function ThunderIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '24px',
    height: '24px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 106484" transform="translate(-10822 -6680)">
        <path
          data-name="Rectangle 56240"
          transform="translate(10822 6680)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <path
          d="M13.75 4a.75.75 0 00-.721.544l-3 10.5a.75.75 0 00.721.956h2.54l-1.767 7.068a.75.75 0 001.269.7l12-12.5A.75.75 0 0024.251 10h-4.459l1.671-5.013A.75.75 0 0020.751 4zm.565 1.5h5.395l-1.671 5.013a.75.75 0 00.712.987h3.74l-8.829 9.2 1.316-5.266a.75.75 0 00-.728-.932h-2.505z"
          transform="translate(10816.999 6678)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export function CustomFilterIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '15px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 15.2"
      {...rest}
    >
      <path
        d="M15.4 5a1.809 1.809 0 00-1.8 1.8V7h-9a.6.6 0 100 1.2h9v.2a1.8 1.8 0 003.6 0v-.2h2.2a.6.6 0 100-1.2h-2.2v-.2A1.809 1.809 0 0015.4 5zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .2v.7a.6.6 0 01-1.2 0v-.7a.6.6 0 000-.195V6.8a.591.591 0 01.6-.6zM8.6 10a1.809 1.809 0 00-1.8 1.8v.2H4.6a.6.6 0 100 1.2h2.2v.2a1.8 1.8 0 103.6 0v-.2h9a.6.6 0 100-1.2h-9v-.2A1.809 1.809 0 008.6 10zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .195v.7a.6.6 0 11-1.2 0v-.7a.6.6 0 000-.195v-.7a.591.591 0 01.6-.6zm6 3.8a1.809 1.809 0 00-1.8 1.8v.2H4.6a.6.6 0 100 1.2h8.2v.2a1.8 1.8 0 103.6 0v-.2h3a.6.6 0 100-1.2h-3v-.2a1.809 1.809 0 00-1.8-1.8zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .195v.7a.6.6 0 01-1.2 0v-.7a.6.6 0 000-.195v-.7a.591.591 0 01.6-.6z"
        transform="translate(-4 -5)"
      />
    </SvgIcon>
  );
}
export function CalendarIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '12px',
    height: '12px',
    color: '#888',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12 12"
      {...rest}
    >
      <path
        d="M8.167 6A2.174 2.174 0 006 8.167v7.667A2.174 2.174 0 008.167 18h7.667A2.174 2.174 0 0018 15.833V8.167A2.174 2.174 0 0015.833 6zm0 1h7.667A1.159 1.159 0 0117 8.167v.5H7v-.5A1.159 1.159 0 018.167 7zM7 9.667h10v6.167A1.159 1.159 0 0115.833 17H8.167A1.159 1.159 0 017 15.833zM9.167 11a.833.833 0 10.833.833.833.833 0 00-.833-.833zM12 11a.833.833 0 10.833.833A.833.833 0 0012 11zm2.833 0a.833.833 0 10.833.833.833.833 0 00-.833-.833zm-5.666 3a.833.833 0 10.833.833.833.833 0 00-.833-.833zM12 14a.833.833 0 10.833.833A.833.833 0 0012 14z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function ShareIcons(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M18.053 5a2.957 2.957 0 00-2.947 2.947 2.812 2.812 0 00.141.7L10.2 11.169a2.9 2.9 0 00-2.251-1.117 2.947 2.947 0 000 5.895 2.9 2.9 0 002.251-1.116l5.049 2.525a2.812 2.812 0 00-.141.7 2.947 2.947 0 102.947-2.947 2.9 2.9 0 00-2.255 1.113L10.753 13.7a2.812 2.812 0 00.141-.7 2.812 2.812 0 00-.141-.7L15.8 9.778a2.9 2.9 0 002.251 1.117 2.947 2.947 0 000-5.895zm0 1.263a1.684 1.684 0 11-1.684 1.684 1.675 1.675 0 011.684-1.684zM7.947 11.316A1.684 1.684 0 116.263 13a1.675 1.675 0 011.684-1.684zm10.105 5.053a1.684 1.684 0 11-1.684 1.684 1.675 1.675 0 011.685-1.685z"
        transform="translate(-5 -5)"
        fill="#007965"
      />
    </SvgIcon>
  );
}
export function StarIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '19px',
    height: '18px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 19.771 18.783"
      {...rest}
    >
      <path
        d="M13.891 5a.742.742 0 00-.668.41l-2.547 5.09-6.047.931a.742.742 0 00-.411 1.257L8.4 16.875l-.932 6.054a.742.742 0 001.075.77l5.342-2.787 5.343 2.788a.742.742 0 001.075-.77l-.932-6.054 4.183-4.183a.742.742 0 00-.411-1.257L17.1 10.5l-2.551-5.09a.742.742 0 00-.658-.41zm0 2.4l2.055 4.111a.742.742 0 00.55.4l4.98.767-3.414 3.414a.742.742 0 00-.209.637l.766 4.978-4.386-2.288a.742.742 0 00-.685 0l-4.391 2.287.766-4.978a.742.742 0 00-.209-.637L6.3 12.678l4.98-.767a.742.742 0 00.55-.4zm-.05 4.51a.742.742 0 00-.73.752v1.521h-1.527a.741.741 0 100 1.483h1.522v1.522a.741.741 0 101.483 0v-1.522h1.521a.741.741 0 100-1.483h-1.521v-1.521a.742.742 0 00-.753-.752z"
        transform="translate(-4 -5)"
        fill="#fec70f"
      />
    </SvgIcon>
  );
}

export function CalenderSVGIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '18px',
    height: '18px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 18.505 18.505"
      {...rest}
    >
      <path
        d="M3.068.1A2.973 2.973 0 00.1 3.072V13.59a2.973 2.973 0 002.968 2.972h5.276a5.915 5.915 0 01-.649-1.372H3.068a1.6 1.6 0 01-1.6-1.6V5.13h13.7v2.577a5.881 5.881 0 011.37.651V3.072A2.973 2.973 0 0013.568.1zm0 1.372h10.5a1.6 1.6 0 011.6 1.6v.686H1.47v-.686a1.6 1.6 0 011.598-1.6zm1.37 5.487A1.143 1.143 0 105.579 8.1a1.142 1.142 0 00-1.141-1.143zm3.881 0A1.143 1.143 0 109.459 8.1a1.142 1.142 0 00-1.141-1.141zm5.065 1.385a5.03 5.03 0 105.022 5.03 5.026 5.026 0 00-5.023-5.03zm0 1.372a.457.457 0 01.457.457v2.744h2.739a.458.458 0 010 .915h-2.741v2.744a.457.457 0 11-.913 0v-2.744h-2.739a.458.458 0 010-.915h2.739v-2.743a.457.457 0 01.457-.458zm-8.945 1.358a1.143 1.143 0 101.141 1.143 1.142 1.142 0 00-1.143-1.142z"
        fill="#007965"
        stroke="#007965"
        strokeWidth={0.2}
      />
    </SvgIcon>
  );
}

export function DashIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '10px',
    height: '1px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 10.924 1.677"
      {...rest}
    >
      <path
        data-name="Path 97933"
        d="M-2.877-4.062H8.047V-5.74H-2.877z"
        transform="translate(2.877 5.74)"
        fill="#f44f5a"
      />
    </SvgIcon>
  );
}
export function SpecialityIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '11px',
    height: '11px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 11.09 11.133"
      {...rest}
    >
      <path
        d="M6.545.914L1 3.249v8.8h11.09v-8.8zm0 1.095l4.537 1.91v7.12H2.008v-7.12zM4.477 3.982a.928.928 0 00-.957.892v1.341A1.833 1.833 0 005.1 7.937a2.309 2.309 0 002.2 2.094 2.279 2.279 0 002.115-1.577A1.006 1.006 0 108.4 8.27a1.246 1.246 0 01-1.1.752A1.272 1.272 0 016.157 7.9a1.811 1.811 0 001.4-1.689V4.874a.928.928 0 00-.957-.892h-.055a.5.5 0 100 1.008v1.225A.913.913 0 015.554 7h-.036a.913.913 0 01-.989-.786V4.99a.5.5 0 100-1.008z"
        transform="translate(-1 -.914)"
        fill="#f58634"
      />
    </SvgIcon>
  );
}

export function LocationIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '10px',
    height: '11px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 10.79 11.09"
      {...rest}
    >
      <path
        d="M11.4 5a3.6 3.6 0 00-3.6 3.6 6.081 6.081 0 001.122 2.852 27.763 27.763 0 001.72 2.466.966.966 0 001.509 0 27.763 27.763 0 001.72-2.466A6.081 6.081 0 0014.992 8.6 3.6 3.6 0 0011.4 5zm0 .9a2.691 2.691 0 012.7 2.7 6.306 6.306 0 01-.991 2.385 27.1 27.1 0 01-1.663 2.383c-.03.038-.058.038-.088 0a27.1 27.1 0 01-1.663-2.383A6.306 6.306 0 018.7 8.6a2.691 2.691 0 012.7-2.7zm0 1.8a.9.9 0 10.9.9.9.9 0 00-.9-.9zm-3.679 4.8a1.05 1.05 0 00-.959.623l-.666 1.5a1.049 1.049 0 00.954 1.467h8.69a1.049 1.049 0 00.959-1.475l-.666-1.5a1.05 1.05 0 00-.959-.623H14.1q-.286.434-.622.9h1.6a.15.15 0 01.137.089l.666 1.5a.15.15 0 01-.136.211H7.05a.15.15 0 01-.137-.211l.666-1.5a.15.15 0 01.137-.089h1.6q-.336-.466-.621-.9z"
        transform="translate(-6.001 -5)"
        fill="#f58634"
      />
    </SvgIcon>
  );
}
export function LanguageIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '10px',
    height: '10px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 10.133 10.133"
      {...rest}
    >
      <path
        d="M9.066 4A5.072 5.072 0 004 9.064a5.02 5.02 0 00.573 2.3l-.549 1.966a.646.646 0 00.781.781l1.968-.549a5.018 5.018 0 002.294.572A5.066 5.066 0 109.066 4zm0 .76a4.306 4.306 0 11-2.092 8.07.38.38 0 00-.287-.034l-1.871.522.522-1.87a.38.38 0 00-.034-.287 4.3 4.3 0 013.762-6.4zm1.894 1.513a.38.38 0 00-.374.385v1.2a.38.38 0 000 .123v1.971a.38.38 0 10.76 0V8.305h.38a.38.38 0 100-.76h-.38v-.887a.38.38 0 00-.386-.385zm-2.78.005a.38.38 0 100 .76h1.14v.253a1.006 1.006 0 01-.369.782.38.38 0 00.484.586 1.774 1.774 0 00.645-1.368v-.633a.38.38 0 00-.38-.38zm-.505 1.267a.38.38 0 00-.356.243l-1.267 3.294a.38.38 0 10.71.273l.3-.77h1.227l.3.77a.38.38 0 10.71-.273L8.028 7.788a.38.38 0 00-.353-.243zm0 1.439L8 9.825h-.65z"
        transform="translate(-4 -3.998)"
        fill="#f58634"
      />
    </SvgIcon>
  );
}

export function InformationIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '10px',
    height: '10px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12 12"
      {...rest}
    >
      <path
        d="M10 4a6 6 0 106 6 6 6 0 00-6-6zm0 .9a5.093 5.093 0 11-3.608 1.492A5.093 5.093 0 0110 4.9zM10 7a.6.6 0 10.6.6.6.6 0 00-.6-.6zm-.007 2.093a.45.45 0 00-.443.457v3.3a.45.45 0 00.9 0v-3.3a.45.45 0 00-.457-.457z"
        transform="translate(-4 -4)"
        fill="#f58634"
      />
    </SvgIcon>
  );
}

export function PrivateBackIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '32px',
    height: '32px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 32 32"
      {...rest}
    >
      <g data-name="Group 9" transform="translate(-4 -4)">
        <circle
          data-name="Ellipse 39211"
          cx={16}
          cy={16}
          r={16}
          transform="translate(4 4)"
          fill="#fff"
        />
        <path
          d="M21.505 15.84l-4.187 4.188 4.187 4.187a.476.476 0 11-.673.673l-4.524-4.524a.476.476 0 010-.673l4.524-4.524a.476.476 0 11.673.673z"
          fill="#007965"
          stroke="#007965"
          strokeWidth={1}
        />
      </g>
    </SvgIcon>
  );
}

export function RedCalendarIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '12px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 11.09 11.09"
      {...rest}
    >
      <path
        d="M8 6a2.009 2.009 0 00-2 2v7.085a2.009 2.009 0 002 2h7.085a2.009 2.009 0 002-2V8a2.009 2.009 0 00-2-2zm0 .924h7.085A1.072 1.072 0 0116.166 8v.462H6.924V8A1.072 1.072 0 018 6.924zM6.924 9.389h9.242v5.7a1.072 1.072 0 01-1.078 1.078H8a1.072 1.072 0 01-1.078-1.078zm2 1.232a.77.77 0 10.77.77.77.77 0 00-.768-.77zm2.618 0a.77.77 0 10.77.77.77.77 0 00-.767-.77zm2.618 0a.77.77 0 10.77.77.77.77 0 00-.767-.77zm-5.234 2.772a.77.77 0 10.77.77.77.77 0 00-.77-.77zm2.618 0a.77.77 0 10.77.77.77.77 0 00-.769-.77z"
        transform="translate(-6 -6)"
        fill="#f58634"
      />
    </SvgIcon>
  );
}
export function UploadImageIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 50.288,
    height: 40.846,
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 4627"
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 40.439 31.724"
      {...rest}
    >
      <path
        data-name="Path 602"
        d="M34 28.721a5.555 5.555 0 110 11.11H14.555a5.555 5.555 0 110-11.11h1.389v-1.389a8.332 8.332 0 1116.665 0v1.389z"
        transform="translate(-4.056 -12.639)"
        fill="#eae6ff"
      />
      <path
        data-name="Path 603"
        d="M38.421 44.238h-3.486a1.046 1.046 0 110-2.092h3.486a5.926 5.926 0 100-11.853h-1.743a1.027 1.027 0 01-1.046-1.046V27.5a9.412 9.412 0 10-18.825 0v1.743a1.027 1.027 0 01-1.046 1.046h-1.743a5.926 5.926 0 100 11.853H17.5a1.046 1.046 0 010 2.092h-3.482a8.018 8.018 0 010-16.036h.7v-.7a11.5 11.5 0 1123.008 0v.7h.7a8.018 8.018 0 010 16.036z"
        transform="translate(-6 -16)"
        fill="#6552cc"
      />
      <g data-name="Group 105794" fill="#6552cc">
        <path
          data-name="Path 604"
          d="M62.046 83.55A1.027 1.027 0 0161 82.5V72.046a1.046 1.046 0 112.092 0V82.5a1.027 1.027 0 01-1.046 1.05z"
          transform="translate(15.688 19.174) translate(-57.514 -71)"
        />
        <path
          data-name="Path 605"
          d="M59.018 76.578a1 1 0 01-.732-.314l-2.754-2.754-2.754 2.754a1.035 1.035 0 01-1.464-1.464l3.486-3.486a1.011 1.011 0 011.464 0L59.75 74.8a1.046 1.046 0 01-.732 1.778z"
          transform="translate(15.688 19.174) translate(-51 -71)"
        />
      </g>
    </SvgIcon>
  );
}
export function ExpertNotesIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106224" transform="translate(-48 -479)">
        <rect
          data-name="Rectangle 46375"
          width={44}
          height={44}
          rx={22}
          transform="translate(48 479)"
          fill="#f5f5f5"
        />
        <path
          d="M8.5 6A2.513 2.513 0 006 8.5v15A2.513 2.513 0 008.5 26h10.556a.833.833 0 00.589-.244l.009-.009 6.1-6.1a.833.833 0 00.246-.591V8.5A2.513 2.513 0 0023.5 6zm0 1.667h15a.821.821 0 01.833.833v9.722h-3.611a2.513 2.513 0 00-2.5 2.5v3.611H8.5a.821.821 0 01-.833-.833v-15a.821.821 0 01.833-.833zm2.778 2.777a.833.833 0 100 1.667h9.444a.833.833 0 100-1.667zm0 3.889a.833.833 0 100 1.667H18.5a.833.833 0 100-1.667zm9.444 5.557h2.433l-3.266 3.266v-2.434a.821.821 0 01.833-.833z"
          transform="translate(54 485)"
          fill="#5c6266"
        />
        <g data-name="Group 106352" transform="translate(3 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M80.962 510.999a.316.316 0 00-.311.32v3.367h-3.368a.316.316 0 100 .631h3.368v3.366a.316.316 0 00.632 0v-3.362h3.368a.316.316 0 100-.631h-3.368v-3.369a.316.316 0 00-.321-.32z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.5}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExpertEditNotesIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106224" transform="translate(-48 -479)">
        <rect
          data-name="Rectangle 46375"
          width={44}
          height={44}
          rx={22}
          transform="translate(48 479)"
          fill="#f5f5f5"
        />
        <path
          d="M8.5 6A2.513 2.513 0 006 8.5v15A2.513 2.513 0 008.5 26h10.556a.833.833 0 00.589-.244l.009-.009 6.1-6.1a.833.833 0 00.246-.591V8.5A2.513 2.513 0 0023.5 6zm0 1.667h15a.821.821 0 01.833.833v9.722h-3.611a2.513 2.513 0 00-2.5 2.5v3.611H8.5a.821.821 0 01-.833-.833v-15a.821.821 0 01.833-.833zm2.778 2.777a.833.833 0 100 1.667h9.444a.833.833 0 100-1.667zm0 3.889a.833.833 0 100 1.667H18.5a.833.833 0 100-1.667zm9.444 5.557h2.433l-3.266 3.266v-2.434a.821.821 0 01.833-.833z"
          transform="translate(54 485)"
          fill="#5c6266"
        />
        <g data-name="Group 106352" transform="translate(3 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M83.575 510.926a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExpertDontsIcons(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106223" transform="translate(-134 -479)">
        <rect
          data-name="Rectangle 56145"
          width={44}
          height={44}
          rx={22}
          transform="translate(134 479)"
          fill="#f5f5f5"
        />
        <path
          d="M15.636 0a2.4 2.4 0 00-2.386 2.386v.477h-3.1A2.159 2.159 0 008 5.011v13.841A2.159 2.159 0 0010.148 21h10.977a2.159 2.159 0 002.148-2.148V5.011a2.159 2.159 0 00-2.148-2.148h-3.1v-.477A2.4 2.4 0 0015.636 0zm0 1.432a.944.944 0 01.955.955V4.3h-1.909V2.386a.944.944 0 01.954-.954zM10.148 4.3h3.1v.716a.716.716 0 00.716.716h3.341a.716.716 0 00.716-.716V4.3h3.1a.705.705 0 01.716.716v13.836a.705.705 0 01-.716.716H10.148a.705.705 0 01-.716-.716V5.011a.705.705 0 01.716-.711zm5.477 3.331a.716.716 0 00-.7.726v4.773a.716.716 0 101.432 0V8.352a.716.716 0 00-.727-.726zm.011 7.647a.955.955 0 10.955.955.955.955 0 00-.955-.96z"
          transform="translate(140.364 490.5)"
          fill="#5c6266"
        />
        <g data-name="Group 106353" transform="translate(89 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M80.962 510.999a.316.316 0 00-.311.32v3.367h-3.368a.316.316 0 100 .631h3.368v3.366a.316.316 0 00.632 0v-3.362h3.368a.316.316 0 100-.631h-3.368v-3.369a.316.316 0 00-.321-.32z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.5}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExpertEditDontsIcons(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106223" transform="translate(-134 -479)">
        <rect
          data-name="Rectangle 56145"
          width={44}
          height={44}
          rx={22}
          transform="translate(134 479)"
          fill="#f5f5f5"
        />
        <path
          d="M15.636 0a2.4 2.4 0 00-2.386 2.386v.477h-3.1A2.159 2.159 0 008 5.011v13.841A2.159 2.159 0 0010.148 21h10.977a2.159 2.159 0 002.148-2.148V5.011a2.159 2.159 0 00-2.148-2.148h-3.1v-.477A2.4 2.4 0 0015.636 0zm0 1.432a.944.944 0 01.955.955V4.3h-1.909V2.386a.944.944 0 01.954-.954zM10.148 4.3h3.1v.716a.716.716 0 00.716.716h3.341a.716.716 0 00.716-.716V4.3h3.1a.705.705 0 01.716.716v13.836a.705.705 0 01-.716.716H10.148a.705.705 0 01-.716-.716V5.011a.705.705 0 01.716-.711zm5.477 3.331a.716.716 0 00-.7.726v4.773a.716.716 0 101.432 0V8.352a.716.716 0 00-.727-.726zm.011 7.647a.955.955 0 10.955.955.955.955 0 00-.955-.96z"
          transform="translate(140.364 490.5)"
          fill="#5c6266"
        />
        <g data-name="Group 106523" transform="translate(89 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M83.575 510.926a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExpertReferenceIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106222" transform="translate(-220 -479)">
        <rect
          data-name="Rectangle 56146"
          width={44}
          height={44}
          rx={22}
          transform="translate(220 479)"
          fill="#f5f5f5"
        />
        <path
          data-name="icons8-repository (1)"
          d="M10.5 3A2.51 2.51 0 008 5.5v12.725a2.5 2.5 0 002.273 2.488v-1.374a1.139 1.139 0 01-.884-.886h12.474a.682.682 0 00.682-.682V5.5a2.51 2.51 0 00-2.5-2.5zm0 1.364h9.545A1.126 1.126 0 0121.181 5.5v11.589H9.364V5.5A1.126 1.126 0 0110.5 4.362zm1.136 1.819a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm-.455 5.909v2.954a.682.682 0 001.164.482l.882-.881.882.881a.681.681 0 001.164-.482v-2.956zm5 0v1.364h5.681a.682.682 0 100-1.364z"
          transform="translate(226.728 488.002)"
          fill="#5c6266"
        />
        <g data-name="Group 106354" transform="translate(175 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M80.962 510.999a.316.316 0 00-.311.32v3.367h-3.368a.316.316 0 100 .631h3.368v3.366a.316.316 0 00.632 0v-3.362h3.368a.316.316 0 100-.631h-3.368v-3.369a.316.316 0 00-.321-.32z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.5}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ExpertEditReferenceIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106222" transform="translate(-220 -479)">
        <rect
          data-name="Rectangle 56146"
          width={44}
          height={44}
          rx={22}
          transform="translate(220 479)"
          fill="#f5f5f5"
        />
        <path
          data-name="icons8-repository (1)"
          d="M10.5 3A2.51 2.51 0 008 5.5v12.725a2.5 2.5 0 002.273 2.488v-1.374a1.139 1.139 0 01-.884-.886h12.474a.682.682 0 00.682-.682V5.5a2.51 2.51 0 00-2.5-2.5zm0 1.364h9.545A1.126 1.126 0 0121.181 5.5v11.589H9.364V5.5A1.126 1.126 0 0110.5 4.362zm1.136 1.819a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm-.455 5.909v2.954a.682.682 0 001.164.482l.882-.881.882.881a.681.681 0 001.164-.482v-2.956zm5 0v1.364h5.681a.682.682 0 100-1.364z"
          transform="translate(226.728 488.002)"
          fill="#5c6266"
        />
        <g data-name="Group 106524" transform="translate(175 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#6552cc"
          />
          <path
            d="M83.575 510.926a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function CustomFilterSltIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '19px',
    height: '17px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 19.128 17.35"
      {...rest}
    >
      <g data-name="Group 106521">
        <path
          d="M15.4 5a1.809 1.809 0 00-1.8 1.8V7h-9a.6.6 0 100 1.2h9v.2a1.8 1.8 0 003.6 0v-.2h2.2a.6.6 0 100-1.2h-2.2v-.2A1.809 1.809 0 0015.4 5zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .2v.7a.6.6 0 01-1.2 0v-.7a.6.6 0 000-.195V6.8a.591.591 0 01.6-.6zM8.6 10a1.809 1.809 0 00-1.8 1.8v.2H4.6a.6.6 0 100 1.2h2.2v.2a1.8 1.8 0 103.6 0v-.2h9a.6.6 0 100-1.2h-9v-.2A1.809 1.809 0 008.6 10zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .195v.7a.6.6 0 11-1.2 0v-.7a.6.6 0 000-.195v-.7a.591.591 0 01.6-.6zm6 3.8a1.809 1.809 0 00-1.8 1.8v.2H4.6a.6.6 0 100 1.2h8.2v.2a1.8 1.8 0 103.6 0v-.2h3a.6.6 0 100-1.2h-3v-.2a1.809 1.809 0 00-1.8-1.8zm0 1.2a.591.591 0 01.6.6v.7a.6.6 0 000 .195v.7a.6.6 0 01-1.2 0v-.7a.6.6 0 000-.195v-.7a.591.591 0 01.6-.6z"
          transform="translate(-325 -145.75) translate(321 142.9)"
        />
        <g
          data-name="Ellipse 39213"
          transform="translate(-325 -145.75) translate(336.128 145.75)"
          fill="#f58634"
          stroke="#fff"
          strokeWidth={1.5}
        >
          <circle cx={4} cy={4} r={4} stroke="none" />
          <circle cx={4} cy={4} r={3.25} fill="none" />
        </g>
      </g>
    </SvgIcon>
  );
}

export function BackArrowIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '12px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 6.477 11.001"
      {...rest}
    >
      <path
        d="M5.837 1.313L1.65 5.501l4.187 4.187a.476.476 0 11-.673.673L.64 5.837a.476.476 0 010-.673L5.164.64a.476.476 0 11.673.673z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1}
      />
    </SvgIcon>
  );
}

export function BackwardArrowIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 12.521"
      {...rest}
    >
      <path
        d="M9.19 4.93l-6.26 6.26 6.26 6.26 1.328-1.328-4.047-4.047H18.93V10.3H6.471l4.047-4.047z"
        transform="translate(-2.93 -4.93)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}

export function ForwardArrowIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '12px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 6.536 11.001"
      {...rest}
    >
      <path
        d="M.698 1.313l4.188 4.188L.698 9.688a.477.477 0 00.674.674l4.524-4.525a.476.476 0 000-.673L1.372.64a.476.476 0 00-.674.673z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1}
      />
    </SvgIcon>
  );
}

export function TimerIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '24px',
    height: '24px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 106483" transform="translate(-11208 -6348)">
        <path
          data-name="Rectangle 56239"
          transform="translate(11208 6348)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <path
          d="M14 4a9.989 9.989 0 00-8 4.006V6.25a.75.75 0 10-1.5 0v3.5a.75.75 0 00.75.75h3.5a.75.75 0 100-1.5H7.125A8.5 8.5 0 115.5 14 .75.75 0 104 14 10 10 0 1014 4zm-.262 4.489A.75.75 0 0013 9.25v6a.75.75 0 00.75.75h4a.75.75 0 100-1.5H14.5V9.25a.75.75 0 00-.762-.761z"
          transform="translate(11206 6346)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}

export function ViewSummaryReportsEmptyState(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 36,
    height: 40,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="40"
      viewBox="0 0 36 40"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-pdf"
        d="M12.5,4A4.523,4.523,0,0,0,8,8.5V22.051A2.53,2.53,0,0,0,6,24.5v11a2.53,2.53,0,0,0,2,2.449V39.5A4.523,4.523,0,0,0,12.5,44h23A4.523,4.523,0,0,0,40,39.5V37.949A2.53,2.53,0,0,0,42,35.5v-11a2.53,2.53,0,0,0-2-2.449V18.5a1.5,1.5,0,0,0-.439-1.06l-.016-.016L26.56,4.439A1.5,1.5,0,0,0,25.5,4Zm0,3H24v8.5A4.523,4.523,0,0,0,28.5,20H37v2H11V8.5A1.477,1.477,0,0,1,12.5,7ZM27,9.121,34.879,17H28.5A1.477,1.477,0,0,1,27,15.5ZM9,25H39V35H9Zm7,1a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V32h1a3,3,0,0,0,0-6Zm7,0a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h1a4,4,0,0,0,0-8Zm7,0a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V31h1.5a1,1,0,0,0,0-2H31V28h2a1,1,0,0,0,0-2ZM17,28h1a1,1,0,0,1,0,2H17Zm7,0a2,2,0,0,1,0,4ZM11,38H37v1.5A1.477,1.477,0,0,1,35.5,41h-23A1.477,1.477,0,0,1,11,39.5Z"
        transform="translate(-6 -4)"
        fill="gray"
      />
    </SvgIcon>
  );
}

export function RepotUserUploadIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    color: '#239179',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      sx={rootSx}
      {...rest}
    >
      <g
        id="Group_105712"
        data-name="Group 105712"
        transform="translate(-123 -324)"
      >
        <path
          id="icons8-upload-to-cloud"
          d="M7,7a4.42,4.42,0,0,0-4.206,4.194H2.625A2.779,2.779,0,0,0,0,14.1,2.779,2.779,0,0,0,2.625,17H5.763a1.547,1.547,0,0,1-.076-.484v-.484H2.625A1.851,1.851,0,0,1,.875,14.1a1.851,1.851,0,0,1,1.75-1.935h.583a.462.462,0,0,0,.438-.484A3.55,3.55,0,0,1,7,7.968a3.55,3.55,0,0,1,3.354,3.71.462.462,0,0,0,.438.484h.583a1.851,1.851,0,0,1,1.75,1.935,1.851,1.851,0,0,1-1.75,1.935H8.313v.484A1.547,1.547,0,0,1,8.237,17h3.138A2.779,2.779,0,0,0,14,14.1a2.779,2.779,0,0,0-2.625-2.9h-.169A4.42,4.42,0,0,0,7,7Zm-.022,3.549a.418.418,0,0,0-.277.13L4.514,12.937a.515.515,0,0,0-.14.469.467.467,0,0,0,.3.36.409.409,0,0,0,.432-.122l1.452-1.5v4.37a.44.44,0,1,0,.875,0v-4.37l1.452,1.5a.409.409,0,0,0,.432.122.467.467,0,0,0,.3-.36.515.515,0,0,0-.14-.469L7.3,10.679A.414.414,0,0,0,6.978,10.549Z"
          transform="translate(124 320)"
          fill="#888"
        />
        <rect
          id="Rectangle_55949"
          data-name="Rectangle 55949"
          width="16"
          height="16"
          transform="translate(123 324)"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
}

export function ViewDocumentIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    color: '#239179',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      sx={rootSx}
      {...rest}
    >
      <path
        id="icons8-expand"
        d="M6.868,5.98a.888.888,0,0,0-.878,1.012v3.436a.888.888,0,1,0,1.776,0V9.02l3.368,3.368a.888.888,0,1,0,1.256-1.256L9.021,7.765h1.408a.888.888,0,1,0,0-1.776H6.986a.888.888,0,0,0-.119-.009Zm14.2,0a.888.888,0,0,0-.1.009H17.532a.888.888,0,1,0,0,1.776H18.94l-3.368,3.368a.888.888,0,1,0,1.256,1.256L20.2,9.02v1.408a.888.888,0,1,0,1.776,0V6.986a.888.888,0,0,0-.905-1.006ZM11.743,15.3a.888.888,0,0,0-.61.269L7.765,18.94V17.532a.888.888,0,1,0-1.776,0v3.442a.888.888,0,0,0,1,1h3.436a.888.888,0,1,0,0-1.776H9.021l3.368-3.368a.888.888,0,0,0-.645-1.524Zm4.447,0a.888.888,0,0,0-.618,1.524L18.94,20.2H17.532a.888.888,0,1,0,0,1.776h3.442a.888.888,0,0,0,1-1V17.532a.888.888,0,1,0-1.776,0V18.94l-3.368-3.368A.888.888,0,0,0,16.191,15.3Z"
        transform="translate(-5.98 -5.98)"
        fill="#fff"
      />
    </SvgIcon>
  );
}
export function ShareIconDoctor(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 73,
    height: 73,
    color: '#239179',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 73 73"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5623">
        <path
          data-name="Path 943"
          d="M53.521 25a28.521 28.521 0 1028.521 28.521A28.521 28.521 0 0053.521 25z"
          transform="translate(-14.5 -14.5) translate(-1.947 -2.192)"
          fill="#d7eae7"
        />
        <path
          data-name="Path 944"
          d="M51 16a35 35 0 1035 35 35 35 0 00-35-35z"
          fill="none"
          stroke="#444b54"
          strokeWidth={3}
          transform="translate(-14.5 -14.5)"
        />
        <path
          d="M31.105 5a5.885 5.885 0 00-4.559 9.618l-4.053 5.118H16.65a5.895 5.895 0 100 2.526h5.844l4.053 5.118a5.954 5.954 0 101.982-1.566L24.717 21l3.811-4.816a5.825 5.825 0 002.577.605 5.895 5.895 0 000-11.789zm0 2.526a3.368 3.368 0 11-3.368 3.368 3.349 3.349 0 013.368-3.368zm-20.21 10.106A3.368 3.368 0 117.526 21a3.349 3.349 0 013.369-3.368zm20.21 10.105a3.368 3.368 0 11-3.368 3.368 3.349 3.349 0 013.368-3.368z"
          transform="translate(-14.5 -14.5) translate(28 30)"
          fill="#3f464e"
        />
      </g>
    </SvgIcon>
  );
}

export function ImageIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    color: '#239179',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      sx={rootSx}
      {...rest}
    >
      <path
        id="icons8-image"
        d="M8.444,6A2.455,2.455,0,0,0,6,8.444V19.556A2.455,2.455,0,0,0,8.444,22H19.556A2.455,2.455,0,0,0,22,19.556V8.444A2.455,2.455,0,0,0,19.556,6Zm0,1.333H19.556a1.1,1.1,0,0,1,1.111,1.111v9.091l-2.672-2.585h0a1.8,1.8,0,0,0-2.5,0l-.911.881-2.747-2.657a1.795,1.795,0,0,0-2.5,0h0l-2,1.937V8.444A1.1,1.1,0,0,1,8.444,7.333Zm8.444,1.778a2,2,0,0,0-1.5.641,2.092,2.092,0,0,0,0,2.719,2.072,2.072,0,0,0,3,0,2.092,2.092,0,0,0,0-2.719A2,2,0,0,0,16.889,9.111Zm0,1.333a.587.587,0,0,1,.5.193.763.763,0,0,1,0,.948.75.75,0,0,1-1,0,.763.763,0,0,1,0-.948A.587.587,0,0,1,16.889,10.444ZM10.586,14a.457.457,0,0,1,.323.132h0l2.714,2.626-4.04,3.908H8.444a1.1,1.1,0,0,1-1.111-1.111V16.966l2.929-2.833A.459.459,0,0,1,10.586,14Zm6.158,1.778a.456.456,0,0,1,.324.131l3.6,3.482v.165a1.1,1.1,0,0,1-1.111,1.111H11.5l4.918-4.758A.457.457,0,0,1,16.744,15.778Z"
        transform="translate(-6 -6)"
        fill="#000"
      />
    </SvgIcon>
  );
}

export function ReportUserIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    color: '#239179',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      sx={rootSx}
      {...rest}
    >
      <g
        id="Group_105711"
        data-name="Group 105711"
        transform="translate(-123 -349)"
      >
        <rect
          id="Rectangle_55948"
          data-name="Rectangle 55948"
          width="16"
          height="16"
          transform="translate(123 349)"
          fill="none"
        />
        <path
          id="icons8-user"
          d="M12.714,4A3,3,0,1,0,15.66,7,2.98,2.98,0,0,0,12.714,4Zm0,.9A2.1,2.1,0,1,1,10.652,7,2.075,2.075,0,0,1,12.714,4.9ZM9.172,11.2A1.19,1.19,0,0,0,8,12.394v.456a2.8,2.8,0,0,0,1.563,2.419A6.534,6.534,0,0,0,12.714,16a6.534,6.534,0,0,0,3.151-.731A2.893,2.893,0,0,0,17.358,13.3h.071v-.906A1.19,1.19,0,0,0,16.256,11.2Zm0,.9h7.084a.285.285,0,0,1,.289.294V12.4h0v.45a1.828,1.828,0,0,1-1.107,1.631,5.786,5.786,0,0,1-2.724.619,5.786,5.786,0,0,1-2.724-.619A1.828,1.828,0,0,1,8.884,12.85v-.456A.284.284,0,0,1,9.172,12.1Z"
          transform="translate(118.286 347)"
          fill="#888"
          stroke="#888"
          strokeWidth="0.2"
        />
      </g>
    </SvgIcon>
  );
}
export function TrashWithBorder(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 63.3,
    height: 63.3,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63.6 63.6"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5143">
        <path
          data-name="Path 943"
          d="M50.033 25a25.033 25.033 0 1025.033 25.033A25.033 25.033 0 0050.033 25z"
          transform="translate(-14.5 -14.5) translate(-3.266 -3.466)"
          fill="#feeaea"
        />
        <path
          data-name="Path 944"
          d="M46.3 16a30.3 30.3 0 1030.3 30.3A30.3 30.3 0 0046.3 16z"
          fill="none"
          stroke="#444b54"
          strokeWidth={3}
          transform="translate(-14.5 -14.5)"
        />
        <path
          data-name="trash-can (1)"
          d="M16.8 4a3.614 3.614 0 00-3.6 3.6H6.9a.9.9 0 100 1.8h1.5v15.3a3.3 3.3 0 003.3 3.3h10.2a3.3 3.3 0 003.3-3.3V9.4h1.5a.9.9 0 100-1.8h-6.3A3.614 3.614 0 0016.8 4zm0 1.8a1.787 1.787 0 011.8 1.8H15a1.787 1.787 0 011.8-1.8zm-2.7 6.6a.9.9 0 01.9.9v9a.9.9 0 11-1.8 0v-9a.9.9 0 01.9-.9zm5.4 0a.9.9 0 01.9.9v9a.9.9 0 01-1.8 0v-9a.9.9 0 01.9-.9z"
          transform="translate(-14.5 -14.5) translate(29.967 30.3)"
          fill="#f44f5a"
        />
      </g>
    </SvgIcon>
  );
}
export function DropBox(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 105.011,
    height: 119.769,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105.011 119.769"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 105755" opacity={0.3}>
        <g
          data-name="Group 105754"
          transform="translate(-4938.561 -12300.106) translate(4551.906 12299.929)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g data-name="Group 105754" strokeWidth={0.5}>
            <path
              data-name="Path 98479"
              d="M11.168 48.595s.482-.421 1.267-1.171"
              stroke="#59bbea"
              transform="translate(420.721 7.938)"
            />
            <path
              data-name="Path 98480"
              d="M14.857 44.99c7.253-7.642 20.872-25.3-.747-31.808-18.779-5.654-16.36 11.6-6.918 14.331C19.532 31.081 28.98 12.26 18.928 2.167"
              stroke="#007965"
              strokeDasharray="5 6 5 6"
              transform="translate(420.721 7.938)"
            />
            <path
              data-name="Path 98481"
              d="M17.652 1.015A16.708 16.708 0 0016.252 0"
              stroke="#007965"
              transform="translate(420.721 7.938)"
            />
          </g>
          <ellipse
            data-name="Ellipse 129762"
            cx={2.406}
            cy={1.758}
            rx={2.406}
            ry={1.758}
            transform="translate(428.813 5.028)"
            stroke="#007965"
            strokeWidth={1}
          />
          <ellipse
            data-name="Ellipse 129763"
            cx={2.087}
            cy={1.043}
            rx={2.087}
            ry={1.043}
            transform="rotate(-57.84 219.987 -388.019)"
            stroke="#007965"
            strokeWidth={1}
          />
          <ellipse
            data-name="Ellipse 129764"
            cx={1.043}
            cy={2.087}
            rx={1.043}
            ry={2.087}
            transform="rotate(-4.42 226.36 -5571.067)"
            stroke="#007965"
            strokeWidth={1}
          />
        </g>
        <g data-name="28534669_apps_illustration_2">
          <path
            data-name="Path 98469"
            d="M139.481 303.506a20.886 20.886 0 01-5.177-13.85v-.092c0-11.594-11.847-20.993-26.462-20.993-10.466 0-19.512 4.821-23.8 11.815a20.344 20.344 0 01-10.608 8.6c-9.315 3.249-15.82 10.635-15.82 19.225 0 11.594 11.847 20.993 26.462 20.993a32.243 32.243 0 0011.1-1.933 20.343 20.343 0 0115.611.759 25.9 25.9 0 0010.923 2.35c11.927 0 21.6-7.671 21.6-17.133a14.751 14.751 0 00-3.825-9.738z"
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871) translate(-50.067 -116.372)"
            fill="#f1f1f1"
          />
          <path
            data-name="Path 98470"
            d="M203.971 216.89l-32.9 17.373 30.8 12.154 32.9-17.372z"
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871) translate(-150.413 -70.665)"
            fill="#dbeae7"
          />
          <path
            data-name="Path 98471"
            d="M486.516 229.045l-30.8-12.155v28.416z"
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871) translate(-402.162 -70.665)"
            fill="#a9cdc6"
          />
          <path
            data-name="Path 98472"
            d="M470.411 359.239l-32.9 17.372v-37.189l32.9-17.372z"
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871) translate(-386.057 -163.67)"
            fill="#ffede2"
          />
          <path
            data-name="Path 98473"
            d="M84.354 195.569l-32.9 17.372v-37.189l32.9-17.372z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
          <path
            data-name="Path 98474"
            d="M53.558 146.225l-32.9 17.373L1 156.486l32.9-17.373z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
          <path
            data-name="Path 98475"
            d="M104.011 165.492l-32.9 17.373-19.657-7.113 32.9-17.372z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
          <path
            data-name="Path 98476"
            d="M20.657 200.787l30.8 12.154v-37.189l-30.8-12.154z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
          <path
            data-name="Path 98477"
            d="M20.658 163.598l-17.346 9.235 30.795 12.154 17.347-9.235z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
          <path
            data-name="Path 98478"
            d="M70.905 136.99l-17.347 9.235 30.8 12.155 17.347-9.235z"
            fill="#c4e8e2"
            stroke="#007965"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            transform="translate(-4938.561 -12300.106) translate(4938.561 12205.871)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export function ReportsEmptyState(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 128.252,
    height: 118.438,
    color: '#c5e8e2',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128.252 118.438"
      sx={rootSx}
      {...rest}
    >
      <path
        data-name="Path 20625"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="currentcolor"
      />
      <path
        data-name="Path 20626"
        d="M199.688 152.67c.327-1.875.852-3.73 1.228-5.64a33.34 33.34 0 00.157-11.169 43.873 43.873 0 00-8.233-19.6c-4.586-6.152-11.482-11.134-18.894-9.252-7.711 1.949-14.238 7.9-21.737 10.609-12.7 4.593-25.557-3.779-38.355-3.48-11.051.24-22.645 4.207-29.924 14.016-2.972 4-5.042 9.395-4.339 14.7.859 6.462 5.6 11.378 9.137 16.159 5.421 7.328 7.474 15.31 2.912 23.863-3.083 5.8-7.23 10.919-6.872 18.118.574 11.517 11.208 18.473 19.976 21.573 8.908 3.152 19.023 3.48 27.878-.07 7.867-3.166 13.358-10.091 20.529-14.51a32.45 32.45 0 0121.055-4.621c7.578.9 15.564 2.5 22.888-.769 7.008-3.132 10.327-11.5 10.588-19.52a32.063 32.063 0 00-1.778-11.775c-1.625-4.544-5.219-8.09-6.232-12.909a14.962 14.962 0 01.014-5.717z"
        transform="translate(-77.798 -112.187) translate(77.798 112.187) translate(-79.454 -106.601)"
        fill="#fff"
        opacity={0.7}
      />
      <path
        d="M16.558 4A8.569 8.569 0 008 12.558v58.957a8.569 8.569 0 008.558 8.558h24.991a24.847 24.847 0 01-4.112-5.705H16.558a2.856 2.856 0 01-2.853-2.853V12.558a2.856 2.856 0 012.853-2.853h21.871v16.166a8.569 8.569 0 008.558 8.558h16.166v4.127a24.415 24.415 0 015.705 1.586v-8.565a2.842 2.842 0 00-.836-2.017L43.3 4.836A2.842 2.842 0 0041.282 4zm27.577 9.739l14.984 14.985H46.988a2.856 2.856 0 01-2.853-2.853zm15.215 28.3a20.92 20.92 0 1020.92 20.92 20.92 20.92 0 00-20.921-20.922zm-9.51 9.507a1.9 1.9 0 011.345.557l8.165 8.164 8.164-8.167a1.9 1.9 0 112.686 2.692l-8.164 8.165 8.164 8.164a1.9 1.9 0 11-2.689 2.689l-8.165-8.164-8.165 8.164a1.9 1.9 0 01-2.681-2.689l8.164-8.165-8.164-8.164a1.9 1.9 0 011.345-3.246z"
        transform="translate(-77.798 -112.187) translate(97.905 127.252)"
        fill="#8fb7b0"
      />
    </SvgIcon>
  );
}

export function OrgEmail(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 36,
    height: 36,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 105737">
        <g
          data-name="Ellipse 129752"
          transform="translate(-540 -304) translate(540 304)"
          fill="#fff"
          stroke="#c5c5c5"
          strokeWidth={1.5}
        >
          <circle cx={18} cy={18} r={18} stroke="none" />
          <circle cx={18} cy={18} r={17.25} fill="none" />
        </g>
        <path
          data-name="icons8-mail (1)"
          d="M6.6 8A2.609 2.609 0 004 10.6v7.6a2.609 2.609 0 002.6 2.6h10.8a2.609 2.609 0 002.6-2.6v-7.6A2.609 2.609 0 0017.4 8zm0 1.2h10.8a1.391 1.391 0 011.4 1.4v.442L12 14.718l-6.8-3.676V10.6a1.391 1.391 0 011.4-1.4zm-1.4 3.206l6.515 3.522a.6.6 0 00.57 0l6.515-3.522V18.2a1.391 1.391 0 01-1.4 1.4H6.6a1.391 1.391 0 01-1.4-1.4z"
          transform="translate(-540 -304) translate(546 307.6)"
          fill="#b7b7b7"
        />
      </g>
    </SvgIcon>
  );
}

export function OrgCall(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 36,
    height: 36,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 105737">
        <g
          data-name="Ellipse 129752"
          transform="translate(-540 -304) translate(540 304)"
          fill="#fff"
          stroke="#c5c5c5"
          strokeWidth={1.5}
        >
          <circle cx={18} cy={18} r={18} stroke="none" />
          <circle cx={18} cy={18} r={17.25} fill="none" />
        </g>
        <path
          data-name="icons8-phone (1)"
          d="M13.013 4a2.238 2.238 0 00-.8.086l-.947.28A3.015 3.015 0 009.205 6.48c-.592 2.247.085 4.906 1.848 7.9s3.767 4.889 6.032 5.51a3.07 3.07 0 002.893-.705l.72-.668a2.181 2.181 0 00.275-2.88l-1.041-1.413a2.239 2.239 0 00-2.445-.809l-1.628.487c-.068.02-.232-.073-.374-.2a5.627 5.627 0 01-.98-1.3 5.812 5.812 0 01-.644-1.4.752.752 0 01-.042-.386.189.189 0 01.063-.119L15.1 9.39a2.175 2.175 0 00.513-2.507l-.732-1.6A2.225 2.225 0 0013.013 4zm-.087 1.2a1.03 1.03 0 01.86.585l.733 1.6a.959.959 0 01-.232 1.122l-1.213 1.107a1.392 1.392 0 00-.446.87 2.383 2.383 0 00.073.823 6.86 6.86 0 00.77 1.7 6.424 6.424 0 001.227 1.6 1.582 1.582 0 001.5.442l1.635-.49a1.037 1.037 0 011.133.371l1.041 1.413a.965.965 0 01-.125 1.289l-.72.669a1.89 1.89 0 01-1.761.427c-1.832-.5-3.644-2.124-5.314-4.96s-2.2-5.189-1.723-6.985a1.83 1.83 0 011.246-1.261l.946-.28a1.035 1.035 0 01.371-.042z"
          transform="translate(-540 -304) translate(542.806 310.001)"
          fill="#c5c5c5"
        />
      </g>
    </SvgIcon>
  );
}
export function OrgAddress(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 36,
    height: 36,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 105737">
        <g
          data-name="Ellipse 129752"
          transform="translate(-540 -304) translate(540 304)"
          fill="#fff"
          stroke="#c5c5c5"
          strokeWidth={1.5}
        >
          <circle cx={18} cy={18} r={18} stroke="none" />
          <circle cx={18} cy={18} r={17.25} fill="none" />
        </g>
        <path
          data-name="icons8-address (1)"
          d="M13.784 5A5.2 5.2 0 008.6 10.189a8.773 8.773 0 001.613 4.111 40.055 40.055 0 002.487 3.562 1.394 1.394 0 002.177 0 40.055 40.055 0 002.478-3.562 8.773 8.773 0 001.618-4.115A5.2 5.2 0 0013.784 5zm0 1.3a3.882 3.882 0 013.892 3.892 9.1 9.1 0 01-1.43 3.441 39.1 39.1 0 01-2.4 3.438c-.043.055-.084.055-.127 0a39.1 39.1 0 01-2.4-3.438 9.1 9.1 0 01-1.43-3.441A3.882 3.882 0 0113.784 6.3zm0 2.595a1.3 1.3 0 101.3 1.3 1.3 1.3 0 00-1.3-1.303zm-5.308 6.916a1.515 1.515 0 00-1.383.9l-.96 2.162A1.513 1.513 0 007.515 21h12.538a1.514 1.514 0 001.383-2.128l-.96-2.162a1.515 1.515 0 00-1.383-.9h-1.408q-.413.626-.9 1.3h2.3a.216.216 0 01.2.128l.96 2.162a.216.216 0 01-.2.3H7.515a.216.216 0 01-.2-.3l.961-2.162a.217.217 0 01.2-.128h2.3q-.485-.672-.9-1.3z"
          transform="translate(-540 -304) translate(544.216 309)"
          fill="#c5c5c5"
        />
      </g>
    </SvgIcon>
  );
}

export function MenuIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 28,
    height: 28,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 92"
      sx={rootSx}
      {...rest}
    >
      <path d="M78 23.5H14c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5h64c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5zM84.5 46c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5zm0 29c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5z" />
    </SvgIcon>
  );
}
export function SeekIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 21,
    width: 21,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-ask-question"
        d="M12,4a8.009,8.009,0,0,0-8,8,7.926,7.926,0,0,0,.9,3.627l-.867,3.1A1.021,1.021,0,0,0,5.27,19.962l3.107-.867A7.924,7.924,0,0,0,12,20,8,8,0,1,0,12,4Zm0,1.2A6.8,6.8,0,1,1,8.7,17.94a.6.6,0,0,0-.453-.054l-2.955.824.825-2.953A.6.6,0,0,0,6.06,15.3,6.8,6.8,0,0,1,12,5.2Zm0,2.4A2.409,2.409,0,0,0,9.6,10a.6.6,0,1,0,1.2,0,1.2,1.2,0,1,1,2.4,0c0,.634-.257.821-.709,1.244A2.88,2.88,0,0,0,11.4,13.4a.6.6,0,1,0,1.2,0c0-.664.261-.861.709-1.28A2.837,2.837,0,0,0,14.4,10,2.409,2.409,0,0,0,12,7.6Zm0,7.2a.8.8,0,1,0,.8.8A.8.8,0,0,0,12,14.8Z"
        transform="translate(-4 -3.998)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function LogoutIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 21,
    width: 21,
    color: '#db6464',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-logout"
        d="M8.444,6A2.455,2.455,0,0,0,6,8.444V19.556A2.455,2.455,0,0,0,8.444,22h8a2.455,2.455,0,0,0,2.444-2.444.667.667,0,1,0-1.333,0,1.1,1.1,0,0,1-1.111,1.111h-8a1.1,1.1,0,0,1-1.111-1.111V8.444A1.1,1.1,0,0,1,8.444,7.333h8a1.1,1.1,0,0,1,1.111,1.111.667.667,0,1,0,1.333,0A2.455,2.455,0,0,0,16.444,6Zm9.771,4.215a.667.667,0,0,0-.464,1.145l1.973,1.973h-9.5a.667.667,0,1,0,0,1.333h9.5L17.751,16.64a.667.667,0,1,0,.943.943L21.8,14.471a.667.667,0,0,0,0-.943l-3.111-3.111a.667.667,0,0,0-.478-.2Z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function CameraIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 14,
    width: 14,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="13.333"
      height="12"
      viewBox="0 0 13.333 12"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-compact-camera_1_"
        data-name="icons8-compact-camera (1)"
        d="M6.167,5a.5.5,0,0,0-.5.5v.227A2.169,2.169,0,0,0,4,7.833v7A2.169,2.169,0,0,0,6.167,17h9a2.169,2.169,0,0,0,2.167-2.167v-7a2.169,2.169,0,0,0-2.167-2.167H9V5.5A.5.5,0,0,0,8.5,5Zm.667,3.333h1a.5.5,0,0,1,0,1h-1a.5.5,0,1,1,0-1Zm5.167,0a3,3,0,1,1-3,3A3,3,0,0,1,12,8.333Zm0,1a2,2,0,1,0,2,2A2,2,0,0,0,12,9.333Z"
        transform="translate(-4 -5)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function ShareReport(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 63.6,
    width: 63.6,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63.6 63.6"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Share Report">
        <path
          data-name="Path 943"
          d="M50.033 25a25.033 25.033 0 1025.033 25.033A25.033 25.033 0 0050.033 25z"
          transform="translate(-14.5 -14.5) translate(-3.266 -3.466)"
          fill="#e1f5f2"
        />
        <path
          data-name="Path 944"
          d="M46.3 16a30.3 30.3 0 1030.3 30.3A30.3 30.3 0 0046.3 16z"
          fill="none"
          stroke="#444b54"
          strokeWidth={3}
          transform="translate(-14.5 -14.5)"
        />
        <path
          data-name="My family"
          d="M14.532 4.5a6.253 6.253 0 106.253 6.253A6.268 6.268 0 0014.532 4.5zm0 1.975a4.278 4.278 0 11-4.278 4.278 4.264 4.264 0 014.278-4.278zm9.215 6.911a3.92 3.92 0 00-2.959 1.257 4.155 4.155 0 000 5.384 4.112 4.112 0 005.919 0 4.155 4.155 0 000-5.384 3.92 3.92 0 00-2.96-1.257zm0 1.975a1.82 1.82 0 011.484.594 2.187 2.187 0 010 2.762 1.82 1.82 0 01-1.484.594 1.82 1.82 0 01-1.484-.594 2.187 2.187 0 010-2.762 1.82 1.82 0 011.484-.594zM6.962 19.968A2.966 2.966 0 004 22.93v.789c0 3.8 4.626 6.78 10.532 6.78a16.289 16.289 0 003.5-.377 5.714 5.714 0 01-1.565-1.718 15.565 15.565 0 01-1.935.12c-5.286 0-8.557-2.495-8.557-4.806v-.788a.989.989 0 01.987-.987h10.9a4.245 4.245 0 012.269-.658h.149a5.313 5.313 0 01-1.083-1.316zM20.127 22.6a2.977 2.977 0 00-2.962 2.962v.554a3.906 3.906 0 002.006 3.2 8.506 8.506 0 004.576 1.184 8.506 8.506 0 004.577-1.187 3.906 3.906 0 002.006-3.2v-.554a2.977 2.977 0 00-2.963-2.959zm0 1.975h7.241a.973.973 0 01.987.987v.554a1.953 1.953 0 01-1.093 1.531 6.656 6.656 0 01-3.515.877 6.656 6.656 0 01-3.515-.877 1.953 1.953 0 01-1.093-1.531v-.554a.973.973 0 01.988-.986z"
          transform="translate(-14.5 -14.5) translate(29.135 29.067)"
          fill="#444b54"
        />
      </g>
    </SvgIcon>
  );
}

export function NoActivePrescriptionIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 48.163,
    width: 38.822,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="38.822"
      height="48.163"
      viewBox="0 0 38.822 48.163"
      {...rest}
      sx={rootSx}
    >
      <path
        id="Prescription"
        d="M13.459,4A5.467,5.467,0,0,0,8,9.418V46.745a5.467,5.467,0,0,0,5.459,5.418h27.9a5.467,5.467,0,0,0,5.459-5.418V21.459a1.8,1.8,0,0,0-.533-1.277l-.019-.019L30.517,4.529A1.827,1.827,0,0,0,29.231,4Zm0,3.612H27.411V17.847a5.467,5.467,0,0,0,5.459,5.418H43.183V46.745a1.786,1.786,0,0,1-1.82,1.806h-27.9a1.786,1.786,0,0,1-1.82-1.806V9.418A1.786,1.786,0,0,1,13.459,7.612Zm17.591,2.554,9.559,9.487H32.87a1.786,1.786,0,0,1-1.82-1.806ZM16.8,20.857a1.511,1.511,0,0,0-1.516,1.505V33.8a1.517,1.517,0,0,0,3.033,0V31.694h2.426a5.4,5.4,0,0,0,.628-.063l4.865,7.215-3.407,4.943a1.5,1.5,0,0,0,.4,2.093,1.521,1.521,0,0,0,2.107-.393l2.723-3.949,2.661,3.949a1.521,1.521,0,0,0,2.106.393,1.5,1.5,0,0,0,.4-2.093l-3.32-4.924,3.32-4.814a1.5,1.5,0,0,0-.4-2.093,1.522,1.522,0,0,0-2.106.393l-2.633,3.819L24.2,30.426a5.37,5.37,0,0,0,2-4.151,5.446,5.446,0,0,0-5.459-5.418Zm1.516,3.01h2.426a2.408,2.408,0,1,1,0,4.816H18.312Z"
        transform="translate(-8 -4)"
        fill="#d4d4d4"
      />
    </SvgIcon>
  );
}
export function BloodGroup(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 41,
    height: 41,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      id="Group_5615"
      data-name="Group 5615"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Rectangle_44700"
        data-name="Rectangle 44700"
        fill="#e6fff9"
        stroke="#d3f5ed"
        strokeWidth="1"
      >
        <rect width="40" height="41" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="39" height="40" rx="3.5" fill="none" />
      </g>
      <path
        id="icons8-blood_group"
        data-name="icons8-blood group"
        d="M14.568,4a.577.577,0,0,0-.46.251S12.972,5.911,11.751,7.88a27.464,27.464,0,0,0-2.915,5.582,4.2,4.2,0,0,0,1.4,4.212,6.382,6.382,0,0,0,8.7,0,4.2,4.2,0,0,0,1.4-4.212A27.464,27.464,0,0,0,17.418,7.88C16.2,5.911,15.061,4.251,15.061,4.251A.577.577,0,0,0,14.568,4Zm.017,1.636c.331.49.894,1.307,1.853,2.853a29.958,29.958,0,0,1,2.783,5.281,2.985,2.985,0,0,1-1.068,3.054,5.519,5.519,0,0,1-3.569,1.408,5.519,5.519,0,0,1-3.569-1.408A2.985,2.985,0,0,1,9.948,13.77a29.958,29.958,0,0,1,2.783-5.281C13.691,6.943,14.253,6.126,14.584,5.636Zm-2.893,7.587a.577.577,0,0,0-.557.472,2.637,2.637,0,0,0,.826,2.627,3.81,3.81,0,0,0,2.433.756.577.577,0,1,0,0-1.154,2.8,2.8,0,0,1-1.7-.494,1.348,1.348,0,0,1-.424-1.508.577.577,0,0,0-.574-.7Z"
        transform="translate(4.287 8.808)"
        fill="#45c6a6"
      />
    </SvgIcon>
  );
}
export function Stethescope(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 13.247,
    height: 14.99,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="icons8-stethoscope (2)"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.247 14.99"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="Path 4313"
        d="M7.827 14.824h.028a4.146 4.146 0 004.093-4.135v-.912a.415.415 0 10-.83 0v.913a3.305 3.305 0 01-3.263 3.3 3.215 3.215 0 01-2.337-.954 3.273 3.273 0 01-.982-2.351v-.111a.415.415 0 10-.83 0v.111a4.131 4.131 0 004.121 4.135zm-3.692-5.31a4.122 4.122 0 004.134-4.107V.968A.962.962 0 007.3 0a.407.407 0 00-.415.415A.407.407 0 007.3.83a.131.131 0 01.14.138v4.439a3.305 3.305 0 01-6.61 0V.968A.131.131 0 01.968.83a.407.407 0 00.415-.415A.407.407 0 00.968 0 .962.962 0 000 .968v4.439a4.122 4.122 0 004.135 4.107z"
        transform="translate(0 .166)"
        fill="#007965"
      />
      <path
        data-name="Path 4314"
        d="M.415 1.148A.561.561 0 00.968.567a.542.542 0 00-.124-.36A.518.518 0 00.415 0 .407.407 0 000 .415a.5.5 0 00.028.152A.351.351 0 000 .733a.416.416 0 00.415.415zm3.485 0a.407.407 0 00.414-.415.694.694 0 00-.027-.166.588.588 0 00.027-.152A.407.407 0 003.9 0a.558.558 0 00-.553.567.542.542 0 00.124.36.5.5 0 00.429.221z"
        transform="translate(1.977)"
        fill="#007965"
      />
      <path
        data-name="Path 4315"
        d="M1.286 0a1.293 1.293 0 011.286 1.3 1.293 1.293 0 01-1.286 1.3A1.293 1.293 0 010 1.3 1.293 1.293 0 011.286 0z"
        transform="translate(10.261 5.974)"
        fill="#007965"
      />
      <path
        data-name="Path 4316"
        d="M1.7 3.429A1.715 1.715 0 001.7 0a1.715 1.715 0 000 3.429zm0-2.6a.885.885 0 11-.871.885A.879.879 0 011.7.83z"
        transform="translate(9.846 5.559)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function AddSummaryIcon(props) {
  const { rootStyle, fill, ...rest } = props;
  const rootSx = {
    width: 18,
    height: 18,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M14 4a10 10 0 1010 10A10.011 10.011 0 0014 4zm4.25 10.75h-3.5v3.5a.75.75 0 01-1.5 0v-3.5h-3.5a.75.75 0 010-1.5h3.5v-3.5a.75.75 0 011.5 0v3.5h3.5a.75.75 0 010 1.5z"
        transform="translate(-4 -4)"
        fill={fill}
      />
    </SvgIcon>
  );
}

export function SummaryTrashIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 15.2,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.2 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M12.6 4a2.809 2.809 0 00-2.768 2.4H7.1a.539.539 0 00-.192 0H5.6a.6.6 0 100 1.2h.855l1.007 10.412A2.208 2.208 0 009.652 20h5.895a2.207 2.207 0 002.19-1.988L18.744 7.6h.856a.6.6 0 100-1.2h-1.3a.6.6 0 00-.192 0h-2.74A2.809 2.809 0 0012.6 4zm0 1.2a1.585 1.585 0 011.545 1.2h-3.09A1.585 1.585 0 0112.6 5.2zM7.66 7.6h9.879l-1 10.3a.992.992 0 01-1 .9H9.652a.993.993 0 01-1-.9zm3.53 1.991a.6.6 0 00-.591.609v6a.6.6 0 101.2 0v-6a.6.6 0 00-.609-.609zm2.8 0a.6.6 0 00-.591.609v6a.6.6 0 101.2 0v-6a.6.6 0 00-.609-.609z"
        transform="translate(-5 -4)"
        fill="#f44f5a"
      />
    </SvgIcon>
  );
}

export function SummaryEditIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M18.057 5.01a2.938 2.938 0 00-2.084.86L6.65 15.191a1.474 1.474 0 00-.375.638L5.025 20.2a.632.632 0 00.781.781l4.376-1.25a1.477 1.477 0 00.637-.374l9.322-9.323a2.944 2.944 0 00-2.084-5.024zm0 1.256a1.677 1.677 0 011.19.5 1.675 1.675 0 010 2.382l-.817.817-2.381-2.385.817-.817a1.679 1.679 0 011.191-.497zm-2.9 2.207l2.382 2.382-7.613 7.613a.213.213 0 01-.091.053l-3.282.938.938-3.283a.206.206 0 01.053-.09z"
        transform="translate(-5.001 -5.01)"
        fill="rgba(24,68,87,0.72)"
      />
    </SvgIcon>
  );
}

export function SummaryTickIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-ok (1)"
        d="M16 4a12 12 0 1012 12A12.014 12.014 0 0016 4zm5.137 9.937l-6 6a.9.9 0 01-1.273 0l-3-3a.9.9 0 011.273-1.273l2.363 2.363 5.363-5.363a.9.9 0 011.274 1.273z"
        transform="translate(-4 -4)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function SummaryCloseIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 33.941,
    height: 33.941,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.941 33.941"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M16 4a12 12 0 1012 12A12.014 12.014 0 0016 4zm5.1 12.9h-4.2v4.2a.9.9 0 01-1.8 0v-4.2h-4.2a.9.9 0 010-1.8h4.2v-4.2a.9.9 0 011.8 0v4.2h4.2a.9.9 0 010 1.8z"
        transform="rotate(-45 17.657 15.314)"
        fill="#f44f5a"
      />
    </SvgIcon>
  );
}

export function ScheduleCalender(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-schedule (1)"
        d="M4.381 0a4.381 4.381 0 104.381 4.381A4.39 4.39 0 004.381 0zm0 1.143a3.238 3.238 0 11-3.238 3.238 3.229 3.229 0 013.238-3.238zm-.009.757a.571.571 0 00-.563.58v1.9a.571.571 0 00.167.4l.762.762a.571.571 0 10.808-.808l-.594-.595V2.476a.571.571 0 00-.58-.58zm4.7.389a5.089 5.089 0 01.358 1.143h4.092a1.335 1.335 0 011.333 1.333v.571H9.432a5.128 5.128 0 01-.358 1.143h5.783v7.048a1.335 1.335 0 01-1.333 1.333H4.762a1.335 1.335 0 01-1.333-1.333V9.432a5.128 5.128 0 01-1.143-.358v4.449A2.479 2.479 0 004.762 16h8.762A2.479 2.479 0 0016 13.524V4.762a2.479 2.479 0 00-2.476-2.476zM9.143 8a.952.952 0 10.952.952A.952.952 0 009.143 8zm3.238 0a.952.952 0 10.952.952.952.952 0 00-.952-.952zM5.9 11.429a.952.952 0 10.952.952.952.952 0 00-.952-.952zm3.238 0a.952.952 0 10.952.952.952.952 0 00-.947-.952z"
      />
    </SvgIcon>
  );
}

export function SplitIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 17.096,
    height: 17.543,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="17.096"
      height=" 17.543"
      viewBox="0 0 17.096 17.543"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-rename"
        d="M10.794,4a.658.658,0,1,0,0,1.315h1.1v14.9h-1.1a.658.658,0,1,0,0,1.315H12.44a.658.658,0,0,0,.213,0H14.3a.658.658,0,1,0,0-1.315h-1.1V5.315h1.1A.658.658,0,1,0,14.3,4ZM6.849,6.63A2.859,2.859,0,0,0,4,9.479v6.575A2.859,2.859,0,0,0,6.849,18.9h4.164V17.589H6.849a1.525,1.525,0,0,1-1.534-1.534V9.479A1.525,1.525,0,0,1,6.849,7.945h4.164V6.63Zm7.233,0V7.945h4.164a1.525,1.525,0,0,1,1.534,1.534v6.575a1.525,1.525,0,0,1-1.534,1.534H14.082V18.9h4.164A2.859,2.859,0,0,0,21.1,16.055V9.479A2.859,2.859,0,0,0,18.246,6.63Z"
        transform="translate(-4 -4)"
        fill="#4D4D4D"
      />
    </SvgIcon>
  );
}

export function PhoneIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 20,
    height: 18,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.055 18.737"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-call (2)"
        d="M8.864 0a3.067 3.067 0 00-.774.109c-2.266.62-4.271 2.519-6.032 5.509s-2.441 5.653-1.848 7.9a3.013 3.013 0 002.063 2.109l.947.28a2.235 2.235 0 002.662-1.2l.733-1.6a2.177 2.177 0 00-.515-2.499L4.888 9.5a.2.2 0 01-.063-.12.748.748 0 01.042-.384A5.806 5.806 0 015.51 7.6a5.61 5.61 0 01.98-1.3c.142-.123.3-.216.373-.2l1.629.488a2.239 2.239 0 002.444-.809l1.04-1.413a2.18 2.18 0 00-.276-2.884l-.72-.669A3.049 3.049 0 009.629.088 3.088 3.088 0 008.864 0zm.013 1.2a1.915 1.915 0 01.468.055 1.855 1.855 0 01.823.437l.72.668a.966.966 0 01.125 1.29L9.972 5.064a1.037 1.037 0 01-1.133.371L7.2 4.945a1.584 1.584 0 00-1.5.441 6.406 6.406 0 00-1.228 1.6A6.87 6.87 0 003.7 8.69a2.376 2.376 0 00-.073.823 1.4 1.4 0 00.445.87l1.214 1.11a.958.958 0 01.233 1.121l-.732 1.6a1.038 1.038 0 01-1.231.545l-.947-.28a1.827 1.827 0 01-1.239-1.266c-.474-1.8.05-4.145 1.723-6.986s3.482-4.46 5.314-4.961a1.84 1.84 0 01.47-.066z"
        transform="rotate(-60 9.299 5.369)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function RegisterIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 18,
    height: 18,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 15.405 13.864"
      {...rest}
    >
      <path
        d="M10.7 4a2.2 2.2 0 00-2.2 2.189v.73H4.651A1.655 1.655 0 003 8.561v7.662a1.655 1.655 0 001.651 1.642h12.1a1.655 1.655 0 001.649-1.643V8.561a1.655 1.655 0 00-1.651-1.642H12.9v-.73A2.2 2.2 0 0010.7 4zm0 1.095a1.089 1.089 0 011.1 1.095v1.641a.175.175 0 01-.183.182H9.785a.175.175 0 01-.185-.182V6.189a1.089 1.089 0 011.1-1.094zM4.651 8.013H8.52a1.288 1.288 0 001.265 1.095h1.834a1.288 1.288 0 001.265-1.095h3.87a.54.54 0 01.55.547v7.662a.54.54 0 01-.55.547H4.651a.54.54 0 01-.55-.547V8.561a.54.54 0 01.55-.548zM7.4 10.2a1.095 1.095 0 101.1 1.1 1.1 1.1 0 00-1.1-1.1zm4.218.73a.547.547 0 100 1.095h3.668a.547.547 0 100-1.095zm-5.5 2.189a.549.549 0 00-.55.547c0 .547 0 1.642 1.8 1.642h.073c1.8 0 1.8-1.095 1.8-1.642a.549.549 0 00-.55-.547zm5.5.365a.547.547 0 100 1.095h3.668a.547.547 0 100-1.095z"
        transform="translate(-3 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function StateCoucilIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 18,
    height: 18,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.798 13.864"
      {...rest}
    >
      <path
        data-name="icons8-medical-doctor (1)"
        d="M7.4 0a4.969 4.969 0 00-2.217.383 1.539 1.539 0 00-.467.333A1.07 1.07 0 004.549 1a.6.6 0 00-.033.133l-.283 3.65v.083l-.033.366a.8.8 0 000 .1 3.2 3.2 0 106.4 0v-.1l-.317-4.1A.6.6 0 0010.248 1a1.07 1.07 0 00-.167-.283 1.54 1.54 0 00-.466-.334A4.969 4.969 0 007.4 0zm0 8.532c-3.52 0-6.4 1.656-6.4 3.149v2.183h12.8v-2.183c0-1.419-2.6-2.981-5.882-3.133a7.524 7.524 0 00-.518-.016zm0-7.465a4.087 4.087 0 011.733.267.325.325 0 01.1.067l.217 2.733c-.492.029-1.158.133-2.05.133s-1.558-.1-2.05-.133L5.566 1.4a.325.325 0 01.1-.067A4.087 4.087 0 017.4 1.066zm-.434.533v.633h-.634V3.1h.633v.633h.867V3.1h.633v-.867h-.633V1.6zM5.8 9.732L7.166 12.8h-5.1v-1.119c0-.397 1.361-1.56 3.734-1.949zm3.2 0c2.373.39 3.733 1.552 3.733 1.95V12.8h-5.1z"
        transform="translate(-1)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function DocumentColorIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 13.05,
    height: 16.25,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.05 16.25"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-document (1)"
        d="M1.925.125a1.809 1.809 0 00-1.8 1.8v12.4a1.809 1.809 0 001.8 1.8h9.2a1.809 1.809 0 001.8-1.8v-8.4a.6.6 0 00-.176-.424l-.006-.006L7.549.301a.6.6 0 00-.424-.176zm0 1.2h4.6v3.4a1.809 1.809 0 001.8 1.8h3.4v7.8a.591.591 0 01-.6.6h-9.2a.591.591 0 01-.6-.6v-12.4a.591.591 0 01.6-.6zm5.8.848l3.152 3.152H8.325a.591.591 0 01-.6-.6zm-3.8 6.352a.6.6 0 100 1.2h5.2a.6.6 0 100-1.2zm0 2.8a.6.6 0 100 1.2h3.6a.6.6 0 100-1.2z"
        fill="#007965"
        stroke="#007965"
        strokeWidth={0.25}
      />
    </SvgIcon>
  );
}

export function SummaryCalendarIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-schedule (1)"
        d="M4.381 0a4.381 4.381 0 104.381 4.381A4.39 4.39 0 004.381 0zm0 1.143a3.238 3.238 0 11-3.238 3.238 3.229 3.229 0 013.238-3.238zm-.009.757a.571.571 0 00-.563.58v1.9a.571.571 0 00.167.4l.762.762a.571.571 0 10.808-.808l-.594-.595V2.476a.571.571 0 00-.58-.58zm4.7.389a5.089 5.089 0 01.358 1.143h4.092a1.335 1.335 0 011.333 1.333v.571H9.432a5.128 5.128 0 01-.358 1.143h5.783v7.048a1.335 1.335 0 01-1.333 1.333H4.762a1.335 1.335 0 01-1.333-1.333V9.432a5.128 5.128 0 01-1.143-.358v4.449A2.479 2.479 0 004.762 16h8.762A2.479 2.479 0 0016 13.524V4.762a2.479 2.479 0 00-2.476-2.476zM9.143 8a.952.952 0 10.952.952A.952.952 0 009.143 8zm3.238 0a.952.952 0 10.952.952.952.952 0 00-.952-.952zM5.9 11.429a.952.952 0 10.952.952.952.952 0 00-.952-.952zm3.238 0a.952.952 0 10.952.952.952.952 0 00-.947-.952z"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function RxDownloadIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M3 20a2.015 2.015 0 01-2-2V6a2.015 2.015 0 012-2h18a2.015 2.015 0 012 2v12a2.015 2.015 0 01-2 2zm0-2h18V6H3zm9-2l-4-4h3V8h2v4h3z"
        transform="translate(-1 -4)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function NotABit(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={21.969}
      height={21.365}
      viewBox="0 0 21.969 21.365"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Not a bit">
        <circle
          data-name="Ellipse 129842"
          cx={3.67}
          cy={3.67}
          r={3.67}
          transform="rotate(-80.74 14.394 -.409)"
          fill="#fff"
        />
        <circle
          data-name="Ellipse 129843"
          cx={3.67}
          cy={3.67}
          r={3.67}
          transform="rotate(-76.63 8.676 6.65)"
          fill="#fff"
        />
        <path
          data-name="Path 99488"
          d="M209.1 830.5a3.669 3.669 0 01-5.24 4.965 3.67 3.67 0 105.24-4.965z"
          transform="translate(-202.322 -822.631)"
          fill="#efefef"
        />
        <path
          data-name="Path 99489"
          d="M459.79 830.5a3.669 3.669 0 01-5.24 4.965 3.67 3.67 0 105.24-4.965z"
          transform="translate(-440.938 -822.631)"
          fill="#efefef"
        />
        <path
          data-name="Path 99490"
          d="M191.989 820.007a3.839 3.839 0 113.839-3.839 3.843 3.843 0 01-3.839 3.839zm0-7.341a3.5 3.5 0 103.5 3.5 3.506 3.506 0 00-3.5-3.499z"
          transform="translate(-187.369 -805.336)"
          fill="#231f20"
        />
        <path
          data-name="Path 99491"
          d="M234.586 891.108a1.778 1.778 0 11-1.778-1.778 1.778 1.778 0 011.778 1.778z"
          transform="translate(-228.183 -878.627)"
          fill="#231f20"
        />
        <path
          data-name="Path 99492"
          d="M245.235 905.168a.728.728 0 11-.728-.728.727.727 0 01.728.728z"
          transform="translate(-240.319 -893.009)"
          fill="#fff"
        />
        <path
          data-name="Path 99493"
          d="M442.669 820.007a3.839 3.839 0 113.839-3.839 3.843 3.843 0 01-3.839 3.839zm0-7.341a3.5 3.5 0 103.5 3.5 3.506 3.506 0 00-3.5-3.499z"
          transform="translate(-425.975 -805.336)"
          fill="#231f20"
        />
        <circle
          data-name="Ellipse 129844"
          cx={1.778}
          cy={1.778}
          r={1.778}
          transform="rotate(-77.74 15.876 -2.123)"
          fill="#231f20"
        />
        <path
          data-name="Path 99494"
          d="M495.816 905.168a.728.728 0 11-.728-.728.728.728 0 01.728.728z"
          transform="translate(-478.83 -893.009)"
          fill="#fff"
        />
        <path
          data-name="Path 99495"
          d="M172.22 674.264c.773.548 1.009-.839 2.782-1.686 1.479-.707 2.643-.163 2.77-.819s-1.314-1.176-3.521-.256c-1.642.684-2.929 2.125-2.032 2.761z"
          transform="translate(-171.925 -670.819)"
          fill="#231f20"
        />
        <path
          data-name="Path 99496"
          d="M502.87 670.53c-.821.589-1.1-.88-3.014-1.763-1.6-.736-2.841-.147-2.987-.843s1.393-1.261 3.776-.306c1.773.711 3.177 2.228 2.225 2.912z"
          transform="translate(-481.209 -667.112)"
          fill="#231f20"
        />
        <path
          data-name="Path 99497"
          d="M280.482 1027.3a.239.239 0 01-.206-.115 5.153 5.153 0 00-1.2-1.23 7.128 7.128 0 00-3.935-1.389 7.245 7.245 0 00-5.815 2.638.241.241 0 11-.385-.289 7.577 7.577 0 011.643-1.506 7.289 7.289 0 014.586-1.323 7.6 7.6 0 014.218 1.5 5.513 5.513 0 011.3 1.348.241.241 0 01-.08.331.235.235 0 01-.126.035z"
          transform="translate(-264.224 -1006.883)"
          fill="#231f20"
        />
        <path
          data-name="Path 99498"
          d="M249.831 1064.869a2.305 2.305 0 00-.174 2.066.125.125 0 00.148.084.121.121 0 00.084-.149 2.079 2.079 0 01.15-1.88c.082-.132-.126-.253-.208-.121z"
          transform="translate(-245.754 -1045.658)"
          fill="#231f20"
        />
        <path
          data-name="Path 99499"
          d="M516.845 1065.859a1.747 1.747 0 01.443 1.749c-.046.148.186.212.232.064a1.989 1.989 0 00-.5-1.983c-.111-.108-.282.062-.17.17z"
          transform="translate(-500.198 -1046.459)"
          fill="#231f20"
        />
      </g>
    </SvgIcon>
  );
}

export function ALittle(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="A little"
      xmlns="http://www.w3.org/2000/svg"
      width={21.915}
      height={17}
      viewBox="0 0 21.915 17"
      {...rest}
      sx={rootSx}
    >
      <circle
        data-name="Ellipse 129825"
        cx={3.689}
        cy={3.689}
        r={3.689}
        transform="rotate(-72.08 12.927 -3.618)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 129826"
        cx={3.689}
        cy={3.689}
        r={3.689}
        transform="rotate(-76.63 6.655 4.789)"
        fill="#fff"
      />
      <path
        data-name="Path 99424"
        d="M214.938 215.62a3.689 3.689 0 01-5.268 4.991 3.689 3.689 0 105.268-4.991z"
        transform="translate(-207.868 -211.179)"
        fill="#efefef"
      />
      <path
        data-name="Path 99425"
        d="M465.618 215.62a3.689 3.689 0 01-5.268 4.991 3.689 3.689 0 105.268-4.991z"
        transform="translate(-446.411 -211.179)"
        fill="#efefef"
      />
      <path
        data-name="Path 99426"
        d="M197.809 205.168a3.859 3.859 0 113.859-3.859 3.863 3.863 0 01-3.859 3.859zm0-7.379a3.52 3.52 0 103.52 3.52 3.524 3.524 0 00-3.52-3.52z"
        transform="translate(-192.909 -193.889)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129827"
        cx={1.788}
        cy={1.788}
        r={1.788}
        transform="rotate(-52.27 9.62 1.696)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129828"
        cx={0.732}
        cy={0.732}
        r={0.732}
        transform="translate(3.73 6.892)"
        fill="#fff"
      />
      <path
        data-name="Path 99427"
        d="M448.5 205.168a3.859 3.859 0 113.859-3.859 3.863 3.863 0 01-3.859 3.859zm0-7.379a3.52 3.52 0 103.52 3.52 3.524 3.524 0 00-3.52-3.52z"
        transform="translate(-431.462 -193.889)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129829"
        cx={1.788}
        cy={1.788}
        r={1.788}
        transform="rotate(-45 16.847 -13.54)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129830"
        cx={0.732}
        cy={0.732}
        r={0.732}
        transform="translate(15.867 6.892)"
        fill="#fff"
      />
      <path
        data-name="Path 99428"
        d="M172.626 128.163c.625.718 1.184-.58 3.118-.987 1.613-.339 2.621.468 2.9-.142s-1.005-1.46-3.379-1.085c-1.766.279-3.365 1.381-2.64 2.215z"
        transform="translate(-172.447 -125.762)"
        fill="#231f20"
      />
      <path
        data-name="Path 99429"
        d="M503.407 126.416c-.663.683-1.151-.643-3.06-1.155-1.591-.427-2.643.324-2.888-.3s1.083-1.4 3.433-.9c1.748.375 3.284 1.562 2.515 2.355z"
        transform="translate(-481.695 -123.899)"
        fill="#231f20"
      />
      <path
        data-name="Path 99430"
        d="M275.008 403H275a7.8 7.8 0 01-4.727-1.589 7.258 7.258 0 01-1.582-1.6.242.242 0 11.4-.267 6.918 6.918 0 001.485 1.49 7.315 7.315 0 004.42 1.48h.007a7.506 7.506 0 004.649-1.525 6.309 6.309 0 001.459-1.538.242.242 0 11.415.248 6.648 6.648 0 01-1.561 1.659 7.986 7.986 0 01-4.957 1.642z"
        transform="translate(-263.994 -386)"
        fill="#231f20"
      />
      <path
        data-name="Path 99431"
        d="M250.339 388.3a1.4 1.4 0 01.9-1.088c.155-.047.179-.271-.064-.233a1.721 1.721 0 00-1.069 1.257.124.124 0 00.085.149.122.122 0 00.149-.085z"
        transform="translate(-246.343 -374.235)"
        fill="#231f20"
      />
      <path
        data-name="Path 99432"
        d="M529.788 385.8a1.164 1.164 0 01.719 1.246.125.125 0 00.085.149.122.122 0 00.149-.085 1.412 1.412 0 00-.889-1.544.122.122 0 00-.149.085.124.124 0 00.085.149z"
        transform="translate(-512.402 -372.892)"
        fill="#231f20"
      />
    </SvgIcon>
  );
}

export function Totally(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={20.751}
      height={22.414}
      viewBox="0 0 20.751 22.414"
      {...rest}
      sx={rootSx}
    >
      <circle
        data-name="Ellipse 129836"
        cx={3.434}
        cy={3.434}
        r={3.434}
        transform="rotate(-70.1 12.817 -3.508)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 129837"
        cx={3.434}
        cy={3.434}
        r={3.434}
        transform="rotate(-45 9.27 3.84)"
        fill="#fff"
      />
      <path
        data-name="Path 99443"
        d="M1436.713 192.42a3.434 3.434 0 01-4.9 4.646 3.434 3.434 0 104.9-4.646z"
        transform="translate(-1429.837 -187.513)"
        fill="#efefef"
      />
      <path
        data-name="Path 99444"
        d="M1687.394 192.42a3.434 3.434 0 01-4.9 4.646 3.434 3.434 0 104.9-4.646z"
        transform="translate(-1669.22 -187.513)"
        fill="#efefef"
      />
      <path
        data-name="Path 99445"
        d="M1419.692 181.434a3.592 3.592 0 113.592-3.592 3.6 3.6 0 01-3.592 3.592zm0-6.869a3.276 3.276 0 103.276 3.276 3.28 3.28 0 00-3.276-3.276z"
        transform="translate(-1414.836 -170.162)"
        fill="#231f20"
      />
      <path
        data-name="Path 99446"
        d="M1456.757 212.974a1.934 1.934 0 11-1.934-1.934 1.934 1.934 0 011.934 1.934z"
        transform="translate(-1449.967 -205.294)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129838"
        cx={0.791}
        cy={0.791}
        r={0.791}
        transform="rotate(-76.72 6.714 1.794)"
        fill="#fff"
      />
      <path
        data-name="Path 99447"
        d="M1707.438 212.974a1.934 1.934 0 11-1.934-1.934 1.934 1.934 0 011.934 1.934z"
        transform="translate(-1689.35 -205.294)"
        fill="#231f20"
      />
      <circle
        data-name="Ellipse 129839"
        cx={0.791}
        cy={0.791}
        r={0.791}
        transform="rotate(-80.78 12.08 -4.691)"
        fill="#fff"
      />
      <path
        data-name="Path 99448"
        d="M1670.382 181.434a3.592 3.592 0 113.592-3.592 3.6 3.6 0 01-3.592 3.592zm0-6.869a3.276 3.276 0 103.276 3.276 3.28 3.28 0 00-3.276-3.276z"
        transform="translate(-1654.228 -170.162)"
        fill="#231f20"
      />
      <path
        data-name="Path 99449"
        d="M1418.711 86.038c.425.711.973-.452 2.514-.689 1.285-.2 2 .624 2.271.078s-.657-1.426-2.538-1.251c-1.4.131-2.74 1.037-2.247 1.862z"
        transform="translate(-1417.23 -84.126)"
        fill="#231f20"
      />
      <path
        data-name="Path 99450"
        d="M1718.234 85.492c-.441.7-.962-.474-2.5-.746-1.28-.227-2.015.578-2.273.026s.69-1.411 2.566-1.192c1.399.162 2.718 1.098 2.207 1.912z"
        transform="translate(-1698.758 -83.544)"
        fill="#231f20"
      />
      <path
        data-name="Path 99451"
        d="M1429.95 383.859s.708 4.982 2.787 6.88c1.485 1.357 3.679 1.894 6.291 1.991 2.987-.109 4.338-.635 5.821-1.995 2.074-1.9 2.773-6.885 2.773-6.885s-3.089 2.655-8.835 2.655-8.837-2.646-8.837-2.646z"
        transform="translate(-1428.061 -370.316)"
        fill="#231f20"
      />
      <path
        data-name="Path 99452"
        d="M1475.151 411.777a12.911 12.911 0 006.884-1.94.851.851 0 00.379-.873l-.267-1.427a15.885 15.885 0 01-7 1.526 16.089 16.089 0 01-7.092-1.591c0 .474-.014 1.1-.026 1.521a.851.851 0 00.407.751 13.655 13.655 0 006.711 2.036z"
        transform="translate(-1464.427 -392.872)"
        fill="#fff"
      />
      <path
        data-name="Path 99453"
        d="M1475.529 409.051a16.022 16.022 0 01-7.092-1.591c0 .223 0 .478-.007.73a.852.852 0 00.388.547 13.656 13.656 0 006.711 2.036 12.91 12.91 0 006.884-1.94.85.85 0 00.3-.322l-.184-.984a15.884 15.884 0 01-7 1.526z"
        transform="translate(-1464.807 -392.862)"
        fill="#efefef"
      />
      <path
        data-name="Path 99454"
        d="M1471.793 501.318a12.278 12.278 0 01-7.354-2.388 6.023 6.023 0 001.225 1.7c1.485 1.357 3.679 1.894 6.291 1.991 2.987-.109 4.338-.635 5.821-1.995a5.766 5.766 0 001.121-1.514 11.86 11.86 0 01-7.104 2.206z"
        transform="translate(-1460.997 -480.21)"
        fill="#fff"
      />
      <path
        data-name="Path 99455"
        d="M1409.381 367.707a1.163 1.163 0 01.188-.833 1.143 1.143 0 011.226-.447.105.105 0 00.056-.2 1.378 1.378 0 00-1.029.136 1.34 1.34 0 00-.617.808 1.373 1.373 0 00-.027.595.106.106 0 00.129.073.108.108 0 00.073-.129z"
        transform="translate(-1408.206 -353.442)"
        fill="#231f20"
      />
      <path
        data-name="Path 99456"
        d="M1800.606 364.414a1.117 1.117 0 011.066-.115 1.192 1.192 0 01.686.815 1.351 1.351 0 01.018.626c-.028.132.175.189.2.056a1.565 1.565 0 00-.259-1.243 1.352 1.352 0 00-1.13-.547 1.241 1.241 0 00-.69.228.108.108 0 00-.038.144.106.106 0 00.144.038z"
        transform="translate(-1781.862 -351.364)"
        fill="#231f20"
      />
    </SvgIcon>
  );
}
export function Cancel(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 73,
    height: 73,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 73 73"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 5623">
        <path
          data-name="Path 943"
          d="M53.521 25a28.521 28.521 0 1028.521 28.521A28.521 28.521 0 0053.521 25z"
          transform="translate(-14.5 -14.5) translate(-1.947 -2.192)"
          fill="#fae6e7"
        />
        <path
          data-name="Path 944"
          d="M51 16a35 35 0 1035 35 35 35 0 00-35-35z"
          fill="none"
          stroke="#444b54"
          strokeWidth={3}
          transform="translate(-14.5 -14.5)"
        />
        <path
          data-name="Path 4342"
          d="M35.226 67.877l32.808-32.808m-32.808 0l32.808 32.808"
          fill="none"
          stroke="#3f464e"
          strokeLinecap="round"
          strokeWidth={3}
          transform="translate(-14.5 -14.5)"
        />
      </g>
    </SvgIcon>
  );
}
export function CheckMarkPwa(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 13,
    width: 23,
    color: '#fff',

    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      sx={rootSx}
      {...rest}
    >
      <g data-name="Group 106048" stroke="#f5faf9" strokeWidth={0.5}>
        <g
          data-name="Ellipse 39431"
          transform="translate(-67.5 -145.5) translate(68 146)"
          fill="#16d65e"
        >
          <circle cx={6} cy={6} r={6} stroke="none" />
          <circle cx={6} cy={6} r={6.25} fill="none" />
        </g>
        <path
          d="M76.367 150.125a.25.25 0 00-.172.076l-3.071 3.071-1.323-1.327a.25.25 0 10-.353.353l1.5 1.5a.25.25 0 00.353 0l3.247-3.244a.25.25 0 00-.182-.429z"
          fill="#fff"
          transform="translate(-67.5 -145.5)"
        />
      </g>
    </SvgIcon>
  );
}

export function Iphone(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 16,
    width: 16,
    color: '#fff',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.4 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M13.2 4A2.209 2.209 0 0011 6.2v11.6a2.209 2.209 0 002.2 2.2h6a2.209 2.209 0 002.2-2.2V6.2A2.209 2.209 0 0019.2 4h-6zm0 1.2h.63l.234 1.324a.6.6 0 00.537.332h3.2a.6.6 0 00.537-.332L18.57 5.2h.63a.991.991 0 011 1v11.6a.991.991 0 01-1 1h-6a.991.991 0 01-1-1V6.2a.991.991 0 011-1z"
        transform="translate(-11 -4)"
      />
    </SvgIcon>
  );
}

export function VideoCallStartIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 12,
    width: 16,
    color: '#fff',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-video-call"
        d="M15.2,18.4A2.6,2.6,0,0,1,12.6,21h-6A2.6,2.6,0,0,1,4,18.4V11.6A2.6,2.6,0,0,1,6.6,9h6a2.6,2.6,0,0,1,2.6,2.6Zm4.5-7.722a.6.6,0,0,0-.6.008L16,12.54V17.46l3.092,1.855A.6.6,0,0,0,20,18.8V11.2A.6.6,0,0,0,19.7,10.678Z"
        transform="translate(-4 -9)"
        fill="#fff"
      />
    </SvgIcon>
  );
}

export function MinimizeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 16,
    width: 16,
    color: '#fff',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_105642"
        data-name="Group 105642"
        transform="translate(-13017 -7400)"
      >
        <rect
          id="Rectangle_55848"
          data-name="Rectangle 55848"
          width="16"
          height="16"
          transform="translate(13017 7400)"
          fill="none"
        />
        <path
          id="icons8-pip"
          d="M5.925,8A1.933,1.933,0,0,0,4,9.925v7.35A1.933,1.933,0,0,0,5.925,19.2h10.15A1.933,1.933,0,0,0,18,17.275V9.925A1.933,1.933,0,0,0,16.075,8Zm0,1.05h10.15a.867.867,0,0,1,.875.875v7.35a.867.867,0,0,1-.875.875H5.925a.867.867,0,0,1-.875-.875V9.925a.909.909,0,0,1,.018-.178A.865.865,0,0,1,5.925,9.05Zm4.9,1.05a.525.525,0,0,0-.525.525v3.5a.525.525,0,0,0,.525.525h4.55a.525.525,0,0,0,.525-.525v-3.5a.525.525,0,0,0-.525-.525Zm.525,1.05h3.5V13.6h-3.5Z"
          transform="translate(13014 7394)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
}

export function MaximizeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 16,
    width: 16,
    color: '#fff',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_105640"
        data-name="Group 105640"
        transform="translate(-12481 -6465)"
      >
        <rect
          id="Rectangle_55847"
          data-name="Rectangle 55847"
          width="16"
          height="16"
          transform="translate(12481 6465)"
          fill="none"
        />
        <path
          id="icons8-full-screen"
          d="M7.528,6A1.534,1.534,0,0,0,6,7.528V9.194a.417.417,0,1,0,.833,0V7.528a.688.688,0,0,1,.694-.694H9.194a.417.417,0,1,0,0-.833Zm5.278,0a.417.417,0,1,0,0,.833h1.667a.688.688,0,0,1,.694.694V9.194a.417.417,0,1,0,.833,0V7.528A1.534,1.534,0,0,0,14.472,6Zm-6.4,6.383a.417.417,0,0,0-.41.423v1.667A1.534,1.534,0,0,0,7.528,16H9.194a.417.417,0,1,0,0-.833H7.528a.688.688,0,0,1-.694-.694V12.805a.417.417,0,0,0-.423-.423Zm9.167,0a.417.417,0,0,0-.41.423v1.667a.688.688,0,0,1-.694.694H12.805a.417.417,0,1,0,0,.833h1.667A1.534,1.534,0,0,0,16,14.472V12.805a.417.417,0,0,0-.423-.423Z"
          transform="translate(12478 6462)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
      </g>
    </SvgIcon>
  );
}
