import { Box, Drawer as MuiDrawer, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CloseIcon } from '../icons';
import { drawer_style } from './style';

function Drawer(props) {
  const {
    header,
    children,
    height = 'auto',
    hideBackdrop = false,
    isStyledDrawerRootSx = {},
    show,
    anchor = 'bottom',
    footer,
    isCloseIconRequired = false,
    drawerRightClose = false,
    isStyledDrawer,
    IsStyledDrawerContent,
    rootStyle = {},
    headerStyle = {},
    footerStyle = {},
    childrenStyle = {},
    onCloseDrawer = () => {},
    closeStyle = {},
    ...rest
  } = props;

  return (
    <MuiDrawer
      sx={{
        ...drawer_style.rootSx,
        ...rootStyle,
      }}
      anchor={anchor}
      open={show}
      onClose={() => {
        onCloseDrawer();
      }}
      hideBackdrop={hideBackdrop}
      {...rest}
    >
      <Box sx={drawer_style.totalHeaderSx}>
        {header && (
          <Typography
            sx={{
              ...drawer_style.headerSx,
              ...headerStyle,
            }}
          >
            {header}
          </Typography>
        )}
        {drawerRightClose && (
          <Box sx={{ padding: '0px 15px 0px 0px' }}>
            <CloseIcon
              rootStyle={{ ...drawer_style.closesSx, ...closeStyle }}
              open={show}
              onClick={() => {
                onCloseDrawer();
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          ...drawer_style.childrenSx,
          height,
          mb: 8,
          ...childrenStyle,
        }}
      >
        {isStyledDrawer ? (
          <Box>
            <Box
              sx={{ ...drawer_style.drawerBoxSx, ...isStyledDrawerRootSx }}
            />
            <Box display="flex" justifyContent="center">
              <Box sx={drawer_style.drawerCenterBoxSx}>
                <Box sx={drawer_style.drawerCenterIconBoxSx}>
                  <Typography sx={drawer_style.iconTextSx} color="#fff">
                    !
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box width={274} pt={5.5} pb={2}>
                <Typography fontSize={14} fontWeight={500} textAlign="center">
                  {IsStyledDrawerContent}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>{children}</Box>
        )}
      </Box>
      {footer && (
        <Box
          sx={{
            ...drawer_style.footerSx,
            ...footerStyle,
          }}
        >
          {footer}
        </Box>
      )}
      {isCloseIconRequired && (
        <Box>
          <CloseIcon
          id='close'
            rootStyle={{
              ...drawer_style.closeSx,
              ...closeStyle,
            }}
            open={show}
            onClick={() => {
              onCloseDrawer();
            }}
          />
        </Box>
      )}
    </MuiDrawer>
  );
}

export { Drawer };
Drawer.propTypes = {
  onCloseDrawer: PropTypes.func,
  height: PropTypes.string,
  anchor: PropTypes.string,
  header: PropTypes.string,
  footer: PropTypes.any,
  show: PropTypes.bool,
  isCloseIconRequired: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  classes: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.any,
  isStyledDrawerRootSx: PropTypes.object,
  drawerRightClose: PropTypes.bool,
  isStyledDrawer: PropTypes.bool,
  IsStyledDrawerContent: PropTypes.any,
  rootStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  footerStyle: PropTypes.object,
  childrenStyle: PropTypes.object,
  closeStyle: PropTypes.object,
};
