import { CalenderIcon } from '@atoms/icons/iconSet5';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateRangePicker_style } from './style';

const CustomInput = forwardRef(
  ({ value, onClick = () => {}, unStyled, customSx = {} }, ref) => (
    <Box
      sx={
        unStyled === false
          ? { ...dateRangePicker_style.customInputSx, ...customSx }
          : dateRangePicker_style.inputStyleSx
      }
      onClick={onClick}
      ref={ref}
    >
      <Typography sx={dateRangePicker_style.selectedDateSx}>{value}</Typography>
      {!unStyled && (
        <IconButton
          disableRipple
          sx={unStyled === true ? 'display:none' : 'justifyContent:center'}
        >
          {unStyled === false ? <CalenderIcon /> : ''}
        </IconButton>
      )}
    </Box>
  )
);

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  unStyled: PropTypes.bool,
  customSx: PropTypes.object,
};

CustomInput.displayName = 'CustomInput';

export function DateRangePicker(props) {
  const {
    selectsRange = false,
    onChange = () => false,
    startDate = null,
    endDate = null,
    dateFormat = 'MMM dd, yyyy',
    peekNextMonth,
    showYearDropdown,
    showMonthDropdown,
    popperPlacement = '',
    unStyled = false,
    readOnly = false,
    minDate = null,
    maxDate = null,
    customSx = {},
  } = props;

  const [startDateData, setStartDateData] = useState(startDate);
  const [endDateData, setEndDateData] = useState(endDate);

  function onChangeHandler(value) {
    if (selectsRange === true) {
      const result = {
        startDate: value[0],
        endDate: value[1],
      };
      setStartDateData(result?.startDate);
      setEndDateData(result?.endDate);
      if (value[0] && value[1]) {
        onChange(result);
      }
    } else {
      setStartDateData(value);
      onChange(value);
    }
  }

  useEffect(() => {
    setStartDateData(startDate);
    setEndDateData(endDate);
  }, [startDate, endDate]);
  return (
    <DatePicker
      id="dateStartEnd"
      selectsRange={selectsRange}
      startDate={startDateData}
      endDate={endDateData}
      onChange={onChangeHandler}
      dateFormat={dateFormat}
      readOnly={readOnly}
      peekNextMonth={peekNextMonth}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode="select"
      showDisabledMonthNavigation
      customInput={<CustomInput unStyled={unStyled} customSx={customSx} />}
      popperPlacement={popperPlacement}
      minDate={minDate}
      maxDate={maxDate}
      {...(!selectsRange && {
        selected: startDateData,
      })}
    />
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectsRange: PropTypes.bool,
  unStyled: PropTypes.bool,
  popperPlacement: PropTypes.oneOf(['bottom-end', 'bottom-start']),
  dateFormat: PropTypes.PropTypes.oneOf(['MMM dd, yyyy', 'dd/MM/yyyy']),
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  peekNextMonth: PropTypes.any,
  showYearDropdown: PropTypes.any,
  showMonthDropdown: PropTypes.any,
  readOnly: PropTypes.bool,
  customSx: PropTypes.object,
};
