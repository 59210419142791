import { countryCode } from '@hc/utils/constants';
import { getMobileLimitBasedOnCC } from '@hc/utils/validations';
import { Box, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SimpleSelect } from './select';
import { mobileInput_style } from './style';

export function MobileInput({
  onChange = () => {},
  fullWidth = true,
  autoFocus = false,
  isReadonly = false,
  disabled,
  disabledNumber,
  isReadonlyCC,
  enterKeyPress = () => {},
  value,
  onErrorOccured,
  helperText = '',
  id = 'mobileInput',
  isError = false,
  mobileInputStyle = {},
  rootWapperstyle = {},
  className = '',
  maxLength = 10,
  ...rest
}) {
  const [focus, setFoucs] = useState(false);
  const [selectValue, setSelectValue] = React.useState({
    mobile: value?.mobile ?? '',
    mobileCode: value?.mobileCode ?? '+91',
  });
  const [limits, setLimits] = React.useState(maxLength);
  const [isvalid, setIsvalid] = React.useState(true);

  const mobileNoValidation = (limitValue = limits) => {
    const inputLengthValue = selectValue?.mobile?.length;
    if (limitValue) {
      if (limitValue === inputLengthValue) {
        setIsvalid(true);
      } else if (limitValue > inputLengthValue) {
        setIsvalid(false);
      } else {
        setIsvalid(true);
      }
    }
  };

  const handleChangeSelect = (mobileCode = {}) => {
    const newState = {
      mobile: selectValue.mobile,
      mobileCode,
    };
    setSelectValue(newState);
    onChange(newState);

    if (Object.keys(mobileCode)?.length > 0) {
      const limit = getMobileLimitBasedOnCC(mobileCode);
      setLimits(limit);
    }
  };

  const handleChange = (mobile) => {
    if (mobile?.length <= limits) {
      const newState = {
        mobile: mobile.replace('.', ''),
        mobileCode: selectValue.mobileCode,
      };
      setSelectValue(newState);
      onChange(newState);
    }
  };

  useEffect(() => {
    const limit = getMobileLimitBasedOnCC(value?.mobileCode);
    mobileNoValidation(limit);
  }, [selectValue]);

  useEffect(() => {
    if (value) {
      setSelectValue({
        ...selectValue,
        mobile: value?.mobile ?? '',
        mobileCode: value?.mobileCode ?? '+91',
      });
    }
  }, [value]);

  React.useEffect(() => {
    if (onErrorOccured) {
      onErrorOccured(!isvalid);
    }
  }, [isvalid]);

  return (
    <Box>
      {/* MobileInput with props */}
      <Box
        className={`${className}`}
        {...rest}
        sx={{
          ...mobileInput_style.rootWapperSx,
          border: `1.5px solid`,
          borderColor: isError ? 'red' : '#DBEAE8',
          ...(focus && {
            border: '2px solid',
            borderColor: 'primary.main',
          }),
          ...rootWapperstyle,
        }}
      >
        <Box sx={mobileInput_style.NumberSx}>
          <SimpleSelect
            options={countryCode.filter(
              (country) => country.countryName === 'India'
            )}
            handleChangeSelect={handleChangeSelect}
            value={selectValue?.mobileCode ?? ''}
            disabled={isReadonlyCC || disabledNumber}
          />
        </Box>
        {/* MobileInput with props */}
        <Box flexGrow={1}>
          <TextField
            type="number"
            inputProps={{ maxLength: limits, min: 0, readOnly: isReadonly }}
            id={id}
            sx={{
              ...mobileInput_style.mobileInputStyledSx,
              ...mobileInputStyle,
            }}
            value={selectValue?.mobile}
            variant="outlined"
            size="small"
            onChange={(e) => handleChange(e.target.value)}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            disabled={isReadonly || disabled}
            onInvalid={(e) => {
              e.preventDefault();
            }}
            onFocus={() => {
              setFoucs(true);
            }}
            onBlur={() => {
              setFoucs(false);
            }}
            error={isError}
            onKeyPress={(e) => (enterKeyPress ? enterKeyPress(e) : '')}
          />
        </Box>
      </Box>
      {/* Warning Message */}
      <Grid container direction="row">
        {/* {isvalid === false && limits !== null && selectValue?.mobile?.length > 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="caption" color="error">
              {`Please enter ${limits} digits`}
            </Typography>
          </Grid>
        )} */}

        {/* Field required Message */}
        {helperText?.length > 0 && (
          <Typography
            sx={{ mt: 0.5 }}
            variant="caption"
            color={`${isError ? '#F44F5A' : '#DBEAE8'}`}
          >
            {helperText}
          </Typography>
        )}
      </Grid>
    </Box>
  );
}
MobileInput.propTypes = {
  value: PropTypes.any,
  helperText: PropTypes.string,
  isError: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isReadonly: PropTypes.bool,
  disabledNumber: PropTypes.bool,
  mobileInputStyle: PropTypes.object,
  rootWapperstyle: PropTypes.object,
  enterKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isReadonlyCC: PropTypes.bool,
  onErrorOccured: PropTypes.any,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};
