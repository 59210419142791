import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// import { useLinkPreview } from 'get-link-preview';
import { useActivityTemplate } from '@hc/store/backoffice/activityTemplate';
import { addReferences_style } from './style';
import {
  BackOfficeAddReferenceIcon,
  Button,
  CloseIcon,
  Input,
  Label,
  Loader,
  UploadIcon,
} from '../../../atoms';

function AddReferences(props) {
  const {
    className = '',
    rootStyle = {},
    HandleSubmit = () => {},
    closeReferenceDrawer = () => {},
    isExpert,
    boxRootStyle = {},
    ...rest
  } = props;

  const { Link, addLink, handelLinkChange, handleLinkDelete } =
    useActivityTemplate((state) => ({
      Link: state.Link,
      addLink: state.addLink,
      handelLinkChange: state.handelLinkChange,
      handleLinkDelete: state.handleLinkDelete,
      linkloading: state.linkloading,
    }));
  const handleDelete = (i) => {
    handleLinkDelete(i);
  };

  return (
    <Box
      sx={{
        ...addReferences_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{ ...addReferences_style.boxRootSx, ...boxRootStyle }}
        height="68vh"
        pb={4}
      >
        {Link.map((link, i) => (
          <Stack
            key={i}
            mt={1}
            sx={{
              ...(isExpert && {
                backgroundColor: '#fff',
                p: 2,
                borderRadius: '8px',
              }),
            }}
          >
            <Box sx={addReferences_style.LablewithBtnSx}>
              <Typography sx={{ color: '#262C33', fontSize: '14px', mb: 0.5 }}>
                {` Link ${i + 1} `}
              </Typography>

              <Box
                sx={addReferences_style.FlexSx}
                onClick={() => handleDelete(i)}
              >
                <CloseIcon rootStyle={{ color: '#f00', width: '12px' }} />
              </Box>
            </Box>

            <Box sx={addReferences_style.inputGroupSx}>
              <Label
                rootStyle={addReferences_style.labelSx}
                htmlFor="ActivityName"
                isRequired
              >
                Link name
              </Label>

              <Input
                id="ActivityName"
                placeholder="What is the link about?"
                textFieldStyle={
                  isExpert
                    ? addReferences_style.expertField
                    : addReferences_style.inputFieldSx
                }
                onChange={(e) => {
                  handelLinkChange(link.id, 'linkName', e.target.value);
                }}
                value={link.linkName}
                fullWidth
              />
            </Box>

            <Box sx={addReferences_style.inputGroupSx}>
              <Label
                rootStyle={addReferences_style.labelSx}
                htmlFor="ActivityName"
                isRequired
              >
                Paste URL
              </Label>
              <Input
                id="ActivityName"
                placeholder="Paste link URL"
                textFieldStyle={
                  isExpert
                    ? addReferences_style.expertField
                    : addReferences_style.inputFieldSx
                }
                onChange={(e) => {
                  handelLinkChange(link.id, 'url', e.target.value);
                }}
                value={link.url}
                fullWidth
              />
            </Box>

            <Box sx={addReferences_style.imginputGroupSx}>
              <Label
                rootStyle={addReferences_style.labelSx}
                htmlFor="ActivityName"
              >
                Link Preview
              </Label>
              <Box
                sx={{
                  width: '100%',
                  height: '180px',
                  border: '1px solid #DBEAE8',
                  borderRadius: '8px',
                }}
              >
                {!Link[i].loading && Link[i].imageUrl && (
                  <img
                    src={Link[i].imageUrl}
                    alt="pop"
                    width="100%"
                    height="180px"
                    style={{ objectFit: 'cover', borderRadius: '8px' }}
                  />
                )}

                {!Link[i].url && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '180px',
                    }}
                  >
                    {' '}
                    <UploadIcon />{' '}
                  </Box>
                )}

                {Link[i].loading && Link[i].url && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <Loader />
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
        ))}

        <Stack alignItems="center">
          <Box
            sx={{
              border: isExpert ? '1px solid #6552CC' : '1px solid #007965',
              backgroundColor: isExpert ? '#F4F2FF' : '',
              px: '6px',
              py: '3px',
              mt: isExpert ? 2 : 0,
              borderRadius: '8px',
              display: 'flex',
              gap: 0.4,
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => addLink()}
          >
            <BackOfficeAddReferenceIcon
              rootStyle={{ color: isExpert ? '#6552CC' : '#007965' }}
            />
            <Typography sx={addReferences_style.getlinkSx}>add Link</Typography>
          </Box>
        </Stack>
      </Box>
      {!isExpert && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 6,
            position: 'fixed',
            inset: 'auto',
            zIndex: '100',
          }}
        >
          <Button
            buttonStyle={addReferences_style.SaveButtonSx}
            onClick={HandleSubmit}
          >
            Save
          </Button>

          <Button
            buttonStyle={addReferences_style.cancleButtonSx}
            onClick={closeReferenceDrawer}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}

AddReferences.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  HandleSubmit: PropTypes.func,
  closeReferenceDrawer: PropTypes.func,
  isExpert: PropTypes.bool,
  boxRootStyle: PropTypes.object,
};

export { AddReferences };
