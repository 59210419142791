/* eslint-disable react/jsx-key */
import { SearchIcon } from '@atoms/icons';
import { Input } from '@atoms/input';
// import { useDoctorAppointment, usePatientDetails } from '@hc/store';
import {
  useDoctorAppointment,
  usePatientDetails,
  useReferrals,
} from '@hc/store';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { dataTabs_style } from './style';

export function DataTabs(props) {
  const {
    tabs = [],
    setTabIndex = () => {},
    totalStyle = {},
    tabIndex = '',
    containerStyle = {},
    underTabSx,
    tabStyle = {},
    searchbar,
  } = props;

  const { setSeachFilter } = useDoctorAppointment(
    (state) => ({
      setSeachFilter: state.setSeachFilter,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { setPatientSeachFilter } = usePatientDetails(
    (state) => ({
      setPatientSeachFilter: state.setPatientSeachFilter,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { setSeachReferralsFilter } = useReferrals(
    (state) => ({
      setSeachReferralsFilter: state.setSeachReferralsFilter,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const [onsearchValue, setOnsearchValue] = useState('');

  const onSearch = (val) => {
    setOnsearchValue(val?.target?.value);
    if (tabIndex === '1') {
      setSeachFilter(val?.target?.value);
    } else if (tabIndex === '2') {
      setSeachReferralsFilter(val?.target?.value);
    } else if (tabIndex === '3') {
      setPatientSeachFilter(val?.target?.value);
    }
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    setOnsearchValue('');
    if (newValue === '1') {
      setSeachFilter();
    } else if (newValue === '2') {
      setSeachReferralsFilter();
    } else if (newValue === '3') {
      setPatientSeachFilter();
    }
  };

  return (
    <Box sx={{ ...dataTabs_style.tabContainerSx, ...containerStyle }}>
      <TabContext value={tabIndex}>
        <Box sx={{ ...dataTabs_style.totalSx, ...totalStyle }}>
          <TabList
            sx={{ ...dataTabs_style.underTab, ...underTabSx }}
            onChange={handleChange}
            aria-label="lab API tabs"
          >
            {tabs.length > 0 &&
              tabs?.map((tab, i) => {
                return (
                  <Tab
                    key={i}
                    data-testid={`tab${i}`}
                    id={tab.label ? tab.label : ''}
                    sx={{ ...dataTabs_style.tabSx, ...tabStyle }}
                    label={tab.label}
                    value={tab.value}
                    icon={tab.count}
                    iconPosition="end"
                    data-active={tabIndex === tab.value ? true : false}
                  />
                );
              })}
          </TabList>

          {searchbar ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Input
                id="search"
                placeholder="Search"
                textFieldStyle={dataTabs_style.searchFiledSx}
                onChange={onSearch}
                value={onsearchValue}
                startAdornment={!onsearchValue && <SearchIcon />}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </TabContext>
    </Box>
  );
}

DataTabs.propTypes = {
  tabs: PropTypes.array,
  setTabIndex: PropTypes.func,
  totalStyle: PropTypes.object,
  tabIndex: PropTypes.string,
  containerStyle: PropTypes.object,
  underTabSx: PropTypes.any,
  tabStyle: PropTypes.object,
  searchbar: PropTypes.any,
};
