import { SvgIcon } from '@mui/material';

export function AngleRight(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 12,
    width: 12,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="8.846"
      viewBox="0 0 5 8.846"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-expand-arrow"
        d="M3.075,4.423l-2.844-3a.867.867,0,0,1,0-1.178.761.761,0,0,1,1.116,0L4.782,3.867a.788.788,0,0,1,.162.255.824.824,0,0,1,0,.6.788.788,0,0,1-.162.255L1.348,8.6a.761.761,0,0,1-1.116,0,.867.867,0,0,1,0-1.178Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function AddSolidIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 22,
    width: 22,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-add"
        d="M15,4A11,11,0,1,0,26,15,11.013,11.013,0,0,0,15,4Zm0,1.65A9.35,9.35,0,1,1,5.65,15,9.338,9.338,0,0,1,15,5.65Zm-.013,3.838a.825.825,0,0,0-.812.837v3.85h-3.85a.825.825,0,1,0,0,1.65h3.85v3.85a.825.825,0,1,0,1.65,0v-3.85h3.85a.825.825,0,1,0,0-1.65h-3.85v-3.85a.825.825,0,0,0-.838-.837Z"
        transform="translate(-4 -4)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function VitalUpdateIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 10.606,
    width: 12.97,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="12.97"
      height="10.606"
      viewBox="0 0 12.97 10.606"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-available-updates"
        d="M8.481,6A5.3,5.3,0,0,0,4.15,8.249a.442.442,0,1,0,.722.51,4.413,4.413,0,0,1,8.019,2.351l-.709-.709a.442.442,0,1,0-.625.625l1.326,1.326a.442.442,0,0,0,.625,0l.1-.1a.442.442,0,0,0,.085-.085l1.138-1.138a.442.442,0,1,0-.625-.625l-.447.447A5.308,5.308,0,0,0,8.481,6Zm-4.72,4.125a.442.442,0,0,0-.306.129l-.1.1a.442.442,0,0,0-.087.087L2.129,11.58a.442.442,0,1,0,.625.625l.447-.447a5.3,5.3,0,0,0,9.612,2.6.442.442,0,1,0-.722-.51A4.413,4.413,0,0,1,4.072,11.5l.709.709a.442.442,0,1,0,.625-.625L4.08,10.254a.442.442,0,0,0-.319-.129Z"
        transform="translate(-2 -6)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function ClinicalHomeArrowIcon(props) {
  const { rootStyle, bgColor, fill, ...rest } = props;

  const rootSx = {
    height: 20,
    width: 20,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16.512"
      height="16.512"
      viewBox="0 0 20 20"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_121647"
        data-name="Group 121647"
        transform="translate(-150 -38)"
      >
        <g
          id="Ellipse_129965"
          data-name="Ellipse 129965"
          transform="translate(150 38)"
          fill={bgColor}
          stroke={fill}
          stroke-width="1"
        >
          <circle cx="10" cy="10" r="10" stroke="none" />
          <circle cx="10" cy="10" r="9.5" fill="none" />
        </g>
        <path
          id="icons8-expand-arrow"
          d="M7.85.119,4.272,3.7.695.119A.407.407,0,0,0,.119.695L3.985,4.56a.407.407,0,0,0,.575,0L8.426.695A.407.407,0,1,0,7.85.119Z"
          transform="translate(158.095 52.272) rotate(-90)"
          fill={fill}
          stroke={fill}
          stroke-width="0.3"
        />
      </g>
    </SvgIcon>
  );
}

export function ClinicalHomePageChatIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 16.512,
    width: 16.512,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16.512"
      height="16.512"
      viewBox="0 0 16.512 16.512"
      {...rest}
      sx={rootSx}
    >
      <g
        id="Group_121373"
        data-name="Group 121373"
        transform="translate(-46 -0.044)"
      >
        <path
          id="icons8-chat-message"
          d="M12.256,4A8.265,8.265,0,0,0,4,12.254,8.179,8.179,0,0,0,4.934,16l-.895,3.2a1.053,1.053,0,0,0,1.272,1.272l3.206-.895a8.178,8.178,0,0,0,3.738.932A8.256,8.256,0,0,0,12.256,4Zm0,1.238a7.017,7.017,0,1,1-3.409,13.15.619.619,0,0,0-.468-.056l-3.049.851.851-3.048a.619.619,0,0,0-.056-.468,7.013,7.013,0,0,1,6.13-10.429ZM8.747,10.19a.619.619,0,1,0,0,1.238h7.017a.619.619,0,1,0,0-1.238Zm0,2.89a.619.619,0,1,0,0,1.238h5.366a.619.619,0,1,0,0-1.238Z"
          transform="translate(42 -3.954)"
          fill="#c2ad28"
        />
      </g>
    </SvgIcon>
  );
}

export function ClinicalHomePageVitalIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 14.176,
    width: 14.184,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="15.601"
      height="16.31"
      viewBox="0 0 14.184 14.176"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-ecg"
        d="M8.166,6A2.175,2.175,0,0,0,6,8.166V18.01a2.175,2.175,0,0,0,2.166,2.166H18.01a2.175,2.175,0,0,0,2.166-2.166V16.138a.591.591,0,0,0,0-.191V8.166A2.175,2.175,0,0,0,18.01,6Zm0,1.181H18.01a.976.976,0,0,1,.984.984v7.285H17.313l-.693-3.463a.591.591,0,0,0-1.158,0l-.693,3.463h-.187l-.91-6.19a.591.591,0,0,0-1.169,0l-.91,6.19h-.226L10.7,13.123a.591.591,0,0,0-1.135,0L8.9,15.451H7.181V8.166A.976.976,0,0,1,8.166,7.181Zm4.922,6.225.351,2.384a.991.991,0,0,0,.974.841h.518a.991.991,0,0,0,.965-.791s0,0,0,0l.145-.725.145.725a.991.991,0,0,0,.966.791h1.843V18.01a.976.976,0,0,1-.984.984H8.166a.976.976,0,0,1-.984-.984V16.632H9.05A.989.989,0,0,0,10,15.918l.138-.484.138.484a.99.99,0,0,0,.947.714h.544a.991.991,0,0,0,.974-.841Z"
        transform="translate(-6 -6)"
        fill="#35c03a"
      />
    </SvgIcon>
  );
}

export function ClinicalHomePageHeathCheckupIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 16.31,
    height: 15.601,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      height="15.601"
      width="16.31"
      viewBox="0 0 16.31 15.601"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-health-checkup"
        d="M14.765,3a1.773,1.773,0,1,0,1.773,1.773A1.778,1.778,0,0,0,14.765,3ZM4.127,3.709a.709.709,0,0,0-.614.355H2.355A.355.355,0,0,0,2,4.418V7.255a4.259,4.259,0,0,0,3.9,4.237q0,.009,0,.018a5.894,5.894,0,0,0,.684,3.143c.641,1.038,1.9,1.821,4.083,1.821a4.947,4.947,0,0,0,2.366-.537.355.355,0,0,0,.138-.083,3.676,3.676,0,0,0,1.265-1.222,4.818,4.818,0,0,0,.684-2.463v-.71a1.418,1.418,0,1,0-.709,0v.71a4.248,4.248,0,0,1-.577,2.089,3.079,3.079,0,0,1-.589.691l-.093-1.771-1.223-.49V10.446a2.837,2.837,0,0,1,5.673,0v2.242l-1.223.49-.248,4.715H13.4l-.072-1.3a4.37,4.37,0,0,1-.691.3l.036.686q0,.013,0,.026l.053,1H16.8l.26-4.934,1.248-.5V10.446a3.546,3.546,0,0,0-7.091,0v2.722l1.248.5.091,1.726a4.375,4.375,0,0,1-1.891.372c-2.02,0-2.966-.652-3.48-1.484a5.347,5.347,0,0,1-.578-2.771q0-.009,0-.018a4.259,4.259,0,0,0,3.9-4.237V4.418a.355.355,0,0,0-.355-.355H9a.709.709,0,1,0,0,.709h.8V7.255a3.546,3.546,0,1,1-7.091,0V4.773h.805a.709.709,0,1,0,.613-1.064Zm10.637,0A1.064,1.064,0,1,1,13.7,4.773,1.058,1.058,0,0,1,14.765,3.709Zm0,5.673A.708.708,0,0,1,14.8,10.8h-.064a.708.708,0,0,1,.033-1.415Z"
        transform="translate(-2 -3)"
        fill="#2279bc"
      />
    </SvgIcon>
  );
}

export function ClinicalHomePageStethoscopeIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 18.682,
    width: 16.511,
    color: '#0E1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18.682"
      height="16.511"
      viewBox="0 0 16.511 18.682"
      {...rest}
      sx={rootSx}
    >
      <g
        id="icons8-stethoscope_2_"
        data-name="icons8-stethoscope (2)"
        transform="translate(0 0)"
      >
        <path
          id="Path_4313"
          data-name="Path 4313"
          d="M35.255,29.475h.034a5.168,5.168,0,0,0,5.1-5.153V23.185a.517.517,0,1,0-1.034,0v1.137a4.119,4.119,0,0,1-4.067,4.119,4.007,4.007,0,0,1-2.913-1.189,4.079,4.079,0,0,1-1.224-2.93v-.138a.517.517,0,0,0-1.034,0v.138a5.148,5.148,0,0,0,5.136,5.153Zm-4.6-6.618a5.138,5.138,0,0,0,5.153-5.119V12.206A1.2,1.2,0,0,0,34.6,11a.517.517,0,0,0,0,1.034.163.163,0,0,1,.172.172v5.532a4.119,4.119,0,0,1-8.238,0V12.206a.163.163,0,0,1,.172-.172.517.517,0,1,0,0-1.034A1.2,1.2,0,0,0,25.5,12.206v5.532A5.138,5.138,0,0,0,30.653,22.857Z"
          transform="translate(-25.5 -10.793)"
          fill="#5e47d5"
        />
        <path
          id="Path_4314"
          data-name="Path 4314"
          d="M66.917,11.23h0a.7.7,0,0,0,.689-.724.675.675,0,0,0-.155-.448.646.646,0,0,0-.534-.259.508.508,0,0,0-.517.517.62.62,0,0,0,.034.19.437.437,0,0,0-.034.207A.519.519,0,0,0,66.917,11.23Zm4.343,0a.508.508,0,0,0,.517-.517.865.865,0,0,0-.034-.207.733.733,0,0,0,.034-.19A.508.508,0,0,0,71.26,9.8a.7.7,0,0,0-.689.707.675.675,0,0,0,.155.448A.618.618,0,0,0,71.26,11.23Z"
          transform="translate(-63.935 -9.8)"
          fill="#5e47d5"
        />
        <path
          id="Path_4315"
          data-name="Path 4315"
          d="M20.7,53a1.62,1.62,0,1,1-1.6,1.62A1.611,1.611,0,0,1,20.7,53Z"
          transform="translate(-6.312 -45.555)"
          fill="none"
        />
        <path
          id="Path_4316"
          data-name="Path 4316"
          d="M18.22,54.274a2.137,2.137,0,1,0-2.12-2.137A2.133,2.133,0,0,0,18.22,54.274Zm0-3.24a1.1,1.1,0,1,1-1.086,1.1A1.1,1.1,0,0,1,18.22,51.034Z"
          transform="translate(-3.829 -43.072)"
          fill="#5e47d5"
        />
      </g>
    </SvgIcon>
  );
}

export function BackIcon(props) {
  const { fill = '#fff', rootStyle, ...rest } = props;

  const rootSx = {
    height: 32,
    width: 32,
    color: 'primary.main',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      sx={rootSx}
      {...rest}
    >
      <g id="Group_9" data-name="Group 9" transform="translate(-4 -4)">
        <circle
          id="Ellipse_39211"
          data-name="Ellipse 39211"
          cx="16"
          cy="16"
          r="16"
          fill={fill}
          transform="translate(4 4)"
        />
        <path
          id="icons8-expand-arrow"
          d="M9.188,5.337,5,1.15.813,5.337a.476.476,0,1,1-.673-.673L4.664.14a.476.476,0,0,1,.673,0L9.861,4.664a.476.476,0,1,1-.673.673Z"
          transform="translate(16.168 25.028) rotate(-90)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </SvgIcon>
  );
}

export function ExpertCheckIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        d="M16 4a12 12 0 1012 12A12.014 12.014 0 0016 4zm5.137 9.937l-6 6a.9.9 0 01-1.273 0l-3-3a.9.9 0 111.273-1.273l2.363 2.363 5.363-5.363a.9.9 0 011.274 1.273z"
        transform="translate(-4 -4)"
        fill="#ee8438"
      />
    </SvgIcon>
  );
}

// export function ExpertunCheckIcon(props) {
//   const { rootStyle, ...rest } = props;
//   const rootSx = {
//     width: 24,
//     height: 24,
//     ...rootStyle,
//   };
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       sx={rootSx}
//       viewBox="0 0 24 24"
//       {...rest}
//     >
//       <g data-name="Ellipse 129719" fill="#f00" stroke="#f00" strokeWidth={1}>
//         <circle cx={12} cy={12} r={12} stroke="none" />
//         <circle cx={12} cy={12} r={11.5} fill="none" />
//       </g>
//     </svg>
//   );
// }

export function ExpertunCheckIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        data-name="Ellipse 129854"
        fill="#fff"
        stroke="#b3b3b3"
        strokeWidth={1.5}
      >
        <circle cx={12} cy={12} r={12} stroke="none" />
        <circle cx={12} cy={12} r={11.25} fill="none" />
      </g>
    </SvgIcon>
  );
}

export function DropDown(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 10.027,
    height: 5.668,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.027 5.668"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M3.486 5.014L.262 8.416a.983.983 0 000 1.335.863.863 0 001.265 0l3.894-4.107a.893.893 0 00.179-.288.934.934 0 000-.681.893.893 0 00-.183-.289L1.528.276a.863.863 0 00-1.265 0 .983.983 0 000 1.335z"
        transform="rotate(90 5.013 5.014)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function DropdownSmall(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 6,
    height: 3.391,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 3.391"
      sx={rootSx}
      {...rest}
    >
      <path
        d="M2.086 3L.157 5.036a.588.588 0 000 .8.516.516 0 00.757 0l2.33-2.458a.535.535 0 00.11-.173.559.559 0 000-.408.535.535 0 00-.11-.173L.914.165a.516.516 0 00-.757 0 .588.588 0 000 .8z"
        transform="rotate(90 3 3)"
        fill="#5c6266"
      />
    </SvgIcon>
  );
}
export function DropIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 24,
    width: 24,
    color: '#fff',
    stroke: '#e2e2e2',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 5083" strokeWidth={1}>
        <g
          data-name="Ellipse 39211"
          transform="rotate(-90 11.5 19.5) translate(7 8)"
          fill="currentColor"
          stroke="currenctStroke"
        >
          <circle cx={12} cy={12} r={12} stroke="none" />
          <circle cx={12} cy={12} r={11.5} fill="none" />
        </g>
        <path
          d="M21.505 15.84l-4.187 4.188 4.187 4.187a.476.476 0 11-.673.673l-4.524-4.524a.476.476 0 010-.673l4.524-4.524a.476.476 0 11.673.673z"
          fill="rgba(24,68,87,0.48)"
          stroke="rgba(24,68,87,0.48)"
          transform="rotate(-90 11.5 19.5)"
        />
      </g>
    </SvgIcon>
  );
}
export function DropUpIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 24,
    width: 24,
    color: '#fff',
    stroke: '#e2e2e2',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 90729" strokeWidth={1}>
        <g
          data-name="Ellipse 129089"
          fill="currentColor"
          stroke="currenctStroke"
          transform="rotate(90 12 12)"
        >
          <circle cx={12} cy={12} r={12} stroke="none" />
          <circle cx={12} cy={12} r={11.5} fill="none" />
        </g>
        <path
          d="M14.505 16.16l-4.188-4.188 4.188-4.187a.477.477 0 00-.674-.674l-4.524 4.525a.476.476 0 000 .673l4.524 4.524a.476.476 0 00.674-.673z"
          fill="rgba(24,68,87,0.48)"
          stroke="rgba(24,68,87,0.48)"
          transform="rotate(90 12 12)"
        />
      </g>
    </SvgIcon>
  );
}
export function IllustrationRightIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 32,
    width: 32,
    color: '#000',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      viewBox="0 0 34 34"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 4585" transform="translate(1 1)">
        <g
          data-name="Ellipse 39214"
          fill="none"
          stroke="#0f0b11"
          strokeLinecap="round"
          strokeWidth={1}
          strokeDasharray={2}
        >
          <circle cx={16} cy={16} r={16} stroke="none" />
          <circle cx={16} cy={16} r={16.5} />
        </g>
        <circle
          data-name="Ellipse 39211"
          cx={12}
          cy={12}
          r={12}
          transform="translate(4 4)"
          fill="currentColor"
        />
        <path
          d="M13.466 11.83l4.188 4.188-4.188 4.187a.477.477 0 10.674.674l4.524-4.525a.476.476 0 000-.673l-4.524-4.524a.476.476 0 00-.674.673z"
          fill="currentColor"
          stroke="#fff"
          strokeWidth={1}
        />
      </g>
    </SvgIcon>
  );
}
export function LeftArrowIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 8,
    width: 9.304,
    color: 'rgba(24,68,87,0.48)',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5 8.304"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M4.4 1.093L1.34 4.152 4.4 7.21a.35.35 0 01-.494.494L.6 4.398a.348.348 0 010-.494l3.3-3.3a.348.348 0 11.492.492z"
        fill="currentColor"
        stroke="rgba(24,68,87,0.48)"
        strokeWidth={1}
      />
    </SvgIcon>
  );
}

export function EyeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16.225,
    height: 9.271,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.225 9.271"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-eye"
        d="M8.107,9A8.417,8.417,0,0,0,.015,15.149.507.507,0,1,0,1,15.4a7.443,7.443,0,0,1,7.109-5.385A7.455,7.455,0,0,1,15.226,15.4a.507.507,0,1,0,.982-.253A8.428,8.428,0,0,0,8.107,9Zm.005,2.714a3.279,3.279,0,1,0,3.267,3.278A3.28,3.28,0,0,0,8.112,11.714Zm0,1.018A2.261,2.261,0,1,1,5.86,14.992,2.249,2.249,0,0,1,8.112,12.732Z"
        transform="translate(0.001 -9)"
        fill="#facc15"
      />
    </SvgIcon>
  );
}

export function MembersRightIcon(props) {
  const {
    rootStyle,
    circleFill = '#007965',
    arrowColor = '#fff',
    ...rest
  } = props;
  const rootSx = {
    width: 20,
    height: 20,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 4585" transform="translate(-4 -4)">
        <circle
          data-name="Ellipse 39211"
          cx={10}
          cy={10}
          r={10}
          transform="translate(4 4)"
          fill={circleFill}
        />
        <path
          d="M11.452 9.825l4.188 4.188-4.188 4.187a.477.477 0 10.674.674l4.524-4.525a.476.476 0 000-.673l-4.524-4.524a.476.476 0 00-.674.673z"
          fill={arrowColor}
          stroke={arrowColor}
          strokeWidth={1}
        />
      </g>
    </SvgIcon>
  );
}
export function PreviousIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 20,
    height: 20,
    color: 'rgba(24,68,87,0.48)',
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 5411"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Ellipse 39211" fill="#fff" stroke="#e2e2e2" strokeWidth={1}>
        <circle cx={10} cy={10} r={10} stroke="none" />
        <circle cx={10} cy={10} r={9.5} fill="none" />
      </g>
      <path
        d="M11.766 6.471l-3.44 3.44 3.44 3.441a.391.391 0 01-.553.553l-3.717-3.717a.391.391 0 010-.553l3.717-3.715a.391.391 0 11.553.553z"
        fill="currentColor"
        stroke="rgba(24,68,87,0.48)"
        strokeWidth={1}
      />
    </SvgIcon>
  );
}
export function RightArrowIcon(props) {
  const { rootStyle, opacity, ...rest } = props;

  const rootSx = {
    width: 5,
    height: 8.304,
    color: 'rgba(24,68,87,0.48)',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 5 8.304"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M.6 1.093l3.06 3.059L.6 7.21a.35.35 0 00.494.494l3.3-3.3a.348.348 0 000-.492L1.094.604a.348.348 0 00-.494.489z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1}
        opacity={opacity ?? 1}
      />
    </SvgIcon>
  );
}
export function TableArrowIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 18.061,
    height: 8.102,
    color: '#000',
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.061 8.102"
      sx={rootSx}
      {...rest}
    >
      <path
        id="icons8-left-arrow"
        d="M11.944,23.439a1.289,1.289,0,0,0-.907.392L8.364,26.593a1.29,1.29,0,0,0,0,1.795l2.673,2.761a1.29,1.29,0,0,0,1.854-1.8l-.555-.573H24.772a1.29,1.29,0,0,0,0-2.58H12.336l.555-.573a1.291,1.291,0,0,0-.947-2.188Z"
        transform="translate(-8.001 -23.439)"
      />
    </SvgIcon>
  );
}
export function HealthCircleIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    color: '#007965',
    strokeWidth: 0.2,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      sx={rootSx}
      {...rest}
    >
      <g id="hospital-cross-in-3d-circle" transform="translate(-0.452)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M23.332,16.157a9.058,9.058,0,0,1-5.461,4.28,9.447,9.447,0,0,1-2.6.367,9.29,9.29,0,0,1-3.69-.768,8.486,8.486,0,0,0,1.227.094,8.616,8.616,0,0,0,2.372-.335,8.274,8.274,0,0,0,4.986-3.91A7.866,7.866,0,0,0,20.846,9.7a8.39,8.39,0,0,0-8.025-5.832,8.551,8.551,0,0,0-1.992.254,9.285,9.285,0,0,1,1.857-.762,9.431,9.431,0,0,1,2.6-.366,9.184,9.184,0,0,1,8.79,6.387A8.631,8.631,0,0,1,23.332,16.157ZM4.191,15.076A11.19,11.19,0,0,1,12.009,1.134,11.908,11.908,0,0,1,15.283.673a11.708,11.708,0,0,1,2.109.2A12.547,12.547,0,0,0,12.822,0a12.724,12.724,0,0,0-3.5.492,12.214,12.214,0,0,0-7.358,5.77,11.605,11.605,0,0,0-1,9.132A12.385,12.385,0,0,0,12.812,24a12.727,12.727,0,0,0,3.5-.492,12.862,12.862,0,0,0,2.068-.8,11.9,11.9,0,0,1-3.1.419A11.582,11.582,0,0,1,4.191,15.076ZM11.456,7.4v2.951H8.416v3.1h3.039V16.4h3.189V13.45h3.039v-3.1H14.645V7.4Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
export function NotificationIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 24,
    width: 24,
    color: '#000',
    strokeWidth: 0.2,
    ...rootStyle,
  };
  return (
    <SvgIcon
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      sx={rootSx}
      {...rest}
    >
      <path d="M 23.277344 4.0175781 C 15.193866 4.3983176 9 11.343391 9 19.380859 L 9 26.648438 L 6.3496094 31.980469 A 1.50015 1.50015 0 0 0 6.3359375 32.009766 C 5.2696804 34.277268 6.9957076 37 9.5019531 37 L 18 37 C 18 40.295865 20.704135 43 24 43 C 27.295865 43 30 40.295865 30 37 L 38.496094 37 C 41.002339 37 42.730582 34.277829 41.664062 32.009766 A 1.50015 1.50015 0 0 0 41.650391 31.980469 L 39 26.648438 L 39 19 C 39 10.493798 31.863289 3.6133643 23.277344 4.0175781 z M 23.417969 7.0136719 C 30.338024 6.6878857 36 12.162202 36 19 L 36 27 A 1.50015 1.50015 0 0 0 36.15625 27.667969 L 38.949219 33.289062 C 39.128826 33.674017 38.921017 34 38.496094 34 L 9.5019531 34 C 9.077027 34 8.8709034 33.674574 9.0507812 33.289062 C 9.0507812 33.289062 9.0507812 33.287109 9.0507812 33.287109 L 11.84375 27.667969 A 1.50015 1.50015 0 0 0 12 27 L 12 19.380859 C 12 12.880328 16.979446 7.3169324 23.417969 7.0136719 z M 21 37 L 27 37 C 27 38.674135 25.674135 40 24 40 C 22.325865 40 21 38.674135 21 37 z" />
    </SvgIcon>
  );
}
export function UserIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 12.25,
    width: 9.851,
    color: '#f58634',
    strokeWidth: 0.25,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 9.851 12.25"
      {...rest}
    >
      <path
        data-name="icons8-user (1)"
        d="M4.925.125a3 3 0 103 3 3.007 3.007 0 00-3-3zm0 .9a2.1 2.1 0 11-2.1 2.1 2.093 2.093 0 012.1-2.1zm-3.606 6.3A1.2 1.2 0 00.125 8.519v.456a2.8 2.8 0 001.592 2.419 6.752 6.752 0 003.208.731 6.752 6.752 0 003.208-.731 2.9 2.9 0 001.52-1.969h.072v-.906a1.2 1.2 0 00-1.194-1.194zm0 .9h7.213a.287.287 0 01.294.294v.006h0v.45a1.828 1.828 0 01-1.127 1.631 5.98 5.98 0 01-2.774.619 5.98 5.98 0 01-2.773-.619 1.828 1.828 0 01-1.127-1.631v-.456a.287.287 0 01.294-.294z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.25}
      />
    </SvgIcon>
  );
}
export function ExpertProfileIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    height: 24,
    width: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 106264" transform="translate(-1322 -10311)">
        <path
          data-name="Rectangle 56199"
          transform="translate(1322 10311)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <path
          data-name="icons8-male-user (2)"
          d="M14 4a10 10 0 1010 10A10.011 10.011 0 0014 4zm0 4.5a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0114 8.5zm4.5 8.269c0 1.431-1.823 2.731-4.5 2.731s-4.5-1.3-4.5-2.731v-.338a.931.931 0 01.931-.931h7.138a.931.931 0 01.931.931z"
          transform="translate(1320 10309)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
}

export function WeightNoBgIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 16,
    width: 16,
    color: 'rgba(24,87,76,0.48)',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M10 2a2.217 2.217 0 00-1.665.71 2.264 2.264 0 00-.557 1.512 2.35 2.35 0 00.19.889h-2.6a2.453 2.453 0 00-2.429 2.164l-.923 8A2.458 2.458 0 004.445 18h11.109a2.458 2.458 0 002.43-2.724l-.924-8a2.452 2.452 0 00-2.428-2.164h-2.6a2.35 2.35 0 00.19-.889 2.264 2.264 0 00-.557-1.512A2.217 2.217 0 0010 2zm0 1.333a.8.8 0 01.668.262 1 1 0 010 1.253.8.8 0 01-.668.263.8.8 0 01-.668-.262 1 1 0 010-1.253.8.8 0 01.668-.263zM5.368 6.444h9.265a1.1 1.1 0 011.1.984l.924 8a1.1 1.1 0 01-1.105 1.239H4.445a1.1 1.1 0 01-1.1-1.238l.923-8a1.1 1.1 0 011.1-.985zm1.206 2.667a.556.556 0 00-.556.556v4.222a.556.556 0 101.111 0v-1.332l1.449 1.693a.555.555 0 10.844-.722l-1.5-1.75 1.5-1.75a.555.555 0 00-.844-.722L7.13 11V9.667a.556.556 0 00-.556-.556zm5.636 0A2.474 2.474 0 0010 11.778a2.474 2.474 0 002.211 2.667 1.963 1.963 0 001.727-1.005.554.554 0 00.062-.254v-1.075a.556.556 0 00-.556-.556h-.667a.556.556 0 100 1.111h.111v.352a.859.859 0 01-.678.314c-.6 0-1.1-.712-1.1-1.556s.5-1.556 1.1-1.556a.853.853 0 01.738.4.555.555 0 10.948-.578 1.963 1.963 0 00-1.685-.931z"
        transform="translate(-2 -2)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
