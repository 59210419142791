export const envConfig = {
  auth_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_AUTH_API_HOST
  }${import.meta.env.VITE_AUTH_API_BASE_URL}`,
  api_url: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }${import.meta.env.VITE_REST_API_BASE_URL}`,
  // Version 1 - Auth API
  auth_url_v1: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_AUTH_API_HOST
  }${import.meta.env.VITE_AUTH_API_BASE_URL_v1}`,
  // Version 1 - Rest API
  api_url_v1: `${import.meta.env.VITE_AUTH_API_PROTOCAL}${
    import.meta.env.VITE_REST_API_HOST
  }${import.meta.env.VITE_REST_API_BASE_URL_v1}`,
  payment_url: `${import.meta.env.VITE_APP_PAYMENT_BASE_URL}`,
  google_analytics: `${import.meta.env.VITE_GOOGLE_ANALYTICS}`,
  airtable_pre_evaluation_questionnaire: `${
    import.meta.env.VITE_AIRTABLE_PRE_EVALUATION_FORM_ID
  }`,
  airtable_plan_feedback: `${import.meta.env.VITE_AIRTABLE_FEEDBACK_FORM_ID}`,
  airtable_get_plan: `${import.meta.env.VITE_AIRTABLE_GOAL_FORM_ID}`,
  airtable_activities: `${import.meta.env.VITE_AIRTABLE_ACTIVITIES}`,
  airtable_app_base_id: `${import.meta.env.VITE_AIRTABLE_BASE_ID}`,
  jitsi_meet_domain: `${import.meta.env.VITE_JITSI_DOMAIN}`,
  awareness_share_domain: `${import.meta.env.VITE_AWARENESS_SHARE_DOMAIN}`,
};
