export const dateRangePicker_style = {
  customInputSx: {
    border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    opacity: 1,
    display: 'flex',
    p: 1,
    justifyContent: 'space-between',
    placeHolder: 'Select Date',
  },

  inputStyleSx: {
    border: 'none',
    opacity: 1,
    display: 'flex',
    p: 1,
    justifyContent: 'space-between',
  },
  selectedDateSx: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    justifyContent: 'center',
  },
};
