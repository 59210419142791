/* eslint-disable no-lonely-if */
import { DataTabs } from '@atoms/dataTabs';
import { BackIcon } from '@atoms/icons';
import { SearchField } from '@atoms/searchField';
import { clinicalRoutes, expertRoutes } from '@hc/routes';
import { useViewPort } from '@hc/store';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AddIcon,
  Button,
  ExpertProfileIcon,
  ExpertSplash,
  NotificationIcon,
  ProgressBar
} from '../../atoms';
import { WeeklyCalendar } from '../../atoms/weeklyCalendar';
import { TaskWeeklyCalender } from '../clinical';
import { screenlayout_style } from './style';

function Screenlayout(props) {
  const {
    className = '',
    children,
    title = 'Screen Title',
    backRequired = false,
    backRoute,
    titleRequired = true,
    footer,
    notshowFooter,
    rootStyle = {},
    appBarStyle = {},
    childrenStyle = {},
    footerStyle = {},
    titleStyle = {},
    tabIndex = '',
    setTabIndex = () => {},
    stateValue,
    closeVisitDisable,
    tabData = [],
    tabRequired = false,
    backIconStyle = {},
    mindbodfoodCalendar = false,
    searchRequired = false,
    onSearch,
    setOnSearch,
    setSearchFnc,
    calenderData,
    selectedDate = '',
    planstatus,
    startDate = '',
    endDate = '',
    onWeekChange = () => {},
    planStartDate = '',
    planEndDate = '',
    isDoctorAppbar = false,
    wellnessPlanData,
    selectDate,
    search,
    back = false,
    save = false,
    saveStyle = {},
    saveOnClick = () => {},
    backOnClick = () => {},
    visitClose = false,
    visitCloseFnc = () => {},
    underTabSx = {},
    fill = '#fff',
    tabStyle = {},

    // expert props
    createNewActivity,
    onCreateClick = () => {},
    previousPlan,
    planTemplate,
    isExpertAppBar,
    isExpertLogoRequired,
    isExpertAppHomeFooter,
    stepperformWithAppbar,
    stepperChildren,
    masterchildStyle = {},
    stepperform,
    steperValue = 0,
    setSteperValue = () => {},
    isDrawerFooter,
    drawerChildren,
    footerdDrawerChildren,
    isShowChildFooter,
    stepperRootStyle = {},
    appBarFirstChild,
    appbarSecChild,
    appBarSecChildRootStyle = {},
    appBarFirstChildRootStyle = {},
    viewOnlyChid,
    drawerChildrenStyle = {},
    viewProfile,
    onViewProfile = () => {},
    headerRootStyle = {},
    progressValue,
    profilePage,
    updateRequestStatus = () => {},
    ...rest
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const media = useMediaQuery('(min-width:400px)');
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{ ...screenlayout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      {' '}
      <Box>
        <Box
          sx={
            isDoctorAppbar
              ? { ...screenlayout_style.doctorAppbarSx, ...appBarStyle }
              : isExpertAppBar
              ? { ...screenlayout_style.expertAppbarSx, ...appBarStyle }
              : { ...screenlayout_style.appBarSx, ...appBarStyle }
          }
        >
          <Box
            pl={isDoctorAppbar ? 2.5 : ''}
            py={isDoctorAppbar ? 2 : ''}
            pr={isDoctorAppbar ? 2.5 : ''}
          >
            {!stepperform && (
              <Box>
                <Box
                  sx={{
                    ...screenlayout_style.totalScreenSx,
                    pb: tabRequired ? 2 : 0,
                  }}
                >
                  {backRequired && (
                    <BackIcon
                      id="back"
                      fill={fill}
                      rootStyle={{
                        ...screenlayout_style.backIconSx,
                        ...backIconStyle,
                      }}
                      onClick={() => {
                        if (
                          location?.pathname === clinicalRoutes?.landingPage
                        ) {
                          navigate(clinicalRoutes?.home);
                        }
                        //  else {
                        //   if (
                        //     location?.pathname ===
                        //     clinicalRoutes?.awarenessCategory
                        //   ) {
                        //     navigate(clinicalRoutes?.landingPage);
                        //   }
                        else {
                          if (
                            location?.pathname === expertRoutes?.basicInfoSteper
                          ) {
                            if (previousPlan) {
                              setSteperValue('previousPlan', false);
                            } else if (planTemplate) {
                              setSteperValue('planTemplate', false);
                            } else if (steperValue === 0) {
                              updateRequestStatus();
                            } else {
                              setSteperValue('steperValue', steperValue - 1);
                            }
                          } else {
                            if (backRoute && backRoute.length > 0) {
                              navigate(backRoute, { state: stateValue });
                            } else {
                              navigate(-1);
                            }
                          }
                        }
                        // }
                        // location?.pathname === clinicalRoutes?.landingPage
                        //   ? navigate(clinicalRoutes?.home)
                        //   : location?.pathname ===
                        //     clinicalRoutes?.awarenessCategory
                        //   ? navigate(clinicalRoutes?.landingPage)
                        //   : location?.pathname === expertRoutes?.basicInfoSteper
                        //   ? previousPlan
                        //     ? setSteperValue('previousPlan', false)
                        //     : planTemplate
                        //     ? setSteperValue('planTemplate', false)
                        //     : steperValue === 0
                        //     ? updateRequestStatus()
                        //     : setSteperValue('steperValue', steperValue - 1)
                        //   : backRoute && backRoute.length > 0
                        //   ? navigate(backRoute, { state: stateValue })
                        //   : navigate(-1);
                      }}
                    />
                  )}

                  {/* Title ,Search & InputField */}
                  {!isExpertLogoRequired ? (
                    <Box
                      sx={{
                        ...screenlayout_style.headerSx,
                        ...headerRootStyle,
                      }}
                    >
                      {search ? (
                        <Box sx={screenlayout_style.searchBoxSx}>
                          <SearchField
                            searchFieldStyle={screenlayout_style.searchFieldSx}
                            placeholder="Search Doctor or patient name"
                            onSearch={onSearch}
                            setOnSearch={setOnSearch}
                            endAdornment={
                              <IconButton
                                disableRipple
                                sx={screenlayout_style.endAdornmentSx}
                                onClick={() => setSearchFnc(false)}
                              >
                                <RiCloseLine />
                              </IconButton>
                            }
                          />
                        </Box>
                      ) : (
                        <>
                          {titleRequired && (
                            <Typography
                              sx={{
                                ...screenlayout_style.titleSx,
                                ...titleStyle,
                              }}
                              variant="body1"
                              color="common.white"
                            >
                              {title}
                            </Typography>
                          )}
                          {searchRequired && (
                            <IconButton
                              disableRipple
                              sx={screenlayout_style.iconSx}
                              onClick={() => setSearchFnc(true)}
                            >
                              <RiSearchLine />
                            </IconButton>
                          )}
                        </>
                      )}
                    </Box>
                  ) : (
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <ExpertSplash
                          rootStyle={{ height: '38px', width: '150px' }}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton>
                          <NotificationIcon
                            rootStyle={{
                              color: '#fff',
                              width: '24.1px',
                              height: '25.69px',
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}

                  {/* BACK BUTTON */}
                  {back && (
                    <Button
                      sx={{
                        bgcolor: 'white',
                        color: 'primary.main',
                        borderRadius: '8px',
                        '&:hover': {
                          bgcolor: 'primaryTints.300',
                        },

                        border: '2px solid #007965',
                        width: '100px',
                      }}
                      onClick={() => backOnClick()}
                    >
                      Back
                    </Button>
                  )}
                  {/* SAVE BUTTON */}
                  {createNewActivity && (
                    <Box
                      id="new"
                      onClick={onCreateClick}
                      sx={screenlayout_style?.createNewActivitySx}
                    >
                      <AddIcon rootStyle={{ color: '#fff', height: '16px' }} />
                      <Typography fontSize={12} color="#fff">
                        New
                      </Typography>
                    </Box>
                  )}
                  {viewProfile && (
                    <Box
                      onClick={onViewProfile}
                      sx={screenlayout_style?.profileIconSx}
                    >
                      <ExpertProfileIcon />
                    </Box>
                  )}
                  {/* SAVE BUTTON */}
                  {save && (
                    <Button
                      sx={{
                        bgcolor: 'white',
                        color: 'primary.main',
                        borderRadius: '8px',
                        '&:hover': {
                          bgcolor: 'primaryTints.300',
                        },

                        border: '2px solid #007965',
                        width: '100px',
                        ...saveStyle,
                      }}
                      onClick={saveOnClick}
                    >
                      SAVE
                    </Button>
                  )}
                  {/* CLOSE BUTTON  */}
                  {visitClose && (
                    <Button
                      id="closeVisit"
                      type="submit"
                      sx={{
                        p: isMobilePort ? '5px' : '5px 15px',
                        boxShadow: 'none',
                        border: '2px solid #007965',
                        borderRadius: '8px',
                        fontSize: '14px',
                        width: '154px',
                        '&:hover': {
                          border: '2px solid #007965',
                        },
                      }}
                      variant="outlined"
                      color="primary"
                      fontSize="14px"
                      // disabled={closeVisitDisable === true ? false : true}
                      onClick={() => visitCloseFnc()}
                    >
                      Close Visit
                    </Button>
                  )}
                  {/* Profile Percentage completed */}
                  {profilePage &&
                    progressValue !== 100 &&
                    progressValue !== 0 && (
                      <Box sx={screenlayout_style.headerSx}>
                        {progressValue && (
                          <Typography
                            fontSize={14}
                            sx={{ ...screenlayout_style.completedSx }}
                          >
                            {`${progressValue ?? 0}% Completed!`}
                          </Typography>
                        )}
                      </Box>
                    )}
                </Box>
                {/* Data Tab */}
                {tabRequired && (
                  <DataTabs
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabs={tabData}
                    underTabSx={underTabSx}
                    tabStyle={tabStyle}
                  />
                )}
                <Box>
                  {mindbodfoodCalendar && (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        color="#fff"
                        mt={1.5}
                        mb={2}
                        sx={media ? '' : screenlayout_style.calenderSx}
                      >
                        <Box>
                          <WeeklyCalendar
                            leftIcon={isBefore(
                              new Date(planStartDate),
                              new Date(startDate),
                            )}
                            rightIcon={isAfter(
                              new Date(planEndDate),
                              new Date(endDate),
                            )}
                            endDate={endDate}
                            startDate={startDate}
                            readOnly
                            onWeekChange={onWeekChange}
                            iconStyle={{ color: '#fff' }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ overflowX: 'scroll' }}>
                        <TaskWeeklyCalender
                          planstatus={planstatus}
                          selectDate={selectDate}
                          wellnessPlanData={wellnessPlanData}
                          selectedDate={selectedDate}
                          planStartDate={planStartDate}
                          planEndDate={planEndDate}
                          calenderData={calenderData}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          {stepperform && <Box>{stepperChildren}</Box>}
          {profilePage && progressValue !== 100 && progressValue !== 0 && (
            <Box>
              <ProgressBar value={progressValue ?? 0} />
            </Box>
          )}
        </Box>
        {stepperformWithAppbar && (
          <Box sx={{ ...screenlayout_style.stepperBarSx, ...stepperRootStyle }}>
            {stepperformWithAppbar && <Box>{stepperChildren}</Box>}
          </Box>
        )}
        {appBarFirstChild && (
          <Box
            sx={{
              ...screenlayout_style.app1stRootSx,
              ...appBarFirstChildRootStyle,
            }}
          >
            {appBarFirstChild && <Box>{appBarFirstChild}</Box>}
          </Box>
        )}
        {appbarSecChild && (
          <Box>
            <Box
              sx={{
                ...screenlayout_style.appSecRootSx,
                ...appBarSecChildRootStyle,
              }}
            >
              {appbarSecChild && <Box>{appbarSecChild}</Box>}
            </Box>
            {viewOnlyChid && (
              <Box sx={screenlayout_style.viewOnlySx}>{viewOnlyChid}</Box>
            )}
          </Box>
        )}
      </Box>
      {/* Children */}
      <Box
        sx={
          isDoctorAppbar
            ? {
                ...screenlayout_style.doctorChildrenSx,
                ...childrenStyle,
                pt: tabRequired ? 17 : 9,
                ...masterchildStyle,
              }
            : isExpertAppBar
            ? {
                ...screenlayout_style.expertChildrenSx,
                ...childrenStyle,
                pt: tabRequired ? 17 : 9,
                pb: tabRequired ? 17 : 15,
                ...masterchildStyle,
              }
            : {
                ...screenlayout_style.childrenSx,
                ...childrenStyle,
                pt: tabRequired ? 17 : 9,
                ...masterchildStyle,
              }
        }
      >
        {children}
      </Box>
      <Box>
        {isDrawerFooter ? (
          <Box>
            {isShowChildFooter && (
              <Box
                sx={{
                  ...screenlayout_style.isExpertAppFooterDrawerSx,
                  ...drawerChildrenStyle,
                }}
              >
                {drawerChildren}
              </Box>
            )}

            {!notshowFooter && (
              <Box
                sx={{
                  ...screenlayout_style.footerSx,
                  ...footerStyle,
                }}
              >
                {footerdDrawerChildren}
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {!notshowFooter && (
              <Box sx={{ ...screenlayout_style.footerSx, ...footerStyle }}>
                {footer}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export { Screenlayout };
Screenlayout.propTypes = {
  title: PropTypes.string,
  backRequired: PropTypes.any,
  backRoute: PropTypes.any,
  stateValue: PropTypes.any,
  titleRequired: PropTypes.bool,
  children: PropTypes.any,
  footer: PropTypes.any,
  tabData: PropTypes.array,
  tabRequired: PropTypes.bool,
  searchRequired: PropTypes.bool,
  search: PropTypes.bool,
  onSearch: PropTypes.func,
  planstatus: PropTypes.object,
  selectDate: PropTypes.string,
  wellnessPlanData: PropTypes.object,
  calenderData: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onWeekChange: PropTypes.func,
  setOnSearch: PropTypes.func,
  setSearchFnc: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
  notshowFooter: PropTypes.bool,
  rootStyle: PropTypes.object,
  appBarStyle: PropTypes.object,
  childrenStyle: PropTypes.object,
  footerStyle: PropTypes.object,
  tabIndex: PropTypes.string,
  setTabIndex: PropTypes.func,
  closeVisitDisable: PropTypes.bool,
  backIconStyle: PropTypes.object,
  mindbodfoodCalendar: PropTypes.bool,
  titleStyle: PropTypes.object,
  selectedDate: PropTypes.string,
  planStartDate: PropTypes.string,
  planEndDate: PropTypes.string,
  isDoctorAppbar: PropTypes.bool,
  back: PropTypes.bool,
  save: PropTypes.bool,
  saveStyle: PropTypes.object,
  saveOnClick: PropTypes.func,
  backOnClick: PropTypes.func,
  visitClose: PropTypes.bool,
  visitCloseFnc: PropTypes.func,
  createNewActivity: PropTypes.bool,
  onCreateClick: PropTypes.func,
  previousPlan: PropTypes.bool,
  planTemplate: PropTypes.bool,
  isExpertAppBar: PropTypes.bool,
  isExpertLogoRequired: PropTypes.bool,
  isExpertAppHomeFooter: PropTypes.bool,
  stepperformWithAppbar: PropTypes.bool,
  stepperChildren: PropTypes.element,
  masterchildStyle: PropTypes.object,
  stepperform: PropTypes.bool,
  steperValue: PropTypes.number,
  setSteperValue: PropTypes.func,
  isDrawerFooter: PropTypes.bool,
  drawerChildren: PropTypes.element,
  footerdDrawerChildren: PropTypes.element,
  isShowChildFooter: PropTypes.bool,
  stepperRootStyle: PropTypes.object,
  appBarFirstChild: PropTypes.element,
  appbarSecChild: PropTypes.element,
  appBarSecChildRootStyle: PropTypes.object,
  appBarFirstChildRootStyle: PropTypes.object,
  viewOnlyChid: PropTypes.any,
  drawerChildrenStyle: PropTypes.object,
  viewProfile: PropTypes.bool,
  onViewProfile: PropTypes.func,
  headerRootStyle: PropTypes.object,
  progressValue: PropTypes.number,
  profilePage: PropTypes.bool,
  updateRequestStatus: PropTypes.func,
  underTabSx: PropTypes.object,
  fill: PropTypes.string,
  tabStyle: PropTypes.object,
};
