export const countryCode = [
  {
    countryName: 'Afghanistan',
    dial_code: '+93',
    code: 'AF',
    label: 'Afghanistan',
    value: '+93',
  },
  {
    countryName: 'Albania',
    dial_code: '+355',
    code: 'AL',
    label: 'Albania',
    value: '+355',
  },
  {
    countryName: 'Algeria',
    dial_code: '+213',
    code: 'DZ',
    label: 'Algeria',
    value: '+213',
  },
  {
    countryName: 'Andorra',
    dial_code: '+376',
    code: 'AD',
    label: 'Andorra',
    value: '+376',
  },
  {
    countryName: 'Angola',
    dial_code: '+244',
    code: 'AO',
    label: 'Angola',
    value: '+244',
  },
  {
    countryName: 'Antarctica',
    dial_code: '+672',
    code: 'AQ',
    label: 'Antarctica',
    value: '+672',
  },
  {
    countryName: 'Antigua & Barbuda',
    dial_code: '+1268',
    code: 'AG',
    label: 'Antigua & Barbuda',
    value: '+1268',
  },
  {
    countryName: 'Argentina',
    dial_code: '+54',
    code: 'AR',
    label: 'Argentina',
    value: '+54',
  },
  {
    countryName: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    label: 'Armenia',
    value: '+374',
  },
  {
    countryName: 'Aruba',
    dial_code: '+297',
    code: 'AW',
    label: 'Aruba',
    value: '+297',
  },
  {
    countryName: 'Australia',
    dial_code: '+61',
    code: 'AU',
    label: 'Australia',
    value: '+61',
  },
  {
    countryName: 'Austria',
    dial_code: '+43',
    code: 'AT',
    label: 'Austria',
    value: '+43',
  },
  {
    countryName: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    label: 'Azerbaijan',
    value: '+994',
  },
  {
    countryName: 'Bahrain',
    dial_code: '+973',
    code: 'BH',
    label: 'Bahrain',
    value: '+973',
  },
  {
    countryName: 'Bangladesh',
    dial_code: '+880',
    code: 'BD',
    label: 'Bangladesh',
    value: '+880',
  },
  {
    countryName: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    label: 'Belarus',
    value: '+375',
  },
  {
    countryName: 'Belgium',
    dial_code: '+32',
    code: 'BE',
    label: 'Belgium',
    value: '+32',
  },
  {
    countryName: 'Belize',
    dial_code: '+501',
    code: 'BZ',
    label: 'Belize',
    value: '+501',
  },
  {
    countryName: 'Benin',
    dial_code: '+229',
    code: 'BJ',
    label: 'Benin',
    value: '+229',
  },
  {
    countryName: 'Bhutan',
    dial_code: '+975',
    code: 'BT',
    label: 'Bhutan',
    value: '+975',
  },
  {
    countryName: 'Bolivia',
    dial_code: '+591',
    code: 'BO',
    label: 'Bolivia',
    value: '+591',
  },
  {
    countryName: 'Bosnia & Herzegovina',
    dial_code: '+387',
    code: 'BA',
    label: 'Bosnia & Herzegovina',
    value: '+387',
  },
  {
    countryName: 'Botswana',
    dial_code: '+267',
    code: 'BW',
    label: 'Botswana',
    value: '+267',
  },
  {
    countryName: 'Brazil',
    dial_code: '+55',
    code: 'BR',
    label: 'Brazil',
    value: '+55',
  },
  {
    countryName: 'British IOT',
    dial_code: '+246',
    code: 'IO',
    label: 'British IOT',
    value: '+246',
  },
  {
    countryName: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'BN',
    label: 'Brunei Darussalam',
    value: '+673',
  },
  {
    countryName: 'Bulgaria',
    dial_code: '+359',
    code: 'BG',
    label: 'Bulgaria',
    value: '+359',
  },
  {
    countryName: 'Burkina Faso',
    dial_code: '+226',
    code: 'BF',
    label: 'Burkina Faso',
    value: '+226',
  },
  {
    countryName: 'Burundi',
    dial_code: '+257',
    code: 'BI',
    label: 'Burundi',
    value: '+257',
  },
  {
    countryName: 'Cambodia',
    dial_code: '+855',
    code: 'KH',
    label: 'Cambodia',
    value: '+855',
  },
  {
    countryName: 'Cameroon',
    dial_code: '+237',
    code: 'CM',
    label: 'Cameroon',
    value: '+237',
  },
  {
    countryName: 'Cape Verde',
    dial_code: '+238',
    code: 'CV',
    label: 'Cape Verde',
    value: '+238',
  },
  {
    countryName: 'Cayman Islands',
    dial_code: '+345',
    code: 'KY',
    label: 'Cayman Islands',
    value: '+345',
  },
  {
    countryName: 'Central Africa',
    dial_code: '+236',
    code: 'CF',
    label: 'Central Africa',
    value: '+236',
  },
  {
    countryName: 'Chad',
    dial_code: '+235',
    code: 'TD',
    label: 'Chad',
    value: '+235',
  },
  {
    countryName: 'Chile',
    dial_code: '+56',
    code: 'CL',
    label: 'Chile',
    value: '+56',
  },
  {
    countryName: 'China',
    dial_code: '+86',
    code: 'CN',
    label: 'China',
    value: '+86',
  },
  {
    countryName: 'Colombia',
    dial_code: '+57',
    code: 'CO',
    label: 'Colombia',
    value: '+57',
  },
  {
    countryName: 'Comoros',
    dial_code: '+269',
    code: 'KM',
    label: 'Comoros',
    value: '+269',
  },
  {
    countryName: 'Congo',
    dial_code: '+242',
    code: 'CG',
    label: 'Congo',
    value: '+242',
  },
  {
    countryName: 'Congo',
    dial_code: '+243',
    code: 'CD',
    label: 'Congo',
    value: '+243',
  },
  {
    countryName: 'Cook Islands',
    dial_code: '+682',
    code: 'CK',
    label: 'Cook Islands',
    value: '+682',
  },
  {
    countryName: 'Costa Rica',
    dial_code: '+506',
    code: 'CR',
    label: 'Costa Rica',
    value: '+506',
  },
  {
    countryName: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'CI',
    label: "Cote d'Ivoire",
    value: '+225',
  },
  {
    countryName: 'Croatia',
    dial_code: '+385',
    code: 'HR',
    label: 'Croatia',
    value: '+385',
  },
  {
    countryName: 'Cuba',
    dial_code: '+53',
    code: 'CU',
    label: 'Cuba',
    value: '+53',
  },
  {
    countryName: 'Cyprus',
    dial_code: '+537',
    code: 'CY',
    label: 'Cyprus',
    value: '+537',
  },
  {
    countryName: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ',
    label: 'Czech Republic',
    value: '+420',
  },
  {
    countryName: 'Denmark',
    dial_code: '+45',
    code: 'DK',
    label: 'Denmark',
    value: '+45',
  },
  {
    countryName: 'Djibouti',
    dial_code: '+253',
    code: 'DJ',
    label: 'Djibouti',
    value: '+253',
  },
  {
    countryName: 'Ecuador',
    dial_code: '+593',
    code: 'EC',
    label: 'Ecuador',
    value: '+593',
  },
  {
    countryName: 'Egypt',
    dial_code: '+20',
    code: 'EG',
    label: 'Egypt',
    value: '+20',
  },
  {
    countryName: 'El Salvador',
    dial_code: '+503',
    code: 'SV',
    label: 'El Salvador',
    value: '+503',
  },
  {
    countryName: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'GQ',
    label: 'Equatorial Guinea',
    value: '+240',
  },
  {
    countryName: 'Eritrea',
    dial_code: '+291',
    code: 'ER',
    label: 'Eritrea',
    value: '+291',
  },
  {
    countryName: 'Estonia',
    dial_code: '+372',
    code: 'EE',
    label: 'Estonia',
    value: '+372',
  },
  {
    countryName: 'Ethiopia',
    dial_code: '+251',
    code: 'ET',
    label: 'Ethiopia',
    value: '+251',
  },
  {
    countryName: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO',
    label: 'Faroe Islands',
    value: '+298',
  },
  {
    countryName: 'Fiji',
    dial_code: '+679',
    code: 'FJ',
    label: 'Fiji',
    value: '+679',
  },
  {
    countryName: 'Finland',
    dial_code: '+358',
    code: 'FI',
    label: 'Finland',
    value: '+358',
  },
  {
    countryName: 'France',
    dial_code: '+33',
    code: 'FR',
    label: 'France',
    value: '+33',
  },
  {
    countryName: 'French Guiana',
    dial_code: '+594',
    code: 'GF',
    label: 'French Guiana',
    value: '+594',
  },
  {
    countryName: 'French Polynesia',
    dial_code: '+689',
    code: 'PF',
    label: 'French Polynesia',
    value: '+689',
  },
  {
    countryName: 'Gabon',
    dial_code: '+241',
    code: 'GA',
    label: 'Gabon',
    value: '+241',
  },
  {
    countryName: 'Gambia',
    dial_code: '+220',
    code: 'GM',
    label: 'Gambia',
    value: '+220',
  },
  {
    countryName: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    label: 'Georgia',
    value: '+995',
  },
  {
    countryName: 'Germany',
    dial_code: '+49',
    code: 'DE',
    label: 'Germany',
    value: '+49',
  },
  {
    countryName: 'Ghana',
    dial_code: '+233',
    code: 'GH',
    label: 'Ghana',
    value: '+233',
  },
  {
    countryName: 'Gibraltar',
    dial_code: '+350',
    code: 'GI',
    label: 'Gibraltar',
    value: '+350',
  },
  {
    countryName: 'Greece',
    dial_code: '+30',
    code: 'GR',
    label: 'Greece',
    value: '+30',
  },
  {
    countryName: 'Greenland',
    dial_code: '+299',
    code: 'GL',
    label: 'Greenland',
    value: '+299',
  },
  {
    countryName: 'Guadeloupe',
    dial_code: '+590',
    code: 'GP',
    label: 'Guadeloupe',
    value: '+590',
  },
  {
    countryName: 'Guatemala',
    dial_code: '+502',
    code: 'GT',
    label: 'Guatemala',
    value: '+502',
  },
  {
    countryName: 'Guinea',
    dial_code: '+224',
    code: 'GN',
    label: 'Guinea',
    value: '+224',
  },
  {
    countryName: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'GW',
    label: 'Guinea-Bissau',
    value: '+245',
  },
  {
    countryName: 'Haiti',
    dial_code: '+509',
    code: 'HT',
    label: 'Haiti',
    value: '+509',
  },
  {
    countryName: 'Holy See',
    dial_code: '+379',
    code: 'VA',
    label: 'Holy See',
    value: '+379',
  },
  {
    countryName: 'Honduras',
    dial_code: '+504',
    code: 'HN',
    label: 'Honduras',
    value: '+504',
  },
  {
    countryName: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
    label: 'Hong Kong',
    value: '+852',
  },
  {
    countryName: 'Hungary',
    dial_code: '+36',
    code: 'HU',
    label: 'Hungary',
    value: '+36',
  },
  {
    countryName: 'Iceland',
    dial_code: '+354',
    code: 'IS',
    label: 'Iceland',
    value: '+354',
  },
  {
    countryName: 'India',
    dial_code: '+91',
    code: 'IN',
    label: 'India',
    value: '+91',
  },
  {
    countryName: 'Indonesia',
    dial_code: '+62',
    code: 'ID',
    label: 'Indonesia',
    value: '+62',
  },
  {
    countryName: 'Iran',
    dial_code: '+98',
    code: 'IR',
    label: 'Iran',
    value: '+98',
  },
  {
    countryName: 'Iraq',
    dial_code: '+964',
    code: 'IQ',
    label: 'Iraq',
    value: '+964',
  },
  {
    countryName: 'Ireland',
    dial_code: '+353',
    code: 'IE',
    label: 'Ireland',
    value: '+353',
  },
  {
    countryName: 'Israel',
    dial_code: '+972',
    code: 'IL',
    label: 'Israel',
    value: '+972',
  },
  {
    countryName: 'Italy',
    dial_code: '+39',
    code: 'IT',
    label: 'Italy',
    value: '+39',
  },
  {
    countryName: 'Japan',
    dial_code: '+81',
    code: 'JP',
    label: 'Japan',
    value: '+81',
  },
  {
    countryName: 'Jordan',
    dial_code: '+962',
    code: 'JO',
    label: 'Jordan',
    value: '+962',
  },
  {
    countryName: 'Kenya',
    dial_code: '+254',
    code: 'KE',
    label: 'Kenya',
    value: '+254',
  },
  {
    countryName: 'Kiribati',
    dial_code: '+686',
    code: 'KI',
    label: 'Kiribati',
    value: '+686',
  },
  {
    countryName: 'Korea',
    dial_code: '+82',
    code: 'KR',
    label: 'Korea',
    value: '+82',
  },
  {
    countryName: 'Korea',
    dial_code: '+850',
    code: 'KP',
    label: 'Korea',
    value: '+850',
  },
  {
    countryName: 'Kuwait',
    dial_code: '+965',
    code: 'KW',
    label: 'Kuwait',
    value: '+965',
  },
  {
    countryName: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    label: 'Kyrgyzstan',
    value: '+996',
  },
  {
    countryName: 'Lao',
    dial_code: '+856',
    code: 'LA',
    label: 'Lao',
    value: '+856',
  },
  {
    countryName: 'Latvia',
    dial_code: '+371',
    code: 'LV',
    label: 'Latvia',
    value: '+371',
  },
  {
    countryName: 'Lebanon',
    dial_code: '+961',
    code: 'LB',
    label: 'Lebanon',
    value: '+961',
  },
  {
    countryName: 'Lesotho',
    dial_code: '+266',
    code: 'LS',
    label: 'Lesotho',
    value: '+266',
  },
  {
    countryName: 'Liberia',
    dial_code: '+231',
    code: 'LR',
    label: 'Liberia',
    value: '+231',
  },
  {
    countryName: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'LY',
    label: 'Libyan Arab Jamahiriya',
    value: '+218',
  },
  {
    countryName: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI',
    label: 'Liechtenstein',
    value: '+423',
  },
  {
    countryName: 'Lithuania',
    dial_code: '+370',
    code: 'LT',
    label: 'Lithuania',
    value: '+370',
  },
  {
    countryName: 'Luxembourg',
    dial_code: '+352',
    code: 'LU',
    label: 'Luxembourg',
    value: '+352',
  },
  {
    countryName: 'Macao',
    dial_code: '+853',
    code: 'MO',
    label: 'Macao',
    value: '+853',
  },
  {
    countryName: 'Macedonia',
    dial_code: '+389',
    code: 'MK',
    label: 'Macedonia',
    value: '+389',
  },
  {
    countryName: 'Madagascar',
    dial_code: '+261',
    code: 'MG',
    label: 'Madagascar',
    value: '+261',
  },
  {
    countryName: 'Malawi',
    dial_code: '+265',
    code: 'MW',
    label: 'Malawi',
    value: '+265',
  },
  {
    countryName: 'Malaysia',
    dial_code: '+60',
    code: 'MY',
    label: 'Malaysia',
    value: '+60',
  },
  {
    countryName: 'Maldives',
    dial_code: '+960',
    code: 'MV',
    label: 'Maldives',
    value: '+960',
  },
  {
    countryName: 'Mali',
    dial_code: '+223',
    code: 'ML',
    label: 'Mali',
    value: '+223',
  },
  {
    countryName: 'Malta',
    dial_code: '+356',
    code: 'MT',
    label: 'Malta',
    value: '+356',
  },
  {
    countryName: 'Marshall Islands',
    dial_code: '+692',
    code: 'MH',
    label: 'Marshall Islands',
    value: '+692',
  },
  {
    countryName: 'Martinique',
    dial_code: '+596',
    code: 'MQ',
    label: 'Martinique',
    value: '+596',
  },
  {
    countryName: 'Mauritania',
    dial_code: '+222',
    code: 'MR',
    label: 'Mauritania',
    value: '+222',
  },
  {
    countryName: 'Mauritius',
    dial_code: '+230',
    code: 'MU',
    label: 'Mauritius',
    value: '+230',
  },
  {
    countryName: 'Mayotte',
    dial_code: '+262',
    code: 'YT',
    label: 'Mayotte',
    value: '+262',
  },
  {
    countryName: 'Mexico',
    dial_code: '+52',
    code: 'MX',
    label: 'Mexico',
    value: '+52',
  },
  {
    countryName: 'Micronesia',
    dial_code: '+691',
    code: 'FM',
    label: 'Micronesia',
    value: '+691',
  },
  {
    countryName: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    label: 'Moldova',
    value: '+373',
  },
  {
    countryName: 'Monaco',
    dial_code: '+377',
    code: 'MC',
    label: 'Monaco',
    value: '+377',
  },
  {
    countryName: 'Mongolia',
    dial_code: '+976',
    code: 'MN',
    label: 'Mongolia',
    value: '+976',
  },
  {
    countryName: 'Montenegro',
    dial_code: '+382',
    code: 'ME',
    label: 'Montenegro',
    value: '+382',
  },
  {
    countryName: 'Montserrat',
    dial_code: '+1664',
    code: 'MS',
    label: 'Montserrat',
    value: '+1664',
  },
  {
    countryName: 'Morocco',
    dial_code: '+212',
    code: 'MA',
    label: 'Morocco',
    value: '+212',
  },
  {
    countryName: 'Mozambique',
    dial_code: '+258',
    code: 'MZ',
    label: 'Mozambique',
    value: '+258',
  },
  {
    countryName: 'Myanmar',
    dial_code: '+95',
    code: 'MM',
    label: 'Myanmar',
    value: '+95',
  },
  {
    countryName: 'Namibia',
    dial_code: '+264',
    code: 'NA',
    label: 'Namibia',
    value: '+264',
  },
  {
    countryName: 'Nauru',
    dial_code: '+674',
    code: 'NR',
    label: 'Nauru',
    value: '+674',
  },
  {
    countryName: 'Nepal',
    dial_code: '+977',
    code: 'NP',
    label: 'Nepal',
    value: '+977',
  },
  {
    countryName: 'Netherlands',
    dial_code: '+31',
    code: 'NL',
    label: 'Netherlands',
    value: '+31',
  },
  {
    countryName: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'AN',
    label: 'Netherlands Antilles',
    value: '+599',
  },
  {
    countryName: 'New Caledonia',
    dial_code: '+687',
    code: 'NC',
    label: 'New Caledonia',
    value: '+687',
  },
  {
    countryName: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
    label: 'New Zealand',
    value: '+64',
  },
  {
    countryName: 'Nicaragua',
    dial_code: '+505',
    code: 'NI',
    label: 'Nicaragua',
    value: '+505',
  },
  {
    countryName: 'Niger',
    dial_code: '+227',
    code: 'NE',
    label: 'Niger',
    value: '+227',
  },
  {
    countryName: 'Nigeria',
    dial_code: '+234',
    code: 'NG',
    label: 'Nigeria',
    value: '+234',
  },
  {
    countryName: 'Niue',
    dial_code: '+683',
    code: 'NU',
    label: 'Niue',
    value: '+683',
  },
  {
    countryName: 'Norway',
    dial_code: '+47',
    code: 'NO',
    label: 'Norway',
    value: '+47',
  },
  {
    countryName: 'Oman',
    dial_code: '+968',
    code: 'OM',
    label: 'Oman',
    value: '+968',
  },
  {
    countryName: 'Pakistan',
    dial_code: '+92',
    code: 'PK',
    label: 'Pakistan',
    value: '+92',
  },
  {
    countryName: 'Palau',
    dial_code: '+680',
    code: 'PW',
    label: 'Palau',
    value: '+680',
  },
  {
    countryName: 'Palestinia',
    dial_code: '+970',
    code: 'PS',
    label: 'Palestinia',
    value: '+970',
  },
  {
    countryName: 'Panama',
    dial_code: '+507',
    code: 'PA',
    label: 'Panama',
    value: '+507',
  },
  {
    countryName: 'Papua New Guinea',
    dial_code: '+675',
    code: 'PG',
    label: 'Papua New Guinea',
    value: '+675',
  },
  {
    countryName: 'Paraguay',
    dial_code: '+595',
    code: 'PY',
    label: 'Paraguay',
    value: '+595',
  },
  {
    countryName: 'Peru',
    dial_code: '+51',
    code: 'PE',
    label: 'Peru',
    value: '+51',
  },
  {
    countryName: 'Philippines',
    dial_code: '+63',
    code: 'PH',
    label: 'Philippines',
    value: '+63',
  },
  {
    countryName: 'Pitcairn',
    dial_code: '+872',
    code: 'PN',
    label: 'Pitcairn',
    value: '+872',
  },
  {
    countryName: 'Poland',
    dial_code: '+48',
    code: 'PL',
    label: 'Poland',
    value: '+48',
  },
  {
    countryName: 'Portugal',
    dial_code: '+351',
    code: 'PT',
    label: 'Portugal',
    value: '+351',
  },
  {
    countryName: 'Qatar',
    dial_code: '+974',
    code: 'QA',
    label: 'Qatar',
    value: '+974',
  },
  {
    countryName: 'Romania',
    dial_code: '+40',
    code: 'RO',
    label: 'Romania',
    value: '+40',
  },
  {
    countryName: 'Russia',
    dial_code: '+7',
    code: 'RU',
    label: 'Russia',
    value: '+7',
  },
  {
    countryName: 'Rwanda',
    dial_code: '+250',
    code: 'RW',
    label: 'Rwanda',
    value: '+250',
  },
  {
    countryName: 'Saint Helena',
    dial_code: '+290',
    code: 'SH',
    label: 'Saint Helena',
    value: '+290',
  },
  {
    countryName: 'Saint Pierre',
    dial_code: '+508',
    code: 'PM',
    label: 'Saint Pierre',
    value: '+508',
  },
  {
    countryName: 'Samoa',
    dial_code: '+685',
    code: 'WS',
    label: 'Samoa',
    value: '+685',
  },
  {
    countryName: 'San Marino',
    dial_code: '+378',
    code: 'SM',
    label: 'San Marino',
    value: '+378',
  },
  {
    countryName: 'Sao Tome',
    dial_code: '+239',
    code: 'ST',
    label: 'Sao Tome',
    value: '+239',
  },
  {
    countryName: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
    label: 'Saudi Arabia',
    value: '+966',
  },
  {
    countryName: 'Senegal',
    dial_code: '+221',
    code: 'SN',
    label: 'Senegal',
    value: '+221',
  },
  {
    countryName: 'Serbia',
    dial_code: '+381',
    code: 'RS',
    label: 'Serbia',
    value: '+381',
  },
  {
    countryName: 'Seychelles',
    dial_code: '+248',
    code: 'SC',
    label: 'Seychelles',
    value: '+248',
  },
  {
    countryName: 'Sierra Leone',
    dial_code: '+232',
    code: 'SL',
    label: 'Sierra Leone',
    value: '+232',
  },
  {
    countryName: 'Singapore',
    dial_code: '+65',
    code: 'SG',
    label: 'Singapore',
    value: '+65',
  },
  {
    countryName: 'Slovakia',
    dial_code: '+421',
    code: 'SK',
    label: 'Slovakia',
    value: '+421',
  },
  {
    countryName: 'Slovenia',
    dial_code: '+386',
    code: 'SI',
    label: 'Slovenia',
    value: '+386',
  },
  {
    countryName: 'Solomon Islands',
    dial_code: '+677',
    code: 'SB',
    label: 'Solomon Islands',
    value: '+677',
  },
  {
    countryName: 'Somalia',
    dial_code: '+252',
    code: 'SO',
    label: 'Somalia',
    value: '+252',
  },
  {
    countryName: 'South Africa',
    dial_code: '+27',
    code: 'ZA',
    label: 'South Africa',
    value: '+27',
  },
  {
    countryName: 'South Georgia',
    dial_code: '+500',
    code: 'GS',
    label: 'South Georgia',
    value: '+500',
  },
  {
    countryName: 'Spain',
    dial_code: '+34',
    code: 'ES',
    label: 'Spain',
    value: '+34',
  },
  {
    countryName: 'Sri Lanka',
    dial_code: '+94',
    code: 'LK',
    label: 'Sri Lanka',
    value: '+94',
  },
  {
    countryName: 'Sudan',
    dial_code: '+249',
    code: 'SD',
    label: 'Sudan',
    value: '+249',
  },
  {
    countryName: 'Suriname',
    dial_code: '+597',
    code: 'SR',
    label: 'Suriname',
    value: '+597',
  },
  {
    countryName: 'Swaziland',
    dial_code: '+268',
    code: 'SZ',
    label: 'Swaziland',
    value: '+268',
  },
  {
    countryName: 'Sweden',
    dial_code: '+46',
    code: 'SE',
    label: 'Sweden',
    value: '+46',
  },
  {
    countryName: 'Switzerland',
    dial_code: '+41',
    code: 'CH',
    label: 'Switzerland',
    value: '+41',
  },
  {
    countryName: 'Syria',
    dial_code: '+963',
    code: 'SY',
    label: 'Syria',
    value: '+963',
  },
  {
    countryName: 'Taiwan',
    dial_code: '+886',
    code: 'TW',
    label: 'Taiwan',
    value: '+886',
  },
  {
    countryName: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    label: 'Tajikistan',
    value: '+992',
  },
  {
    countryName: 'Tanzania',
    dial_code: '+255',
    code: 'TZ',
    label: 'Tanzania',
    value: '+255',
  },
  {
    countryName: 'Thailand',
    dial_code: '+66',
    code: 'TH',
    label: 'Thailand',
    value: '+66',
  },
  {
    countryName: 'Timor-Leste',
    dial_code: '+670',
    code: 'TL',
    label: 'Timor-Leste',
    value: '+670',
  },
  {
    countryName: 'Togo',
    dial_code: '+228',
    code: 'TG',
    label: 'Togo',
    value: '+228',
  },
  {
    countryName: 'Tokelau',
    dial_code: '+690',
    code: 'TK',
    label: 'Tokelau',
    value: '+690',
  },
  {
    countryName: 'Tonga',
    dial_code: '+676',
    code: 'TO',
    label: 'Tonga',
    value: '+676',
  },
  {
    countryName: 'Tunisia',
    dial_code: '+216',
    code: 'TN',
    label: 'Tunisia',
    value: '+216',
  },
  {
    countryName: 'Turkey',
    dial_code: '+90',
    code: 'TR',
    label: 'Turkey',
    value: '+90',
  },
  {
    countryName: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    label: 'Turkmenistan',
    value: '+993',
  },
  {
    countryName: 'Tuvalu',
    dial_code: '+688',
    code: 'TV',
    label: 'Tuvalu',
    value: '+688',
  },
  {
    countryName: 'UAE',
    dial_code: '+971',
    code: 'AE',
    label: 'UAE',
    value: '+971',
  },
  {
    countryName: 'Uganda',
    dial_code: '+256',
    code: 'UG',
    label: 'Uganda',
    value: '+256',
  },
  {
    countryName: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    label: 'Ukraine',
    value: '+380',
  },
  {
    countryName: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
    label: 'United Kingdom',
    value: '+44',
  },
  {
    countryName: 'United States',
    dial_code: '+1',
    code: 'US',
    label: 'United States',
    value: '+1',
  },
  {
    countryName: 'Uruguay',
    dial_code: '+598',
    code: 'UY',
    label: 'Uruguay',
    value: '+598',
  },
  {
    countryName: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    label: 'Uzbekistan',
    value: '+998',
  },
  {
    countryName: 'Vanuatu',
    dial_code: '+678',
    code: 'VU',
    label: 'Vanuatu',
    value: '+678',
  },
  {
    countryName: 'Venezuela',
    dial_code: '+58',
    code: 'VE',
    label: 'Venezuela',
    value: '+58',
  },
  {
    countryName: 'Viet Nam',
    dial_code: '+84',
    code: 'VN',
    label: 'Viet Nam',
    value: '+84',
  },
  {
    countryName: 'Wallis & Futuna',
    dial_code: '+681',
    code: 'WF',
    label: 'Wallis & Futuna',
    value: '+681',
  },
  {
    countryName: 'Yemen',
    dial_code: '+967',
    code: 'YE',
    label: 'Yemen',
    value: '+967',
  },
  {
    countryName: 'Zambia',
    dial_code: '+260',
    code: 'ZM',
    label: 'Zambia',
    value: '+260',
  },
  {
    countryName: 'Zimbabwe',
    dial_code: '+263',
    code: 'ZW',
    label: 'Zimbabwe',
    value: '+263',
  },
];

export const localStorageKeys = {
  authToken: 'authToken',
  deviceToken: 'deviceToken',
  responseAppointmentId: 'responseAppointmentId',
  bookAppointmentLocalStorage: 'bookAppointmentLocalStorage',
  authTempKey: 'authTempKey',
  isReferralAppoinment: 'isReferralAppoinment',
  isRescheduleDone: 'isRescheduleDone',
  resetPasswordToken: 'resetPasswordToken',
  clinicalVersion: 'clinicalVersion',
  doctorVersion: 'doctorVersion',
  organizationVersion: 'organizationVersion',
  backOfficeVersion: 'backOfficeVersion',
  expertVersion: 'expertVersion',
  basicInfoState: 'basicInfoState',
  requestState: 'requestState',
  planAddEditToolState: 'planAddEditToolState',
  activityState: 'activityState',
  draftState: 'draftState',
  isMobilePort: 'isMobilePort',
  useByMePlanState: 'useByMePlanState',
  doctorPage: 'doctorPage',
  doctorResPage: 'doctorResPage',
  appoinmentType: 'appoinmentType',
};

export const clinicalLoginRoutes = [
  '/createaccount',
  '/login',
  '/otpverification',
  '/resetpassword',
  '/forgotpassword',
  '/onboarding',
  '/otpSplash',
  '/',
  '/awarenesSlider',
  '/awarenesscategory',
];

export const loginRoutes = [
  '/createaccount',
  '/login',
  '/otpverification',
  '/resetpassword',
  '/forgotpassword',
  '/onboarding',
  '/otpSplash',
];

export const profileNotRequireRoutes = [
  '/createaccount',
  '/onboarding',
  '/login',
  '/forgotpassword',
  '/resetpassword',
  '/otpverification',
  '/profile/add',
];
