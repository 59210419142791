import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { qusAnsSelection_style } from './style';

export function QusAnsSelection(props) {
  const {
    QusAnsSelectionHeading = '',
    QusAnsSelectionListing,
    className = '',
    quickSuggestion = false,
    quickSuggestionRequired = [],
    displaySelectionValues = [],
    sugesstionClick = () => {},
    qusAnsSelectionHeadingStyle = {},
    qsOptionViewKey,
    ...rest
  } = props;

  return (
    <Box className={`${className}`} {...rest}>
      <Box
        sx={{
          ...qusAnsSelection_style.boxTitleSx,
          ...qusAnsSelectionHeadingStyle,
        }}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ fontWeight: '600' }}
        >
          {QusAnsSelectionHeading}
        </Typography>
      </Box>
      <Box>{QusAnsSelectionListing}</Box>
      {/* quickSuggestion Box */}
      {quickSuggestion && (
        <>
          <Typography variant="body2" sx={qusAnsSelection_style.quickSx}>
            QUICK SUGGESTIONS
          </Typography>
          <Box sx={qusAnsSelection_style.suggestionSx}>
            {quickSuggestionRequired &&
              quickSuggestionRequired.map((suggestion,i) => (
                <Box
                  key={i}
                  sx={{
                    ...qusAnsSelection_style.boxSx,
                    fontSize: '14px',
                    borderColor:
                      displaySelectionValues &&
                      displaySelectionValues.some(
                        (issue) => issue?.value === suggestion?.value
                      )
                        ? 'orangeTints.600'
                        : 'grey.400',
                    backgroundColor:
                      displaySelectionValues &&
                      displaySelectionValues.some(
                        (issue) => issue?.value === suggestion?.value
                      )
                        ? 'orangeTints.50'
                        : '#FFFFFF',
                    color:
                      displaySelectionValues &&
                      displaySelectionValues.some(
                        (issue) => issue?.value === suggestion?.value
                      )
                        ? 'orangeTints.600'
                        : '#0E1824',
                    fontWeight: 500,
                  }}
                  onClick={() => sugesstionClick(null, suggestion)}
                >
                  {suggestion[qsOptionViewKey]}
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
}

QusAnsSelection.propTypes = {
  QusAnsSelectionHeading: PropTypes.string,
  QusAnsSelectionListing: PropTypes.any,
  quickSuggestion: PropTypes.bool,
  quickSuggestionRequired: PropTypes.array,
  displaySelectionValues: PropTypes.array,
  qusAnsSelectionHeadingStyle: PropTypes.object,
  sugesstionClick: PropTypes.func,
  qsOptionViewKey: PropTypes.any,
  className: PropTypes.node,
  sx: PropTypes.object,
};
