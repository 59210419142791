/* eslint-disable default-param-last */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import {
  getEndOfDayFromStartOfDay,
  getMomentWithDate,
  getMomentWithOutDate,
  getSlotEndTime,
  getStartOfDay,
  getStartOfDayToString,
  getTimeFormat,
  getTimeZoneFnc,
} from '@hc/dayjs';
// eslint-disable-next-line import/extensions
import { localStorageKeys } from './constants';

export function twentyFourTimeFormat(timeStr) {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}:00`;
}

export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const routeTo = (store, path, state, routeAfter = 1000) => {
  if (state) {
    return setTimeout(() => {
      store.setState({ route: path, state });
    }, routeAfter);
  }
  return setTimeout(() => {
    store.setState({ route: path });
  }, routeAfter);
};

export function getBrowserName() {
  const { userAgent } = navigator;
  let browserName;
  let browserSettingsUrl;

  // Check for Edge first
  if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge';
    browserSettingsUrl = 'edge://settings/content/notifications';
  }
  // Check for Chrome
  else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome';
    browserSettingsUrl = 'chrome://settings/content/notifications';
  }
  // Check for Firefox
  else if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox';
    browserSettingsUrl = 'about:preferences#privacy';
  }
  // Check for Safari
  else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari';
    browserSettingsUrl = 'safari://preferences/websites';
  }
  // Default to unknown
  else {
    browserName = 'Unknown Browser';
    browserSettingsUrl = '';
  }

  return { browserName, browserSettingsUrl };
}

export async function getAirtableBases(baseId) {
  try {
    const response = await fetch(
      `https://api.airtable.com/v0/meta/bases/${baseId}/tables`,
      {
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_AIRTABLE_TOKEN}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (err) {
    return null;
  }
}

export function tweleveHourTimeformetter(timeString) {
  const timeString12hr = new Date(
    `1970-01-01T${timeString}Z`
  ).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
  return timeString12hr;
}

/* eslint-disable implicit-arrow-linebreak */
export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getAuthData = () => {
  // get the authToken from local storage
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  return authToken ? parseJwt(authToken) : null;
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export const ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => ({
    ...val,
    value: val[valueKey],
    label: val[labelKey],
  }));
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export const ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export const CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// takes an array of objects and a key as input, and returns an object where the keys are the unique values of the specified property and the values are arrays of objects with that property value.
export function groupBy(array, key) {
  return array.reduce((result, currentItem) => {
    const group = currentItem[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(currentItem);
    return result;
  }, {});
}

/**
 * The below function convert the HEX code to RGBA
 */
export function ConvertHexToRGBA(hex, opacity) {
  if (hex) {
    const { replace } = hex;
    const tempHex = replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
}

/**
 * The below function will scroll the page to the Top.
 */
export const ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

// Check the versions
export const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ['0', '0', '0'];
  const versionsB = versionB ? versionB.split(/\./g) : ['0', '0', '0'];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

// To get distance between two lattitude and longitude
export const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
};

// To get first letter of the name
export const getFirstLetterOfName = (name) =>
  name?.length > 0 ? name?.charAt(0) : '';
// To check number or not
export const isNumber = (value) => {
  // const number = '^[1-9]*$';
  // if (value?.length > 0) {
  //   if (value.match(number)) {
  //     return true;
  //   }
  //   return false;
  // }
  // return true;

  if (value !== '0') {
    return true;
  }
  return false;
};
// To check blood_pressure or not
export const isBloodPressure = (value) => {
  const number = '^[0-9./]+$';
  if (value?.length > 0) {
    if (value.match(number)) {
      return true;
    }
    return false;
  }
  return true;
};

// To check decimal number or not
export const isDecimalNumber = (value) => {
  const decimalNumber = /^\d+(\.\d{1,2})?$/;
  if (value?.length > 0) {
    if (decimalNumber.test(value)) {
      return true;
    }
    return false;
  }
  return true;
};
// To check string or not
export const isString = (value) => {
  const letters = /^[A-Za-z_ ]+$/;
  if (value?.length > 0) {
    if (letters.test(value)) {
      return true;
    }
    return false;
  }
  return true;
};

// To check valid blood pressure or not
export const isValidBloodPressure = (value) => {
  const BP_regex = /^\d{1,3}\/\d{1,3}$/;
  if (BP_regex.test(value)) {
    return true;
  }
  return false;
};

export const reportNameGenerator = (fileType) => {
  let digits = '0123456789';
  let distinctNumber = '';
  for (let i = 0; i < 6; i++) {
    distinctNumber += digits[Math.floor(Math.random() * 10)];
  }
  const reportName =
    fileType === 'image'
      ? `HC_REPORT_IMAGE-${distinctNumber}`
      : `HC_REPORT_PDF-${distinctNumber}`;
  return reportName;
};

export const schemaDataGet = (data, id) => {
  let getCurrentUploadReport = [];
  for (const val of id) {
    const schema = data
      ?.filter((schemaData) => schemaData?.id?.includes(val?.id))
      .map((data) => data);
    getCurrentUploadReport.push(schema?.[0]);
  }
  return getCurrentUploadReport;
};

export const handleUploadFile = async (e, isPdf) => {
  const formdata = new FormData();

  if (isPdf) {
    formdata.append(`files`, e);
  } else {
    for (let i = 0; i < e.length; i++) {
      formdata.append(`files`, e[i]);
    }
  }

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  const uploads = await fetch(
    'https://storage.healthcircles.care/docs/v1/file/multiple/upload',
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => {
      console.log('error', error);
    });

  return uploads;
};

export const formatBytes = (bytes, decimals) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

//
export const encodeImageFileAsURL = (element) => {
  const file = element;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const removeExtension = (filename) => {
  if (filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }
  return '';
};

export const generateReportName = (filename) => {
  if (filename) {
    return `${removeExtension(filename)}-${Math.random()
      .toString(30)
      .slice(9)}`;
  }
  return '';
};

export const getPerfomanceStatsSevenDays = (start) => {
  const startDateTime = getStartOfDay(start);
  const endDateTime = getEndOfDayFromStartOfDay(start, 6);
  return {
    start_date: startDateTime.toISOString(),
    end_date: endDateTime.toISOString(),
  };
};

export const titleCase = (name) => {
  if (name) {
    return name.replace(/^_*(.)|_+(.)/g, (name, c, d) =>
      c ? c.toUpperCase() : ` ${d.toUpperCase()}`
    );
  }
  return '';
};

export const doctorAvailablePayload = (start) => {
  const startDateTime = getStartOfDay(start);
  const endDateTime = getEndOfDayFromStartOfDay(start, 7);
  return {
    startDateTime: startDateTime.toISOString(),
    endDateTime: endDateTime.toISOString(),
    dates: [
      getStartOfDayToString(start, 1, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 2, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 3, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 4, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 5, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 6, 'YYYY-MM-DD'),
      getStartOfDayToString(start, 7, 'YYYY-MM-DD'),
    ],
    clientTimeZone: getTimeZoneFnc(),
  };
};
// find a difference between days (or) hours (or) mins for two days
export function timeDiffCalc(dateFuture) {
  let diffInMilliSeconds = Math.abs(new Date(dateFuture) - new Date()) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  if (days === 0 && hours === 0 && minutes > 0 && minutes < 60) {
    return minutes === 0 || minutes === 1
      ? `in ${minutes} min`
      : `in ${minutes} mins`;
  }
  if (days === 0 && hours > 0 && hours < 24) {
    return hours === 0 || hours === 1
      ? `in ${hours} hour`
      : `in ${hours} hours`;
  }
  if (days > 0) {
    return days === 0 || days === 1 ? `in ${days} day` : `in ${days} days`;
  }
}
// disable join button function if appointment meeting start in more than 5 mins
export function IsBookButtonEnable(dateFuture) {
  let diffInMilliSeconds = Math.abs(new Date(dateFuture) - new Date()) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  if (days === 0 && hours === 0 && minutes < 0) {
    return false;
  }
  return true;
}

export function BMICalculationProfileFamily(key, value, stateValue) {
  const stateValueCopy = JSON.parse(JSON.stringify(stateValue));
  if (key === 'height') {
    if (value?.length > 0 && stateValueCopy?.weight?.length > 0) {
      return (stateValueCopy?.weight / ((value / 100) * (value / 100))).toFixed(
        2
      );
    }
    return '';
  }
  if (key === 'weight') {
    if (value?.length > 0 && stateValueCopy?.height?.length > 0) {
      return (
        value /
        ((stateValueCopy?.height / 100) * (stateValueCopy?.height / 100))
      ).toFixed(2);
    }
    return '';
  }
}

export function BMICalculation(key, value, vitals) {
  const vitalsCopy = JSON.parse(JSON.stringify(vitals));
  if (key === 'height_in_cm') {
    if (value?.length > 0 && vitalsCopy?.weight_in_cm?.length > 0) {
      return (
        vitalsCopy?.weight_in_cm /
        ((value / 100) * (value / 100))
      ).toFixed(2);
    }
    return '';
  }
  if (key === 'weight_in_cm') {
    if (value?.length > 0 && vitalsCopy?.height_in_cm?.length > 0) {
      return (
        value /
        ((vitalsCopy?.height_in_cm / 100) * (vitalsCopy?.height_in_cm / 100))
      ).toFixed(2);
    }
    return '';
  }
}

export function differenceBetweenTwoTimes(slotTime) {
  const currentTime = getTimeFormat(new Date(), 'hh:mm A');
  const currentTimeMilliSecond =
    Number(currentTime.split(':')[0]) * 60 * 60 * 1000 +
    Number(currentTime.split(':')[1]) * 60 * 1000;
  const slotTimeMilliSecond =
    Number(slotTime.split(':')[0]) * 60 * 60 * 1000 +
    Number(slotTime.split(':')[1]) * 60 * 1000;
  if (slotTimeMilliSecond > currentTimeMilliSecond) {
    return true;
  }
  return false;
}

export function inculdeInLiveAppointment(dateFuture) {
  let diffInMilliSeconds = Math.abs(new Date(dateFuture) - new Date()) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  if (days === 0 && hours === 0 && minutes > 0 && minutes < 5) {
    return {
      type: 'upcoming',
      minutes,
      seconds: diffInMilliSeconds,
    };
  }
}

export function excludeLastFiveMinsAppointment(dateFuture) {
  let diffInMilliSeconds = Math.abs(new Date(dateFuture) - new Date()) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  if (days === 0 && hours === 0 && minutes > 0 && minutes < 5) {
    return false;
  }
  return true;
}

export function inculdeInLiveApp(appTime) {
  const slotEndDateTime = getSlotEndTime(appTime, 15);
  const currentDateTime = getMomentWithDate(new Date());
  // 09.45 < 10.00
  if (currentDateTime < slotEndDateTime) {
    return {
      type: 'live',
      minutes: 0,
      seconds: 0,
    };
  }
  return {
    type: 'past',
    minutes: 0,
    seconds: 0,
  };
}

export function camelCaseToTitleCase(text) {
  if (text?.length > 0) {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  return '';
}

export function constructMasterId(data, key) {
  const idArray = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      idArray.push({ id: val[key] });
    });
  }
  return idArray;
}

export function constructBMI(height, weight) {
  if (height > 0 && weight > 0) {
    return (weight / ((height / 100) * (height / 100))).toFixed(2);
  }
  return '';
}

export function diffYMDHMS(date2) {
  if (!date2) {
    return;
  }
  const date1 = getMomentWithOutDate();

  const years = date1.diff(date2, 'year');
  date2.add(years, 'years');

  const months = date1.diff(date2, 'months');
  date2.add(months, 'months');

  const weeks = date1.diff(date2, 'weeks');
  date2.add(weeks, 'weeks');

  const days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  const hours = date1.diff(date2, 'hours');
  date2.add(hours, 'hours');

  const minutes = date1.diff(date2, 'minutes');
  date2.add(minutes, 'minutes');

  // eslint-disable-next-line no-unused-vars
  const seconds = date1.diff(date2, 'seconds');

  if (years) {
    return `${years}y`;
  }
  if (months) {
    return `${months}m`;
  }
  if (weeks) {
    return `${weeks}w`;
  }
  if (days) {
    return `${days}d`;
  }
  if (hours) {
    return `${hours}h`;
  }
  if (minutes) {
    return `${minutes}m`;
  }

  // if (
  //   years === 0 &&
  //   months === 0 &&
  //   weeks === 0 &&
  //   days === 0 &&
  //   hours === 0 &&
  //   minutes > 0 &&
  //   minutes < 60
  // ) {
  //   return minutes + 'm';
  // } else if (
  //   years === 0 &&
  //   months === 0 &&
  //   weeks === 0 &&
  //   days === 0 &&
  //   hours > 0 &&
  //   hours < 24
  // ) {
  //   return hours + 'h';
  // } else if (
  //   years === 0 &&
  //   months === 0 &&
  //   weeks === 0 &&
  //   days > 0 &&
  //   days < 365
  // ) {
  //   return days + 'd';
  // }
}

// To check http url or not
export const isHttpURL = (value) => {
  const isUrl = /^https?:\/\//;
  if (value?.length > 0) {
    if (isUrl.test(value)) {
      return true;
    }
    return false;
  }
  return true;
};

// To change mobile number from 971630322 to xxxxx xxx22
export const encriptMobileNumber = (value) => {
  if (value) {
    return `${'*'.repeat(value.length - 5)} ${'*'.repeat(
      value.length - 7
    )}${value.slice(-2)}`;
  }
};

export async function csvToJson(file) {
  const reader = new FileReader();
  const papa = await import('papaparse');
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const csvData = event.target.result;
      const json = papa.parse(csvData, { header: true }).data;
      resolve(json);
      return json;
    };

    reader.onerror = (event) => {
      reject(event);
    };

    reader.readAsText(file);
  });
}

export async function xlsxToJson(file) {
  const reader = new FileReader();
  const modules = await import('xlsx');
  const { read, utils } = modules;
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const json = utils.sheet_to_json(worksheet, { raw: true });
      resolve(json);
      return json;
    };

    reader.onerror = (event) => {
      reject(event);
    };

    reader.readAsBinaryString(file);
  });
}

export function GetColumnData(getObject) {
  const getArrayOfCloumnValues = Object.keys(getObject);
  const columnValues = getArrayOfCloumnValues.reduce((data, value) => {
    const newObj = {
      field: value,
      headerName: value,
      width: 200,
    };
    return [...data, newObj];
  }, []);

  return columnValues;
}

export function GetDropDownData(getObject) {
  const getArrayOfCloumnValues = Object.keys(getObject);
  const columnValues = getArrayOfCloumnValues.reduce((data, val) => {
    const newObj = {
      label: val,
      value: val,
    };
    return [...data, newObj];
  }, []);
  return columnValues;
}

export const schemaDataMapper = (excel, obj) => {
  const result = [];
  for (const data of excel) {
    const x = {};
    for (const key in obj) {
      if (data[obj[key]]) {
        x[key] = data[obj[key]];
      }
    }
    if (Object.keys(x).length) {
      result.push(x);
    }
  }

  return result;
};
// To get file name
export const getFileName = (fileName) => {
  if (fileName) {
    return fileName?.split('.')?.[0] ?? '';
  }
  return '';
};

// To get file type
export const getFileType = (fileName) => {
  if (typeof fileName === 'string') {
    return fileName?.split('.').pop();
  }
  return '';
};

// To lazy load a script
export function lazyLoadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function ordinal_suffix_of(i) {
  let j = i % 10;
  let k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function durationModeSwitch(id) {
  switch (id) {
    case 1:
      return 1;
    case 2:
      return 7;
    case 3:
      return 30;
    case 4:
      return 365;
    default:
      return 1;
  }
}

export function frequencySwitch(id) {
  switch (id) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 7;
    case 4:
      return 3;
    case 5:
      return 2;
    case 6:
      return 30;
    default:
      return 1;
  }
}

export function calculateDoseQuantity(
  duration = 0,
  durationModeId = 1,
  frequency = 1,
  doseCumulative
) {
  const result =
    Math.floor(
      (duration * durationModeSwitch(durationModeId)) /
        frequencySwitch(frequency)
    ) * doseCumulative;
  return result;
}

export function BetweenDateCount(startDate, endDate) {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const Difference_In_Time = date2.getTime() - date1.getTime();

  const result = Difference_In_Time / (1000 * 3600 * 24);

  const removePoint = (number) => {
    const numberStr = String(number);
    return numberStr.substring(0, numberStr.indexOf('.'));
  };

  const value = removePoint(result);
  return value;
}

export function previewPlanDataContsruct(resObject) {
  const dataConstruct = (resObject, arrayKey) => {
    const dateData = [
      {
        label: 'SUN',
        value: '1',
      },
      {
        label: 'MON',
        value: '2',
      },
      {
        label: 'TUE',
        value: '3',
      },
      {
        label: 'WED',
        value: '4',
      },
      {
        label: 'THU',
        value: '5',
      },
      {
        label: 'FRI',
        value: '6',
      },
      {
        label: 'SAT',
        value: '7',
      },
    ];

    let activityArr = [];
    for (const val of dateData) {
      let resArr = [];
      let dayWiseObject = {};
      for (const da of resObject?.activityData) {
        if (da?.category_name === arrayKey) {
          for (const value of da?.activity_days.sort()) {
            if (value === val?.value) {
              const object = {
                id: da?.id,
                plan: `${da?.activity_name} - ${da?.quantity} ${da?.unit_name} , ${da?.activity_time_name}.`,
                activity_image: da?.activity_image,
                activity_name: da?.activity_name,
                activityUnits: `${da?.quantity} ${da?.unit_name}`,
                subActivityUnits: `${da?.quantity} ${da?.unit_name}`,
                activity_time: da?.activity_time,
                activity_time_name: da?.activity_time_name,
                activity_days: da?.activity_days,
                unit: da?.unit,
                unit_name: da?.unit_name,
                quantity: da?.quantity,
                activity_dont: da?.activity_dont,
                activity_notes: da?.activity_notes,
                activity_references: da?.activity_references,
                category_id: da?.category_id,
                category_name: da?.category_name,
                goal_id: da?.goal_id,
                goal_name: da?.goal_name,
                is_measurable: da?.is_measurable,
                specific_time: da?.specific_time,
                isNew: false,
              };
              resArr.push(object);
            }
          }
          dayWiseObject = {
            day:
              val?.value === '1'
                ? 'SUN'
                : val?.value === '2'
                ? 'MON'
                : val?.value === '3'
                ? 'TUE'
                : val?.value === '4'
                ? 'WED'
                : val?.value === '5'
                ? 'THU'
                : val?.value === '6'
                ? 'FRI'
                : 'SAT',
            plan: resArr,
          };
          activityArr.push(dayWiseObject);
        }
      }
    }

    const key = 'day';
    const arrayUniqueByKey = [
      ...new Map(activityArr.map((item) => [item[key], item])).values(),
    ];
    const keyVal = 'plan';
    const arrayUniqueByKeyCopy = [
      ...new Map(arrayUniqueByKey.map((item) => [item[keyVal], item])).values(),
    ];

    return arrayUniqueByKeyCopy;
  };

  const object = {
    mind: {
      planName: resObject?.planData?.plan_name,
      planDayData: dataConstruct(resObject, 'Mind'),
    },
    body: {
      planName: resObject?.planData?.plan_name,
      planDayData: dataConstruct(resObject, 'Body'),
    },
    food: {
      planName: resObject?.planData?.plan_name,
      planDayData: dataConstruct(resObject, 'Food'),
    },
  };
  return object;
}

export function ordinalSuffixOf(number) {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return `${number}st`;
  }
  if (j === 2 && k !== 12) {
    return `${number}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${number}rd`;
  }
  return `${number}th`;
}

export function camelCaseToWords(value) {
  const result = value?.length > 0 ? value?.replace(/([A-Z])/g, ' $1') : '';
  return result?.length > 0
    ? result?.charAt(0).toUpperCase() + result.slice(1)
    : '';
}
