/* eslint-disable no-unused-vars */
import {
  addDays,
  addMonths,
  differenceInMonths,
  differenceInYears,
  eachDayOfInterval,
  endOfWeek,
  format,
  isAfter,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from 'date-fns';
import moment from 'moment';

export function isAfterFns(date) {
  return isAfter(new Date(date), new Date(moment().format('YYYY-MM-DD')));
}

// GET PREVIOUS WEEK SEVEN DAYS FROM SUNDAY TO SATURDAY
// INPUT_DATA :
// [
//   '2022-12-10T18:30:00.000Z',
//   '2022-12-11T18:30:00.000Z',
//   '2022-12-12T18:30:00.000Z',
//   '2022-12-13T18:30:00.000Z',
//   '2022-12-14T18:30:00.000Z',
//   '2022-12-15T18:30:00.000Z',
//   '2022-12-16T18:30:00.000Z',
// ];
// OUTPUT_DATA :
// {
//     start : 2022,12,04,
//     end: 2022,12,10,
//     betweenDates: [
//         "2022-12-03T18:30:00.000Z",
//         "2022-12-04T18:30:00.000Z",
//         "2022-12-05T18:30:00.000Z",
//         "2022-12-06T18:30:00.000Z",
//         "2022-12-07T18:30:00.000Z",
//         "2022-12-08T18:30:00.000Z",
//         "2022-12-09T18:30:00.000Z"
//     ]
// }
export function getPreviousWeekSevenDays(data) {
  const start = format(subDays(new Date(data[0]), 7), 'yyyy,L,dd');
  const end = format(subDays(new Date(data[data?.length - 1]), 7), 'yyyy,L,dd');
  const betweenDates = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });
  return { start, end, betweenDates };
}

// GET NEXT WEEK SEVEN DAYS FROM SUNDAY TO SATURDAY
// INPUT_DATA :
// [
//     "2022-12-03T18:30:00.000Z",
//     "2022-12-04T18:30:00.000Z",
//     "2022-12-05T18:30:00.000Z",
//     "2022-12-06T18:30:00.000Z",
//     "2022-12-07T18:30:00.000Z",
//     "2022-12-08T18:30:00.000Z",
//     "2022-12-09T18:30:00.000Z"
// ]
// OUTPUT_DATA :
// {
//     start : 2022,12,11,
//     end: 2022,12,17,
//     betweenDates: []
//         "2022-12-10T18:30:00.000Z",
//         "2022-12-11T18:30:00.000Z",
//         "2022-12-12T18:30:00.000Z",
//         "2022-12-13T18:30:00.000Z",
//         "2022-12-14T18:30:00.000Z",
//         "2022-12-15T18:30:00.000Z",
//         "2022-12-16T18:30:00.000Z"
//      ]
// }
export function getNextWeekSevenDays(data) {
  const start = format(addDays(new Date(data[0]), 7), 'yyyy,L,dd');
  const end = format(addDays(new Date(data[data?.length - 1]), 7), 'yyyy,L,dd');
  const betweenDates = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });

  return { start, end, betweenDates };
}

// GET PREVIOUS MONTH LAST SEVEN DAYS FROM SUNDAY TO SATURDAY
// INPUT_DATA :
// [
//     "2022-12-10T18:30:00.000Z",
//     "2022-12-11T18:30:00.000Z",
//     "2022-12-12T18:30:00.000Z",
//     "2022-12-13T18:30:00.000Z",
//     "2022-12-14T18:30:00.000Z",
//     "2022-12-15T18:30:00.000Z",
//     "2022-12-16T18:30:00.000Z"
// ]
// OUTPUT_DATA :
// {
//     start : Sun Oct 30 2022 00:00:00 GMT+0530 (India Standard Time),
//     end: Sat Nov 05 2022 23:59:59 GMT+0530 (India Standard Time),
//     betweenDates:
// [
//     "2022-10-29T18:30:00.000Z",
//     "2022-10-30T18:30:00.000Z",
//     "2022-10-31T18:30:00.000Z",
//     "2022-11-01T18:30:00.000Z",
//     "2022-11-02T18:30:00.000Z",
//     "2022-11-03T18:30:00.000Z",
//     "2022-11-04T18:30:00.000Z"
// ]
// }
export function getPreviousMonthSevenDays(data) {
  const current_month = subMonths(new Date(data[data?.length - 1]), 1);
  const start_of_month = startOfMonth(new Date(current_month));
  const start_week = startOfWeek(new Date(start_of_month));
  const end_week = endOfWeek(new Date(start_of_month));
  const betweenDates = eachDayOfInterval({
    start: new Date(format(start_week, 'yyyy,L,dd')),
    end: new Date(format(end_week, 'yyyy,L,dd')),
  });

  return {
    start_week,
    end_week,
    betweenDates,
  };
}

// GET NEXT MONTH FIRST SEVEN DAYS FROM SUNDAY TO SATURDAY
// INPUT_DATA :
// [
//     "2022-12-10T18:30:00.000Z",
//     "2022-12-11T18:30:00.000Z",
//     "2022-12-12T18:30:00.000Z",
//     "2022-12-13T18:30:00.000Z",
//     "2022-12-14T18:30:00.000Z",
//     "2022-12-15T18:30:00.000Z",
//     "2022-12-16T18:30:00.000Z"
// ]
// OUTPUT_DATA :
// {
//     start : Sun Jan 01 2023 00:00:00 GMT+0530 (India Standard Time),
//     end: Sat Jan 07 2023 23:59:59 GMT+0530 (India Standard Time),
//     betweenDates:
// [
//     "2022-12-31T18:30:00.000Z",
//     "2023-01-01T18:30:00.000Z",
//     "2023-01-02T18:30:00.000Z",
//     "2023-01-03T18:30:00.000Z",
//     "2023-01-04T18:30:00.000Z",
//     "2023-01-05T18:30:00.000Z",
//     "2023-01-06T18:30:00.000Z"
// ]
// }
export function getNextMonthSevenDays(data) {
  const current_month = addMonths(new Date(data[data?.length - 1]), 1);
  const start_of_month = startOfMonth(new Date(current_month));
  const start_week = startOfWeek(new Date(start_of_month));
  const end_week = endOfWeek(new Date(start_of_month));
  const betweenDates = eachDayOfInterval({
    start: new Date(format(start_week, 'yyyy,L,dd')),
    end: new Date(format(end_week, 'yyyy,L,dd')),
  });
  return {
    start_week,
    end_week,
    betweenDates,
  };
}

// GET START OF THE WEEK
// INPUT_DATA : Thu Dec 15 2022 19:10:54 GMT+0530 (India Standard Time)
// OUTPUT_DATA : Sun Dec 11 2022 00:00:00 GMT+0530 (India Standard Time)
export function getStartOfWeek(date) {
  return startOfWeek(date);
}

// GET END  OF THE WEEK
// INPUT_DATA : Thu Dec 15 2022 19:10:54 GMT+0530 (India Standard Time)
// OUTPUT_DATA : Dec 17 2022 23:59:59 GMT+0530 (India Standard Time)
export function getEndOfWeek(date) {
  return endOfWeek(date);
}

// GET THIS WEEK SEVEN DAYS FROM SUNDAY TO STAURDAY
// INPUT_DATA :
// startDate : Sun Dec 11 2022 00:00:00 GMT+0530 (India Standard Time)
// EndDate :  Sat Dec 17 2022 23:59:59 GMT+0530 (India Standard Time)
// OUTPUT_DATA :
// [
//     "2022-12-10T18:30:00.000Z",
//     "2022-12-11T18:30:00.000Z",
//     "2022-12-12T18:30:00.000Z",
//     "2022-12-13T18:30:00.000Z",
//     "2022-12-14T18:30:00.000Z",
//     "2022-12-15T18:30:00.000Z",
//     "2022-12-16T18:30:00.000Z"
// ]
export function getDaysOfInterval(startDate, EndDate) {
  return eachDayOfInterval({
    start: startDate,
    end: EndDate,
  });
}

// GET TODAY DATE
// INPUT_DATA :
// format : 'YYYY-MM-DD'
// OUTPUT_DATA :
// 2022-12-15
export function getTodayDate(format) {
  return moment().format(format ?? 'YYYY-MM-DD');
}

// GET DATE FORMAT
// INPUT_DATA :
// date : 2022-12-15
// format : YYYY-MM-DD
// OUTPUT_DATA :
// 2022-12-15
export function getDateFormat(date, format) {
  return moment(date).format(format ?? 'YYYY-MM-DD');
}

export function getDateFormatToString(date, format) {
  return moment(date)
    .format(format ?? 'lll')
    .toString();
}

export function getStartOfDay(date) {
  return moment.utc(moment(date).startOf('day'));
}

export function getStartOfDayToString(date, days, format) {
  return moment
    .utc(
      moment(date)
        .add(days ?? 1, 'day')
        .startOf('day'),
    )
    .format(format ?? 'YYYY-MM-DD')
    .toString();
}

export function getEndOfDayFromStartOfDay(date, days) {
  return moment.utc(
    moment(date)
      .add(days ?? 7, 'day')
      .endOf('day'),
  );
}

// GET DATE FORMAT
// INPUT_DATA :
// format : YYYY-MM-DD
// OUTPUT_DATA :
// 2022-12-15
export function getDateFormatWithoutDate(format) {
  return moment().format(format ?? 'YYYY-MM-DD');
}

// GET TIME FORMAT
// INPUT_DATA :
// date : 2022-12-15T23:15:00+05:30
// format : HH:mm:ss
// OUTPUT_DATA :
// 23:15:00
export function getTimeFormat(date, format) {
  return moment(date).format(format ?? 'HH:mm:ss');
}

export function getEndTimeFromStartTime(dateTime, minutes) {
  return moment(dateTime)
    .add(minutes ?? 15, 'minute')
    .format()
    .toString();
}

export function getDate(date) {
  return moment(date);
}

export function getStartOfWeeks(format) {
  return moment()
    .startOf('week')
    .format(format ?? 'YYYY-MM-DD');
}
export function getEndOfWeeks(format) {
  return moment()
    .endOf('week')
    .format(format ?? 'YYYY-MM-DD');
}

export function getStartOfMonth(format) {
  return moment()
    .startOf('month')
    .format(format ?? 'YYYY-MM-DD');
}
export function getEndOfMonth(format) {
  return moment()
    .endOf('month')
    .format(format ?? 'YYYY-MM-DD');
}

export function getStartOfYear(format) {
  return moment()
    .startOf('year')
    .format(format ?? 'YYYY-MM-DD');
}
export function getEndOfYear(format) {
  return moment()
    .endOf('year')
    .format(format ?? 'YYYY-MM-DD');
}

export function getMomentWithDate(date) {
  return moment(date);
}

export function getMomentWithOutDate() {
  return moment();
}

// GET TIMEZONE
// OUTPUT_DATA :
// Asia/Calcutta
export function getTimeZoneFnc() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// GET SLOT START TIME
// INPUT_DATA :
// startTime : 2022-12-15T10:00:00.000Z
// OUTPUT_DATA :
// 2022-12-15T15:30:00+05:30
export function getSlotStartTime(startTime) {
  return moment(startTime).format().toString();
}

export function getSystemDateTime(startTime) {
  return moment(startTime).format().toString();
}

export function compareWithCurrentDateTime(date) {
  return moment().isSame(getEndTimeFromStartTime(date, 16), 'seconds');
}

// GET SLOT END TIME TO ADD APPOINTMENT TIME
// INPUT_DATA :
// startTime : 2022-12-15T22:30:00+05:30
// toAddTimeInMins : 15
// OUTPUT_DATA :
// 2022-12-15T22:45:00+05:30
export function getSlotEndTimeToString(startTime, toAddTimeInMins) {
  return moment(startTime)
    .add(toAddTimeInMins ?? 15, 'minute')
    .format()
    .toString();
}

export function getSlotEndTime(date, minute) {
  return moment(date).add(minute ?? 15, 'minute');
}

export function getAppointmentTime(time, format) {
  return moment(time, format ?? 'HH:mm:ss').format('LT');
}

export function startWeekMonth(type, format) {
  return moment()
    .startOf(type ?? 'week')
    .format(format ?? 'YYYY-MM-DD');
}

export function endWeekMonth(type, format) {
  return moment()
    .endOf(type ?? 'week')
    .format(format ?? 'YYYY-MM-DD');
}

export function getReferralDateFormat(date, format) {
  return moment.utc(date).format(format ?? 'HH:MM A, Do MMM, YYYY');
}

export function getDashboardConsultations(time, format) {
  return moment(time, format).format('DD-MMM-YYYY');
}

export function getDashboardMemberStatus(time, format) {
  return moment(time, format).format('MMM-YYYY');
}

export function momentUtc(date) {
  return moment.utc(date).format();
}

export function timeConvertor(val) {
  return moment(val, 'hh:mm a').format('LT');
}

export function differenceInDaysFnc(date) {
  const res = (moment(date).unix() - moment().endOf('day').unix()) / 86400;
  return res <= 3 && res >= 0
    ? 'last_3_days'
    : res > 3
    ? 'more_than_3_days'
    : 'plan_ended';

  // return differenceInDays(new Date('2023-08-03T18:29:59.999Z'), currentData);
}

export function differenceInYearsFnc(date) {
  const resInYears = differenceInYears(new Date(), new Date(date));

  if (resInYears > 0) {
    return `${resInYears} years exp`;
  }
  const resInMonths = differenceInMonths(new Date(), new Date(date));
  return `${resInMonths} months exp`;
}

export function startOfFnc(date, type) {
  return moment(date ?? new Date())
    .startOf(type)
    .toISOString();
}

export function endOfFnc(date, type) {
  return moment(date ?? new Date())
    .endOf(type)
    .toISOString();
}

export function subtractFnc(date, value, type) {
  return moment(date ?? new Date()).subtract(value ?? 1, type ?? 'day');
}

export function addFnc(date, value, type) {
  return moment(date ?? new Date()).add(value ?? 1, type ?? 'day');
}
