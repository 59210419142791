/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DocumentIcon } from '@atoms';
import { DialogModal } from '@atoms/dialog';
import { ViewDocumentIcon } from '@atoms/icons';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import PropTypes from 'prop-types';
import { React, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { patient_report_card_style } from './style';
import { PdfViewer } from '../../../pdfviewer';

export function PatientReportUploadCard(props) {
  const {
    className,
    rootStyle,
    reportName = '',
    doctorName = '',
    uploadedFor = '',
    uploadeddate = '',
    uploadedData = [],
    showReportName,
    showReportType,
    showUploadedBy,
    showCheckBox,
    isUploadCard,
    icon = {},
    IconOnClick = () => {},
    selectAllCheckBox,
    isLoading,
    ...rest
  } = props;
  const [selectCard, ,] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, ,] = useState(0);

  const fileTypeValues = uploadedData.map((data) => data.file.slice(-3));
  const getfileType = fileTypeValues.filter(
    (item, i, ar) => ar.indexOf(item) === i,
  );
  const fileType =
    getfileType?.[0]?.length > 0 ? getfileType.toString() : 'pdf';

  const Image = uploadedData?.[0];
  const ExpandImage = Image?.file;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const DiaogBodyContent = (
    <>
      {fileType === 'pdf' && (
        <Box pb={1}>
          {uploadedData.map((data) => (
            <div style={{ width: '300px', height: '300px' }}>
              <PdfViewer url={data.file} />
            </div>
          ))}
        </Box>
      )}

      {fileType !== 'pdf' && (
        <Box sx={patient_report_card_style.adSx} pb={1}>
          <Slider {...settings}>
            {uploadedData.map((data, i) => (
              <img
                key={i}
                style={{ objectFit: 'fill' }}
                src={data.file}
                alt=""
              />
            ))}
          </Slider>
        </Box>
      )}
    </>
  );

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={patient_report_card_style.rootSx}
      className={`${className}`}
      {...rest}
    >
      {uploadedData[0]?.file && (
        <Box
          sx={{
            ...(selectCard || selectAllCheckBox
              ? patient_report_card_style.activeOutlinedBox
              : patient_report_card_style.outlinedBox),
          }}
        >
          <Grid container>
            <Grid item xs={3} sm={2.5} md={2.5} p={1} mt={0.5}>
              <Box
                sx={{
                  width: '66px',
                  backgroundColor: '#fff',
                  color: '#0E1824',

                  transition:
                    ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  borderRadius: '4px',
                  border: '1px solid #DBDBDB',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={
                    fileType === 'pdf'
                      ? patient_report_card_style.resImageBoxSx
                      : patient_report_card_style.ImageBoxSx
                  }
                >
                  {fileType === 'pdf' && (
                    <Box
                      alignSelf="center"
                      py={2.5}
                      sx={{
                        height: '60px',
                      }}
                      onClick={handleModalOpen}
                    >
                      <DocumentIcon rootStyle={{ color: '#FFF' }} />
                    </Box>
                  )}
                  {fileType !== 'pdf' && (
                    <img
                      style={{ objectFit: 'fill' }}
                      src={ExpandImage}
                      alt=""
                      onClick={handleModalOpen}
                    />
                  )}

                  <Box
                    sx={patient_report_card_style.expandIconSx}
                    alignSelf="center"
                  >
                    {fileType !== 'pdf' && (
                      <Box onClick={handleModalOpen}>
                        <ViewDocumentIcon />
                      </Box>
                    )}
                    {showReportName && (
                      <DialogModal
                        isDialogOpened={isModalOpen}
                        handleCloseDialog={handleClose}
                        title={reportName}
                        content={DiaogBodyContent}
                      />
                    )}
                    {!showReportName && (
                      <DialogModal
                        isDialogOpened={isModalOpen}
                        handleCloseDialog={handleClose}
                        title={doctorName}
                        content={DiaogBodyContent}
                      />
                    )}
                  </Box>
                  <Box
                    sx={patient_report_card_style.expandIconSx}
                    alignSelf="end"
                  >
                    <Typography fontSize="10px" color="white">
                      {uploadeddate}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              sm={9.5}
              md={9.5}
              py={1.7}
              pl={1.3}
              display="flex"
              justifyContent="space-between"
            >
              <Grid container>
                <Grid item xs={12} alignSelf="start">
                  <Grid container>
                    <Grid item xs={10}>
                      {showReportName && (
                        <Typography sx={patient_report_card_style.reportName}>
                          {reportName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="end">
                      {!showCheckBox && (
                        <Box
                          onClick={IconOnClick}
                          sx={patient_report_card_style.editIconSx}
                        >
                          {isLoading ? (
                            <CircularProgress
                              variant="determinate"
                              value={progress}
                              color="success"
                              size={23}
                              thickness={6}
                            />
                          ) : (
                            <Box>{icon}</Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} alignSelf="end">
                  <Grid container display="flex" justifyContent="space-between">
                    <Grid item display="flex" alignSelf="end">
                      <Typography fontSize="12px" color="#888888" mt={-0.5}>
                        {uploadedFor}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
PatientReportUploadCard.propTypes = {
  className: PropTypes.object,
  rootStyle: PropTypes.object,
  reportName: PropTypes.string,
  doctorName: PropTypes.string,
  speciality: PropTypes.string,
  reportType: PropTypes.string,
  uploadedBy: PropTypes.string,
  uploadedFor: PropTypes.string,
  relationShip: PropTypes.string,
  uploadeddate: PropTypes.string,
  uploadedData: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
    }),
  ),
  showReportName: PropTypes.bool,
  showReportType: PropTypes.bool,
  showUploadedBy: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  selectAllCheckBox: PropTypes.bool,
  icon: PropTypes.object,
  isUploadCard: PropTypes.bool,
  IconOnClick: PropTypes.func,
  isLoading: PropTypes.bool,
};
